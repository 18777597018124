import { FETCH_ASSETS_STAGES_USER_ERROR, FETCH_ASSETS_STAGES_USER_REQUEST, FETCH_ASSETS_STAGES_USER_SUCCESS } from '../types'
import axios from 'axios';

export const fetchAssetStages = (params) => (dispatch) => {
    dispatch({ type: FETCH_ASSETS_STAGES_USER_REQUEST })
    axios.get(process.env.REACT_APP_BACKEND_URL + '/assetStages').then(resp => {
        dispatch({
            type: FETCH_ASSETS_STAGES_USER_SUCCESS,
            payload: {
                stages: resp.data
            }
        })
    }).catch(e => {
        console.error('Error al obtener los estados de los bienes: ', e);
        dispatch({
            type: FETCH_ASSETS_STAGES_USER_ERROR,
            error: e.toString()
        })
    })
}