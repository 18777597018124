import { FETCH_EVALUATOR_ERROR, FETCH_EVALUATOR_SUCCESS, FETCH_EVALUATOR_REQUEST } from '../types/index';


const initialState = {
    autoInfo: [],
    evaluators:[],
    isFetching: false,
    error: null
}

export default function evaluators(state = initialState, action) {
    switch (action.type) {
        case FETCH_EVALUATOR_REQUEST:                        
            return {
                ...state,
                isFetching: true
            };
        case FETCH_EVALUATOR_SUCCESS:                        
            return {
                ...state,
                isFetching: false,
                evaluators: action.payload.evaluators,
                autoInfo: action.payload.autoInfo
            };
        case FETCH_EVALUATOR_ERROR:                        
            return {
                ...state,
                isFetching: false,
                error: action.error
            };
        default:
            return state;
    }
}