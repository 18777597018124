import { FETCH_DASHBOARD_ERROR, FETCH_DASHBOARD_SUCCESS, FETCH_DASHBOARD_REQUEST } from '../types/index';


const initialState = {
    autoInfo: [],
    dashboard:[],
    isFetching: false,
    error: null
}

export default function dashboard(state = initialState, action) {
    switch (action.type) {
        case FETCH_DASHBOARD_REQUEST:                        
            return {
                ...state,
                isFetching: true
            };
        case FETCH_DASHBOARD_SUCCESS:                        
            return {
                ...state,
                isFetching: false,
                dashboard: action.payload.dashboard,
                autoInfo: action.payload.autoInfo
            };
        case FETCH_DASHBOARD_ERROR:                        
            return {
                ...state,
                isFetching: false,
                error: action.error
            };
        default:
            return state;
    }
}