import { FETCH_ASSIGNED_ASSETS_REQUEST, FETCH_ASSIGNED_ASSETS_SUCCESS, FETCH_ASSIGNED_ASSETS_ERROR, GET_USER } from "../types";
import {REACT_APP_BACKEND_URL} from "../../config";

import axios from 'axios';
import qs from 'querystring';
import { susi_lists } from "../../config";

export const createAssignedAsset = async (params) => {
    const requestBody = {
        siaf_id: params.asset_id,
        placa: params.placa,
        user_susi_id: params.user_id,
        category_id: params.category_id,
        status_id: params.asset_status,
        change_type_id: params.change_type
    }
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }

    return await axios.post(process.env.REACT_APP_BACKEND_URL + '/assignedAssets/', config)
        .then((result) => {
            //console.log("Este es el Id que guarda", result.data.record_id);
            return result.data.record_id;
        })
        .catch((err) => {
            console.log("Hubo un error al crear el inmueble asignado ", err);
        })
}

export const allAssetForPDF = async (identification, year = '', features = false) => {
    return await axios.get(process.env.REACT_APP_BACKEND_URL + '/assignedAssets/all?identification=' + identification + '&year=' + year + '&features=' + features)
        .then((result) => {
            return result.data;
        }).catch((err) => {
            console.log('Ocurrio un error con los datos.')
        });
}
// certification
export const getCertificate = async (identification, year = '') => {
    return await axios.get(process.env.REACT_APP_BACKEND_URL + '/assignedAssets/certification?identification=' + identification + '&year=' + year)
        .then((result) => {
            return result.data;
        }).catch((err) => {
            console.log('Ocurrio un error con los datos.')
        });
}

export const updateAssignedAsset = async (params) => {
    const requestBody = {}

    if (params.asset_status) {
        requestBody.asset_status_id = params.asset_status;
    }

    if (params.change_type) {
        requestBody.change_type_id = params.change_type;
    }

    if (params.missing_reason) {
        requestBody.missing_reason_id = params.missing_reason;
    }

    if (params.missing_reason) {
        requestBody.missing_reason_id = params.missing_reason;
    }

    if (params.observations) {
        requestBody.observations = params.observations;
    }
    //console.log("Request Body", requestBody);    
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }

    if (requestBody.change_type_id == susi_lists.newness.missing || requestBody.change_type_id == susi_lists.newness.checked) {
        return await axios.put(process.env.REACT_APP_BACKEND_URL + '/assignedAssets/' + params.id, qs.stringify(requestBody), config)
            .then((result) => {
                return result;
            })
            .catch((err) => {
                console.log("Este es el error ", err);
                return err;
            })
    } else if (requestBody.asset_status_id != null) {
        return await axios.put(process.env.REACT_APP_BACKEND_URL + '/assignedAssets/' + params.id, qs.stringify(requestBody), config)
            .then((result) => {
                return result;
            })
            .catch((err) => {
                console.log("Este es el error ", err);
                return err;
            })
    }
}

export const createInventoryCheckList = async () => {
    const requestBody = {
        competitionType: 1,
    }


    const headers = {
        'Content-Type': 'application/json; charset=utf-8'
      };

    return await axios.post(REACT_APP_BACKEND_URL +  'getCompetitions.php', qs.stringify(requestBody), {headers:headers})
        .then((result) => {
            //console.log("esta es la respuesta", result);
            return result.data[0];
        })
        .catch((err) => {
            console.log("Hubo un error al crear el inventario ", err);
        })
}

export const fetchForImpresion = async (params) => {
    let paramsStrings = "";
    for (const param in params) {
        if (paramsStrings.length > 0) {
            paramsStrings += "&";
        }
        paramsStrings += param + "=" + params[param];
    }
    return axios.get(process.env.REACT_APP_BACKEND_URL + '/assignedAssets/impresion?' + paramsStrings).then((resp) => {
        return resp.data
    }).catch(e => {
        console.error('Error al obtener bien para impresión: ',e);
        return null
    })
}

export const fetchAssignedAssets = (params, mount = false) => (dispatch) => {
    console.log("TLA fetchAssignedAssets en actions assignedAssetsAtions");
    console.log(params);
    let paramsStrings = "";

    for (const param in params) {
        if (mount && params['page'] !== 1) {
            params['page'] = 1
        }
        if (paramsStrings.length > 0) {
            paramsStrings += "&";
        }
        paramsStrings += param + "=" + params[param];
    }

    dispatch({ type: FETCH_ASSIGNED_ASSETS_REQUEST });

    const requestBody = {
        competitionType: 1,
    }
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }

    axios.get(REACT_APP_BACKEND_URL + 'patients/all?' + paramsStrings).then(res => {
        dispatch({
            type: FETCH_ASSIGNED_ASSETS_SUCCESS,
            payload: {
                page: 1,
                assets: res.data,
                count: 1,
                countSeccional: 1,
                verificationLimit: 20,
                mount: mount
            }
        })
        dispatch({
            type: GET_USER,
            payload: {
                culmination_date: res.data.culminationDate,
                mount: mount
            }
        })
    }).catch(error => {
        console.error('Error en fetchAssignedAssets: ',error);
        dispatch({
            type: FETCH_ASSIGNED_ASSETS_ERROR,
            payload:{
                error: error.toString(),
                mount: mount
            }
        })
    })
}

