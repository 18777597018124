import { FETCH_LOCATIONS_REQUEST, FETCH_LOCATIONS_SUCCESS, FETCH_LOCATIONS_ERROR } from "../types";

const initialState = {
    locations:[],
    isFetching: false,
    error: null 
}

export default function locations (state = initialState, action){    
    switch (action.type) {
        case FETCH_LOCATIONS_REQUEST:                        
            return {
                ...state,
                isFetching: true
            };
        case FETCH_LOCATIONS_SUCCESS:                   
            return {
                ...state,
                isFetching: false,
                locations: action.payload.locations
            };
        case FETCH_LOCATIONS_ERROR:                        
            return {
                ...state,
                isFetching: false,
                error: action.payload.error
            };
        default:
            return state;
    }
}