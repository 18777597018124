import {REACT_APP_BACKEND_URL} from "../../config";

import axios from 'axios';

export const createInscription = async (formData) => {
    console.log("crear inscripcion");
    console.log(formData);
    try {
        const response = await axios.post(
            `${REACT_APP_BACKEND_URL}createInscription.php`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
        console.log("responde");
        console.log(response.data);
        if (!response.data.ok) {
            console.error('Ha surgido el siguiente error al crear la inscripción: ');
        }
        return response.data;
    } catch (error) {
        console.error('Error al crear la inscripción: ', error);
        return error;
    }
}


// export const createInscription = async (params) => {
//     console.log("crear inscripcion");
//     console.log(params);
//     return await axios.post(REACT_APP_BACKEND_URL + 'createInscription.php',
//      params).then((resp) => {
//         console.log(resp.data)
//         if (!resp.data.ok) {
//             console.error('ha surgido el siguiente error al crear la inscripción: ');
//         }
//         return resp.data
//     }).catch(err => {
//         console.error('Error al crear la inscripción: ', err);
//         return err
//     })
// }

export const getInscription = async (params) => {
    let paramsStrings = "";
    for (const param in params) {
        if (paramsStrings.length > 0) {
            paramsStrings += "&";
        }
        paramsStrings += param + "=" + params[param];
    }
    return axios.get(REACT_APP_BACKEND_URL + '/inscription?' + paramsStrings).then((resp) => {
        return resp.data
    }).catch(e => {
        console.error('Error al obtener inscripción: ',e);
        return null
    })
}

export const closeInscription = async (formData) => {
    console.log("cerrar inscripcion");
    console.log(formData);
    try {
        const response = await axios.post(
            `${REACT_APP_BACKEND_URL}closeInscription.php`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
        console.log("responde");
        console.log(response.data);
        if (!response.data.ok) {
            console.error('Ha surgido el siguiente error al cerrar la inscripción: ');
        }
        return response.data;
    } catch (error) {
        console.error('Error al cerrar la inscripción: ', error);
        return error;
    }
}

export const createEvaluator = async (formData) => {
    console.log("crear evaluador");
    console.log(formData);
    try {
        const response = await axios.post(
            `${REACT_APP_BACKEND_URL}createEvaluator.php`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
        console.log("responde");
        console.log(response.data);
        if (!response.data.ok) {
            console.error('Ha surgido el siguiente error al crear un evaluador: ');
        }
        return response.data;
    } catch (error) {
        console.error('Error al crear un evaluador: ', error);
        return error;
    }
}

export const getEvaluator = async (params) => {
    console.log("modificar evaluador");
    console.log(params);
    const paramsStrings = "idUser=" + encodeURIComponent(params)
    console.log(paramsStrings);
    return axios.get(`${REACT_APP_BACKEND_URL}getEvaluator.php?` + paramsStrings).then((resp) => {
        return resp.data
    }).catch(e => {
        console.error('Error al obtener evaluador: ',e);
        return null
    })
}

export const createAssignEvaluation = async (formData) => {
    console.log("crear asignación evaluación");
    console.log(formData);
    try {
        const response = await axios.post(
            `${REACT_APP_BACKEND_URL}createAssignEvaluation.php`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
        console.log("responde");
        console.log(response.data);
        if (!response.data.ok) {
            console.error('Ha surgido el siguiente error al crear la asignación: ');
        }
        return response.data;
    } catch (error) {
        console.error('Error al crear la asignación: ', error);
        return error;
    }
}

export const updateState = async (inscriptionId, idUser, newState) => {
    console.log("actualiza estado");
    let data = {
        inscriptionId: inscriptionId,
        idUser       : idUser,
        newState     : newState
    }
    try {
        const response = await axios.post(
            `${REACT_APP_BACKEND_URL}updateState.php`,
            data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
        console.log("responde");
        console.log(response.data);
        if (!response.data.ok) {
            console.error('Ha surgido el siguiente error al actualizar: ');
        }
        return response.data;
    } catch (error) {
        console.error('Error al actualizar: ', error);
        return error;
    }
}