import { FETCH_AUTH_USER_ERROR, FETCH_AUTH_USER_REQUEST, FETCH_AUTH_USER_SUCCESS } from "../types";

const initialState = {
    token: window.sessionStorage.getItem('_userToken'),
    isFetching: false,
    error: false,
    culmination_date: '',
    limit_file_size: ''
}

export default function getUserToken(state = initialState, action) {
    console.log(action.type);
    switch(action.type) {
        case FETCH_AUTH_USER_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case FETCH_AUTH_USER_SUCCESS:
            return {
                ...state,
                token: action.payload.token,
                isFetching: true,
                error: false,
                culmination_date: action.payload.culmination_date,
                limit_file_size: action.payload.limit_file_size
            };
        case FETCH_AUTH_USER_ERROR:
            return {
                ...state,
                error: true,
                isFetching: false
            };
        default: 
            return state
    }
}