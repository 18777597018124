import { FETCH_DATA_USER_REQUEST, FETCH_DATA_USER_SUCCESS, FETCH_DATA_USER_ERROR } from "../types";

import axios from 'axios';

export const fetchDataUser = (numero_documento) => (dispatch) => {
    dispatch({ type: FETCH_DATA_USER_REQUEST });
    axios.get('https://susi.fiscalia.gov.co/core/public/get/employeeStatus/' + numero_documento)
        .then(res => {
            dispatch({
                type: FETCH_DATA_USER_SUCCESS,
                payload: {
                    directory_user: res.data
                }
            })
        })
        .catch(error => {
            dispatch({
                type: FETCH_DATA_USER_ERROR,
                error: error.toString()
            })
        })
}
