import {FETCH_ASSETS_SURPLUS_ERROR, FETCH_ASSETS_SURPLUS_SUCCESS, FETCH_ASSETS_SURPLUS_REQUEST} from '../types'
const initialState = {
    asset: null,
    isFetching: false,
    error: null
}


export default function assetSurplus(state = initialState, action) {
    switch (action.type) {
        case FETCH_ASSETS_SURPLUS_REQUEST: {
            return {
                ...state,
                isFetching: true
            };
        }                        
        case FETCH_ASSETS_SURPLUS_SUCCESS: {                      
            return {
                ...state,
                isFetching: false,
                asset: action.payload.asset
            };
        }
        case FETCH_ASSETS_SURPLUS_ERROR: {                       
            return {
                ...state,
                isFetching: false,
                asset: null,
                error: action.payload.error
            };
        }
        default:
            return state;
    }
}