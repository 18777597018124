import { FETCH_MISSINGS_ERROR, FETCH_MISSINGS_REQUEST, FETCH_MISSINGS_SUCCESS, FETCH_TOTAL_MISSINGS_ERROR, FETCH_TOTAL_MISSINGS_REQUEST, FETCH_TOTAL_MISSINGS_SUCCESS, UPDATE_MISSINGS_ERROR, UPDATE_MISSINGS_REQUEST, UPDATE_MISSINGS_SUCCESS } from "../types";

const initialState = {
    verificationLimit: 0,
    count: 0,
    page: 1,
    hasMore: false,
    missings: [],
    documents: [],
    mount: true,
    isFetching: false,
    updating: false,
    error: null,
    total: []
}

export default function missingReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_MISSINGS_REQUEST:
            return {
                ...state,
                page: 1,
                hasMore: true,
                isFetching: true,
                mount: action.payload.mount
            };
        case FETCH_MISSINGS_SUCCESS:
            return {
                ...state,
                page: action.payload.page,
                count: action.payload.count,
                documents: action.payload.documents,
                isFetching: false,
                verificationLimit: action.payload.verificationLimit,
                hasMore: action.payload.missings.length === 20,
                missings: action.payload.page > 1 ? state.missings.concat(action.payload.missings) : action.payload.missings,
                countSeccional: action.payload.countSeccional,
                mount: false
            };
        case FETCH_MISSINGS_ERROR:
            return {
                ...state,
                page: 1,
                hasMore: false,
                isFetching: false,
                error: action.error
            };

        case FETCH_TOTAL_MISSINGS_REQUEST:
            return { ...state, isFetching: true };
        case FETCH_TOTAL_MISSINGS_SUCCESS:
            return {
                ...state,
                total: action.payload.total,
                isFetching: false
            };
        case FETCH_TOTAL_MISSINGS_ERROR:
            return {
                ...state,
                error: action.error,
                isFetching: false
            };

        case UPDATE_MISSINGS_REQUEST:
            return { ...state };
        case UPDATE_MISSINGS_SUCCESS:
            return {
                ...state,
                updating: false
            };
        case UPDATE_MISSINGS_ERROR:
            return {
                ...state,
                error: action.error,
                updating: false
            };


        default:
            return state;
    }
}