import {
  FETCH_INVENTORY_HISTORY_ERROR,
  FETCH_INVENTORY_HISTORY_SUCCESS,
  FETCH_INVENTORY_HISTORY_REQUEST,
} from "../types/index";
import {REACT_APP_BACKEND_URL} from "../../config";

import axios from "axios";

export const fetchInventoryHistory = (params) => (dispatch) => {
  let paramsStrings = "";
  for (const param in params) {
    if (paramsStrings.length > 0) {
      paramsStrings += "&";
    }
    paramsStrings += param + "=" + params[param];
  }

  dispatch({ type: FETCH_INVENTORY_HISTORY_REQUEST });
  axios.get(REACT_APP_BACKEND_URL + "/getHistory.php?" + paramsStrings).then((resp) => {
      dispatch({
        type: FETCH_INVENTORY_HISTORY_SUCCESS,
        payload: {
          history: resp.data
        },
      });
    })
    .catch((e) => {
      dispatch({
        type: FETCH_INVENTORY_HISTORY_ERROR,
        error: e.toString(),
      });
    });
};
