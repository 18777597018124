import { FETCH_SECTIONALS_USER_REQUEST, FETCH_SECTIONALS_USER_SUCCESS, FETCH_SECTIONALS_USER_ERROR } from "../types";

import axios from 'axios';

export const fetchSectionalsUser = (params) => (dispatch) => {
    let paramsStrings = "";
    for (const param in params) {
        if (paramsStrings.length > 0) {
            paramsStrings += "&";
        }
        paramsStrings += param + "=" + params[param];
    }
    dispatch({ type: FETCH_SECTIONALS_USER_REQUEST });
    axios.get(process.env.REACT_APP_BACKEND_URL + '/sectionals?' + paramsStrings)
        .then(res => {
            // console.log("ESTA ES LA RESPUESTA DE LAS SECCIONALES ", res.data);
            dispatch({
                type: FETCH_SECTIONALS_USER_SUCCESS,
                payload: {
                    sectionals: res.data
                }
            })
        })
        .catch(error => {
            dispatch({
                type: FETCH_SECTIONALS_USER_ERROR,
                error: error.toString()
            })
        })
}