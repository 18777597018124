// src/routes/AppRoutes.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import OnlineInventory  from '../../pages/OnlineInventory/OnlineInventory';
import AssignedAsset    from '../../pages/OnlineInventory/AssignedAsset';
import InventoryHistory from '../../pages/InventoryHistory/InventoryHistory';
import Uploadyear         from '../../pages/Uploadyear/Uploadyear';
import Followup         from '../../pages/Followup/Followup';
import Firstonly        from '../../pages/Firstonly/Firstonly';

export default function Routers() {
  window.sessionStorage.setItem('_abrirRegistrar', false);
  return (
    <Routes>
      {/* Define tus rutas */}

      <Route path="/toreport" element={<InventoryHistory />} />
      <Route path="/uploadyear" element={<Uploadyear />} />
      <Route path="/followups" element={<Followup />} />
      <Route path="/firstonly" element={<Firstonly />} />
      <Route path="/registrar" element={<OnlineInventory />} />
      <Route path="/" element={<InventoryHistory/>} />
      

    </Routes>
  );
}
