import { GET_SURPLUS_SEARCH_PARAMS } from "../types";

const initialState = {
    placa: localStorage.getItem('placaSearch') || '',
    serie: localStorage.getItem('serieSearch') || ''
}

export default function surplusSearched (state = initialState, action) {
    switch (action.type) {
        
        case GET_SURPLUS_SEARCH_PARAMS:
            window.sessionStorage.setItem('placaSearch',  action.payload.placa);
            window.sessionStorage.setItem('serieSearch', action.payload.serie);
            return {
                ...state,
                placa: action.payload.placa,
                serie: action.payload.serie
            };
        default:
            return {
                ...state
            };
    }
}