export const FETCH_COUNTRIES_REQUEST = 'FETCH_COUNTRIES_REQUEST';
export const FETCH_COUNTRIES_SUCCESS = 'FETCH_COUNTRIES_SUCCESS';
export const FETCH_COUNTRIES_ERROR   = 'FETCH_COUNTRIES_ERROR';

export const FETCH_BASE_REQUEST = 'FETCH_BASE_REQUEST';
export const FETCH_BASE_SUCCESS = 'FETCH_BASE_SUCCESS';
export const FETCH_BASE_ERROR   = 'FETCH_BASE_ERROR';

export const FETCH_CITY_REQUEST = 'FETCH_CITY_REQUEST';
export const FETCH_CITY_SUCCESS = 'FETCH_CITY_SUCCESS';
export const FETCH_CITY_ERROR   = 'FETCH_CITY_ERROR';

export const FETCH_CIUO_REQUEST = 'FETCH_CIUO_REQUEST';
export const FETCH_CIUO_SUCCESS = 'FETCH_CIUO_SUCCESS';
export const FETCH_CIUO_ERROR   = 'FETCH_CIUO_ERROR';

export const FETCH_CIUU_REQUEST = 'FETCH_CIUU_REQUEST';
export const FETCH_CIUU_SUCCESS = 'FETCH_CIUU_SUCCESS';
export const FETCH_CIUU_ERROR   = 'FETCH_CIUU_ERROR';

export const FETCH_CLASSIFICATION_REQUEST = 'FETCH_CLASSIFICATION_REQUEST';
export const FETCH_CLASSIFICATION_SUCCESS = 'FETCH_CLASSIFICATION_SUCCESS';
export const FETCH_CLASSIFICATION_ERROR   = 'FETCH_CLASSIFICATION_ERROR';

export const FETCH_DECEASE_REQUEST = 'FETCH_DECEASE_REQUEST';
export const FETCH_DECEASE_SUCCESS = 'FETCH_DECEASE_SUCCESS';
export const FETCH_DECEASE_ERROR   = 'FETCH_DECEASE_ERROR';

export const FETCH_DIFERENCE_REQUEST = 'FETCH_DIFERENCE_REQUEST';
export const FETCH_DIFERENCE_SUCCESS = 'FETCH_DIFERENCE_SUCCESS';
export const FETCH_DIFERENCE_ERROR   = 'FETCH_DIFERENCE_ERROR';

export const FETCH_SYNC_SIAF_REQUEST = 'FETCH_SYNC_SIAF_REQUEST';
export const FETCH_SYNC_SIAF_SUCCESS = 'FETCH_SYNC_SIAF_SUCCESS';
export const FETCH_SYNC_SIAF_ERROR = 'FETCH_SYNC_SIAF_ERROR';

export const FETCH_BLOCK_REQUEST = 'FETCH_BLOCK_REQUEST';
export const FETCH_BLOCK_SUCCESS = 'FETCH_BLOCK_SUCCESS';
export const FETCH_BLOCK_ERROR = 'FETCH_BLOCK_ERROR';

export const FETCH_UBICATIONS_ASSETS_REQUEST = 'FETCH_UBICATIONS_ASSETS_REQUEST';
export const FETCH_UBICATIONS_ASSETS_SUCCESS = 'FETCH_UBICATIONS_ASSETS_SUCCESS';
export const FETCH_UBICATIONS_ASSETS_ERROR = 'FETCH_UBICATIONS_ASSETS_ERROR';

export const FETCH_EPSARS_REQUEST = 'FETCH_EPSARS_REQUEST';
export const FETCH_EPSARS_SUCCESS = 'FETCH_EPSARS_SUCCESS';
export const FETCH_EPSARS_ERROR   = 'FETCH_EPSARS_ERROR';

export const FETCH_ASSETS_SURPLUS_REQUEST = 'FETCH_ASSETS_SURPLUS_REQUEST';
export const FETCH_ASSETS_SURPLUS_SUCCESS = 'FETCH_ASSETS_SURPLUS_SUCCESS';
export const FETCH_ASSETS_SURPLUS_ERROR = 'FETCH_ASSETS_SURPLUS_ERROR';

export const FETCH_AUTH_USER_REQUEST = 'FETCH_AUTH_USER_REQUEST';
export const FETCH_AUTH_USER_SUCCESS = 'FETCH_AUTH_USER_SUCCESS';
export const FETCH_AUTH_USER_ERROR   = 'FETCH_AUTH_USER_ERROR';

export const FETCH_EVALUATOR_REQUEST = 'FETCH_EVALUATOR_REQUEST';
export const FETCH_EVALUATOR_SUCCESS = 'FETCH_EVALUATOR_SUCCESS';
export const FETCH_EVALUATOR_ERROR   = 'FETCH_EVALUATOR_ERROR';

export const FETCH_REGISTER_USER_REQUEST = 'FETCH_REGISTER_USER_REQUEST';
export const FETCH_REGISTER_USER_SUCCESS = 'FETCH_REGISTER_USER_SUCCESS';
export const FETCH_REGISTER_USER_ERROR   = 'FETCH_REGISTER_USER_ERROR';

export const FETCH_ASSETS_SURPLUS_COMPLETE_REQUEST = 'FETCH_ASSETS_SURPLUS_COMPLETE_REQUEST';
export const FETCH_ASSETS_SURPLUS_COMPLETE_SUCCESS = 'FETCH_ASSETS_SURPLUS_COMPLETE_SUCCESS';
export const FETCH_ASSETS_SURPLUS_COMPLETE_ERROR = 'FETCH_ASSETS_SURPLUS_COMPLETE_ERROR';

export const FETCH_MAINTOP_REQUEST = 'FETCH_MAINTOP_REQUEST';
export const FETCH_MAINTOP_SUCCESS = 'FETCH_MAINTOP_SUCCESS';
export const FETCH_MAINTOP_ERROR = 'FETCH_MAINTOP_ERROR';

export const FETCH_NOTIFICATIONS_REQUEST = 'FETCH_NOTIFICATIONS_REQUEST';
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';
export const FETCH_NOTIFICATIONS_ERROR = 'FETCH_NOTIFICATIONS_ERROR';

export const CREATE_NOTIFICATIONS_APROVED_REQUEST = 'CREATE_NOTIFICATIONS_APROVED_REQUEST';
export const CREATE_NOTIFICATIONS_APROVED_SUCCESS = 'CREATE_NOTIFICATIONS_APROVED_SUCCESS';
export const CREATE_NOTIFICATIONS_APROVED_ERROR = 'CREATE_NOTIFICATIONS_APROVED_ERROR';

export const FETCH_INVENTORY_HISTORY_REQUEST = 'FETCH_INVENTORY_HISTORY_REQUEST';
export const FETCH_INVENTORY_HISTORY_SUCCESS = 'FETCH_INVENTORY_HISTORY_SUCCESS';
export const FETCH_INVENTORY_HISTORY_ERROR = 'FETCH_INVENTORY_HISTORY_ERROR';

export const FETCH_EVALUATIONS_REQUEST = 'FETCH_EVALUATIONS_REQUEST';
export const FETCH_EVALUATIONS_SUCCESS = 'FETCH_EVALUATIONS_SUCCESS';
export const FETCH_EVALUATIONS_ERROR = 'FETCH_EVALUATIONS_ERROR';

export const FETCH_SCORE_REQUEST = 'FETCH_SCORE_REQUEST';
export const FETCH_SCORE_SUCCESS = 'FETCH_SCORE_SUCCESS';
export const FETCH_SCORE_ERROR = 'FETCH_SCORE_ERROR';

export const FETCH_DASHBOARD_REQUEST = 'FETCH_DASHBOARD_REQUEST';
export const FETCH_DASHBOARD_SUCCESS = 'FETCH_DASHBOARD_SUCCESS';
export const FETCH_DASHBOARD_ERROR   = 'FETCH_DASHBOARD_ERROR';

export const FETCH_ASSIGNED_ASSETS_REQUEST = 'FETCH_ASSIGNED_ASSETS_REQUEST';
export const FETCH_ASSIGNED_ASSETS_SUCCESS = 'FETCH_ASSIGNED_ASSETS_SUCCESS';
export const FETCH_ASSIGNED_ASSETS_ERROR = 'FETCH_ASSIGNED_ASSETS_ERROR';

export const FETCH_ASSIGNED_ASSETS_FOR_PDF_REQUEST = 'FETCH_ASSIGNED_ASSETS_FOR_PDF_REQUEST';
export const FETCH_ASSIGNED_ASSETS_FOR_PDF_SUCCESS = 'FETCH_ASSIGNED_ASSETS_FOR_PDF_SUCCESS';
export const FETCH_ASSIGNED_ASSETS_FOR_PDF_ERROR = 'FETCH_ASSIGNED_ASSETS_FOR_PDF_ERROR';

export const FETCH_ASSETS_STAGES_USER_REQUEST = 'FETCH_ASSETS_STAGES_USER_REQUEST';
export const FETCH_ASSETS_STAGES_USER_SUCCESS = 'FETCH_ASSETS_STAGES_USER_SUCCESS';
export const FETCH_ASSETS_STAGES_USER_ERROR = 'FETCH_ASSETS_STAGES_USER_ERROR';

export const FETCH_ASSETS_STATUS_REQUEST = 'FETCH_ASSETS_STATUS_REQUEST';
export const FETCH_ASSETS_STATUS_SUCCESS = 'FETCH_ASSETS_STATUS_SUCCESS';
export const FETCH_ASSETS_STATUS_ERROR   = 'FETCH_ASSETS_STATUS_ERROR';

export const FETCH_SECTIONALS_USER_REQUEST = 'FETCH_SECTIONALS_USER_REQUEST';
export const FETCH_SECTIONALS_USER_SUCCESS = 'FETCH_SECTIONALS_USER_SUCCESS';
export const FETCH_SECTIONALS_USER_ERROR = 'FETCH_SECTIONALS_USER_ERROR';

export const FETCH_ASSETS_CATEGORIES_REQUEST = 'FETCH_ASSETS_CATEGORIES_REQUEST';
export const FETCH_ASSETS_CATEGORIES_SUCCESS = 'FETCH_ASSETS_CATEGORIES_SUCCESS';
export const FETCH_ASSETS_CATEGORIES_ERROR = 'FETCH_ASSETS_CATEGORIES_ERROR';

export const GET_USER = 'GET_USER';
export const GET_ACTUAL_PARAMS = 'GET_ACTUAL_PARAMS';
export const GET_SURPLUS_SEARCH_PARAMS = 'GET_SURPLUS_SEARCH_PARAMS';


export const FETCH_ERRORHISTO_REQUEST = 'FETCH_ERRORHISTO_REQUEST';
export const FETCH_ERRORHISTO_SUCCESS = 'FETCH_ERRORHISTO_SUCCESS';
export const FETCH_ERRORHISTO_ERROR = 'FETCH_ERRORHISTO_ERROR';

export const FETCH_LATERALITY_REQUEST = 'FETCH_LATERALITY_REQUEST';
export const FETCH_LATERALITY_SUCCESS = 'FETCH_LATERALITY_SUCCESS';
export const FETCH_LATERALITY_ERROR = 'FETCH_LATERALITY_ERROR';

export const FETCH_MEMBER_REQUEST = 'FETCH_MEMBER_REQUEST';
export const FETCH_MEMBER_SUCCESS = 'FETCH_MEMBER_SUCCESS';
export const FETCH_MEMBER_ERROR   = 'FETCH_MEMBER_ERROR';

export const FETCH_NOTREATMENT_REQUEST = 'FETCH_NOTREATMENT_REQUEST';
export const FETCH_NOTREATMENT_SUCCESS = 'FETCH_NOTREATMENT_SUCCESS';
export const FETCH_NOTREATMENT_ERROR = 'FETCH_NOTREATMENT_ERROR';

export const FETCH_LOCATIONS_REQUEST = 'FETCH_LOCATIONS_REQUEST';
export const FETCH_LOCATIONS_SUCCESS = 'FETCH_LOCATIONS_SUCCESS';
export const FETCH_LOCATIONS_ERROR = 'FETCH_LOCATIONS_ERROR';

export const FETCH_WITHOUT_FILLINGOUT_REQUEST = 'FETCH_WITHOUT_FILLINGOUT_REQUEST';
export const FETCH_WITHOUT_FILLINGOUT_SUCCESS = 'FETCH_WITHOUT_FILLINGOUT_SUCCESS';
export const FETCH_WITHOUT_FILLINGOUT_ERROR = 'FETCH_WITHOUT_FILLINGOUT_ERROR';

export const FETCH_MISSINGS_REQUEST = 'FETCH_MISSINGS_REQUEST';
export const FETCH_MISSINGS_SUCCESS = 'FETCH_MISSINGS_SUCCESS';
export const FETCH_MISSINGS_ERROR = 'FETCH_MISSINGS_ERROR';

export const FETCH_REPORT_MISSINGS_REQUEST = 'FETCH_REPORT_MISSINGS_REQUEST';
export const FETCH_REPORT_MISSINGS_SUCCESS = 'FETCH_REPORT_MISSINGS_SUCCESS';
export const FETCH_REPORT_MISSINGS_ERROR = 'FETCH_REPORT_MISSINGS_ERROR';

export const UPDATE_REPORT_MISSINGS_REQUEST = 'UPDATE_REPORT_MISSINGS_REQUEST';
export const UPDATE_REPORT_MISSINGS_SUCCESS = 'UPDATE_REPORT_MISSINGS_SUCCESS';
export const UPDATE_REPORT_MISSINGS_ERROR = 'UPDATE_REPORT_MISSINGS_ERROR';

export const UPDATE_MISSINGS_REQUEST = 'UPDATE_MISSINGS_REQUEST';
export const UPDATE_MISSINGS_SUCCESS = 'UPDATE_MISSINGS_SUCCESS';
export const UPDATE_MISSINGS_ERROR = 'UPDATE_MISSINGS_ERROR';

export const FETCH_TOTAL_MISSINGS_REQUEST = 'FETCH_TOTAL_MISSINGS_REQUEST';
export const FETCH_TOTAL_MISSINGS_SUCCESS = 'FETCH_TOTAL_MISSINGS_SUCCESS';
export const FETCH_TOTAL_MISSINGS_ERROR = 'FETCH_TOTAL_MISSINGS_ERROR';

export const FETCH_PERIOD_REQUEST = 'FETCH_PERIOD_REQUEST';
export const FETCH_PERIOD_SUCCESS = 'FETCH_PERIOD_SUCCESS';
export const FETCH_PERIOD_ERROR = 'FETCH_PERIOD_ERROR';

export const FETCH_HISTOLOGICAL_REQUEST = 'FETCH_HISTOLOGICAL_REQUEST';
export const FETCH_HISTOLOGICAL_SUCCESS = 'FETCH_HISTOLOGICAL_SUCCESS';
export const FETCH_HISTOLOGICAL_ERROR = 'FETCH_HISTOLOGICAL_ERROR';

export const FETCH_TOPOGRAPHIC_REQUEST = 'FETCH_TOPOGRAPHIC_REQUEST';
export const FETCH_TOPOGRAPHIC_SUCCESS = 'FETCH_TOPOGRAPHIC_SUCCESS';
export const FETCH_TOPOGRAPHIC_ERROR = 'FETCH_TOPOGRAPHIC_ERROR';

export const FETCH_IPS_REQUEST = 'FETCH_IPS_REQUEST';
export const FETCH_IPS_SUCCESS = 'FETCH_IPS_SUCCESS';
export const FETCH_IPS_ERROR = 'FETCH_IPS_ERROR';

export const FETCH_VALIDATION_REQUEST = 'FETCH_VALIDATION_REQUEST';
export const FETCH_VALIDATION_SUCCESS = 'FETCH_VALIDATION_SUCCESS';
export const FETCH_VALIDATION_ERROR = 'FETCH_VALIDATION_ERROR';

export const FETCH_CIE10_REQUEST = 'FETCH_CIE10_REQUEST';
export const FETCH_CIE10_SUCCESS = 'FETCH_CIE10_SUCCESS';
export const FETCH_CIE10_ERROR   = 'FETCH_CIE10_ERROR';

export const FETCH_OTHER_REQUEST = 'FETCH_OTHER_REQUEST';
export const FETCH_OTHER_SUCCESS = 'FETCH_OTHER_SUCCESS';
export const FETCH_OTHER_ERROR   = 'FETCH_OTHER_ERROR';

export const FETCH_SEX_REQUEST = 'FETCH_SEX_REQUEST';
export const FETCH_SEX_SUCCESS = 'FETCH_SEX_SUCCESS';
export const FETCH_SEX_ERROR   = 'FETCH_SEX_ERROR';

export const FETCH_TYPE_IDENTIFICATION_REQUEST = 'FETCH_TYPE_IDENTIFICATION_REQUEST';
export const FETCH_TYPE_IDENTIFICATION_SUCCESS = 'FETCH_TYPE_IDENTIFICATION_SUCCESS';
export const FETCH_TYPE_IDENTIFICATION_ERROR   = 'FETCH_TYPE_IDENTIFICATION_ERROR';

export const FETCH_TYPE_REQUEST = 'FETCH_TYPE_REQUEST';
export const FETCH_TYPE_SUCCESS = 'FETCH_TYPE_SUCCESS';
export const FETCH_TYPE_ERROR   = 'FETCH_TYPE_ERROR';

export const FETCH_TYPEUSER_REQUEST = 'FETCH_TYPE_USER_REQUEST';
export const FETCH_TYPEUSER_SUCCESS = 'FETCH_TYPE_USER_SUCCESS';
export const FETCH_TYPEUSER_ERROR   = 'FETCH_TYPE_USER_ERROR';

export const FETCH_ZONE_REQUEST = 'FETCH_ZONE_REQUEST';
export const FETCH_ZONE_SUCCESS = 'FETCH_ZONE_SUCCESS';
export const FETCH_ZONE_ERROR   = 'FETCH_ZONE_ERROR';

export const FETCH_TYPETEL_REQUEST = 'FETCH_TYPETEL_REQUEST';
export const FETCH_TYPETEL_SUCCESS = 'FETCH_TYPETEL_SUCCESS';
export const FETCH_TYPETEL_ERROR   = 'FETCH_TYPETEL_ERROR';

export const FETCH_TYPEEPS_REQUEST = 'FETCH_TYPEEPS_REQUEST';
export const FETCH_TYPEEPS_SUCCESS = 'FETCH_TYPEEPS_SUCCESS';
export const FETCH_TYPEEPS_ERROR   = 'FETCH_TYPEEPS_ERROR';

export const UPLOAD_FILES_REQUEST = 'UPLOAD_FILES_REQUEST';
export const UPLOAD_FILES_SUCCESS = 'UPLOAD_FILES_SUCCESS';
export const UPLOAD_FILES_ERROR = 'UPLOAD_FILES_ERROR';

export const DOWNLOAD_FILES_REQUEST = 'DOWNLOAD_FILES_REQUEST';
export const DOWNLOAD_FILES_SUCCESS = 'DOWNLOAD_FILES_SUCCESS';
export const DOWNLOAD_FILES_ERROR = 'DOWNLOAD_FILES_ERROR';

export const DELETE_FILES_REQUEST = 'DELETE_FILES_REQUEST';
export const DELETE_FILES_SUCCESS = 'DELETE_FILES_SUCCESS';
export const DELETE_FILES_ERROR = 'DELETE_FILES_ERROR';

export const FETCH_DATA_USER_REQUEST = 'FETCH_DATA_USER_REQUEST';
export const FETCH_DATA_USER_SUCCESS = 'FETCH_DATA_USER_SUCCESS';
export const FETCH_DATA_USER_ERROR   = 'FETCH_DATA_USER_ERROR';

export const FETCH_CHANGES_TYPES_REQUEST = 'FETCH_CHANGES_TYPES_REQUEST';
export const FETCH_CHANGES_TYPES_SUCCESS = 'FETCH_CHANGES_TYPES_SUCCESS';
export const FETCH_CHANGES_TYPES_ERROR   = 'FETCH_CHANGES_TYPES_ERROR';

export const FETCH_MISSINGS_REASONS_REQUEST = 'FETCH_MISSINGS_REASONS_REQUEST';
export const FETCH_MISSINGS_REASONS_SUCCESS = 'FETCH_MISSINGS_REASONS_SUCCESS';
export const FETCH_MISSINGS_REASONS_ERROR   = 'FETCH_MISSINGS_REASONS_ERROR';

export const FETCH_SURPLUSASSETTYPES_REQUEST= 'FETCH_SURPLUSASSETTYPES_REQUEST';
export const FETCH_SURPLUSASSETTYPES_SUCCESS= 'FETCH_SURPLUSASSETTYPES_SUCCESS';
export const FETCH_SURPLUSASSETTYPES_ERROR  = 'FETCH_SURPLUSASSETTYPES_ERROR';
