import React from 'react';
import { makeStyles } from '@mui/styles';

import { useTheme } from '@mui/styles';
import { styled} from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    margin: 'auto',
    marginBottom: 10,
  },
  paperOut: {
    backgroundColor: '#ffffa3',
    margin: 'auto',
    maxWidth: 1000,
    marginBottom: 10,
  },
  text: {
    padding: 0,
    margin: 0,
  },
  image: {
    width: 128,
    height: 128,
  },
  image2: {
    width: 20,
    height: 20,
  },
  img: {
    margin: 'auto',
    display: 'block',
    height: '2.5rem',
  },
  tab: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    color: '#4caf50', // Letra verde
    transition: 'all 0.3s ease',
    width: '50%', // Hace que cada Tab ocupe el 50% del ancho
    maxWidth: '50%', // Limita el ancho máximo al 50%
    '&:hover': {
      color: '#f44336', // Letra roja al pasar el mouse por encima
    },
    '&.Mui-selected': {
      color: '#4caf50', // Letra verde cuando está seleccionado
      backgroundColor: '#e8f5e9', // Fondo verde claro cuando está seleccionado
      borderRadius: '8px', // Borde redondeado
      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Sombra para resaltar el tab
      fontWeight: 'bold', // Fuente en negrita
    },
    '&.Mui-disabled': {
      color: '#000 !important', // Letra negra cuando está desactivado
      fontWeight: 'bold !important', // Negrita cuando está desactivado
    },
  },
  indicator: {
    backgroundColor: '#f44336', // Indicador rojo
  },
}));

  // const useStyles = () => {
  //   const theme = useTheme();
    
  //   return {
  //     root: {
  //       display: 'flex',
  //       background: '#F0FFF0',
  //     },
  //     textNav: {
  //       fontSize: '0.875rem',
  //       margin: '0 0.5rem',
  //       fontWeight: '400',
  //     },
  //     toolbar: {
  //       paddingRight: 0,
  //       paddingLeft: 0,
  //       width: '100%',
  //     },
  //     toolbarIcon: {
  //       display: 'flex',
  //       alignItems: 'center',
  //       justifyContent: 'space-between',
  //       padding: '23px 8px',
  //       ...theme.mixins.toolbar,
  //     },
  //     appBar: {
  //       backgroundColor: 'transparent',
  //       color: '#000',
  //       boxShadow: 'none',
  //       marginRight: '3.5rem',
  //       zIndex: theme.zIndex.drawer + 1,
  //     },
  //     appBarShift: {
  //       width: '100%',
  //     },
  //     menuButton: {
  //       marginRight: 0,
  //     },
  //     menuButtonHidden: {
  //       display: 'none',
  //     },
  //     fatherTitle: {
  //       display: 'flex',
  //     },
  //     title: {
  //       fontWeight: '600',
  //       color: '#00B88B',
  //       fontSize: '2rem',
  //     },
  //     title2: {
  //       fontWeight: '600',
  //       color: '#202533',
  //       fontSize: '1.4rem',
  //       paddingLeft: '0.5rem',
  //     },
  //     titleInline: {
  //       placeSelf: 'baseline',
  //     },
  //     drawerPaper: {
  //       position: 'relative',
  //       whiteSpace: 'nowrap',
  //       width: 240, // Asigna el valor que tenías en drawerWidth
  //     },
  //     drawerPaperClose: {
  //       overflowX: 'hidden',
  //       position: 'relative',
  //       width: theme.spacing(7),
  //       [theme.breakpoints.up('sm')]: {
  //         width: theme.spacing(9),
  //       },
  //     },
  //     appBarSpacer: theme.mixins.toolbar,
  //     content: {
  //       flexGrow: 1,
  //       height: '100vh',
  //       width: '100%',
  //       overflow: 'hidden',
  //       background: '#F9FAFE',
  //     },
  //     container: {
  //       paddingTop: theme.spacing(2),
  //       paddingBottom: theme.spacing(4),
  //       maxWidth: 'none',
  //     },
  //     paper: {
  //       padding: theme.spacing(2),
  //       display: 'flex',
  //       overflow: 'auto',
  //       flexDirection: 'column',
  //     },
  //     fixedHeight: {
  //       height: 240,
  //     },
  //     image: {
  //       width: 230, // Remueve las comillas
  //       height: 70,  // Remueve las comillas
  //     },
  //     img: {
  //       margin: 'auto',
  //       display: 'block',
  //       maxWidth: '20%',
  //       maxHeight: '20%',
  //     },
  //     listItem: {
  //       width: '100%',
  //       maxWidth: 360,
  //       backgroundColor: theme.palette.background.paper,
  //     },
  //   };
  // };
  
  export default useStyles;