import { FETCH_NOTIFICATIONS_ERROR, FETCH_NOTIFICATIONS_SUCCESS, FETCH_NOTIFICATIONS_REQUEST, CREATE_NOTIFICATIONS_APROVED_REQUEST, CREATE_NOTIFICATIONS_APROVED_SUCCESS, CREATE_NOTIFICATIONS_APROVED_ERROR } from '../types/index';

import axios from 'axios';


export const fetchAllNotifications = (params) => (dispatch) => {
    dispatch({
        type: FETCH_NOTIFICATIONS_REQUEST, payload: {
            mount: params.mount
        }
    });

    axios.get(process.env.REACT_APP_BACKEND_URL + '/notifications?user_susi_id=' + params.user + '&page=' + params.page)
        .then(res => {
            dispatch({
                type: FETCH_NOTIFICATIONS_SUCCESS,
                payload: {
                    data: res.data,
                    page: params.page
                }
            })
        })
        .catch(error => {
            dispatch({
                type: FETCH_NOTIFICATIONS_ERROR,
                error: error.toString()
            })
        })
}

export const getNotyByState = (id, state, identification) => (dispatch) => {
    dispatch({ type: CREATE_NOTIFICATIONS_APROVED_REQUEST });
    
    axios.post(process.env.REACT_APP_BACKEND_URL + '/notifications/state', { state, identification, id }).then((data) => {
        dispatch({ type: CREATE_NOTIFICATIONS_APROVED_SUCCESS });
    }).catch(error => {
        console.error('Error al generar notificaciones de estado de faltantes: ', error);
        dispatch({
            type: CREATE_NOTIFICATIONS_APROVED_ERROR,
            error: error.toString()
        })
    })
}

export const checkNotifications = async (params) => {
    let paramsStrings = "";
    for (const param in params) {
        if (paramsStrings.length > 0) {
            paramsStrings += "&";
        }
        paramsStrings += param + "=" + params[param];
    }
    return await axios.put(process.env.REACT_APP_BACKEND_URL + '/notifications/read?' + paramsStrings)
        .then((resp) => { return resp.data; }).catch((err) => { return err; })
}