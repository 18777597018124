import {FETCH_COUNTRIES_REQUEST, FETCH_COUNTRIES_SUCCESS, FETCH_COUNTRIES_ERROR,
    FETCH_BASE_REQUEST, FETCH_BASE_SUCCESS, FETCH_BASE_ERROR,
    FETCH_CITY_REQUEST, FETCH_CITY_SUCCESS, FETCH_CITY_ERROR,
    FETCH_CIUO_REQUEST, FETCH_CIUO_SUCCESS, FETCH_CIUO_ERROR,
    FETCH_CIUU_REQUEST, FETCH_CIUU_SUCCESS, FETCH_CIUU_ERROR,
    FETCH_CLASSIFICATION_REQUEST, FETCH_CLASSIFICATION_SUCCESS, FETCH_CLASSIFICATION_ERROR,
    FETCH_DECEASE_REQUEST, FETCH_DECEASE_SUCCESS, FETCH_DECEASE_ERROR,
    FETCH_DIFERENCE_REQUEST, FETCH_DIFERENCE_SUCCESS, FETCH_DIFERENCE_ERROR,
    FETCH_EPSARS_REQUEST, FETCH_EPSARS_SUCCESS, FETCH_EPSARS_ERROR,
    FETCH_ERRORHISTO_REQUEST, FETCH_ERRORHISTO_SUCCESS, FETCH_ERRORHISTO_ERROR,
    FETCH_LATERALITY_REQUEST, FETCH_LATERALITY_SUCCESS, FETCH_LATERALITY_ERROR,
    FETCH_NOTREATMENT_REQUEST, FETCH_NOTREATMENT_SUCCESS, FETCH_NOTREATMENT_ERROR, FETCH_MAINTOP_REQUEST, FETCH_MAINTOP_SUCCESS, FETCH_MAINTOP_ERROR, FETCH_PERIOD_REQUEST, FETCH_PERIOD_SUCCESS, FETCH_PERIOD_ERROR, FETCH_HISTOLOGICAL_REQUEST, FETCH_HISTOLOGICAL_SUCCESS, FETCH_HISTOLOGICAL_ERROR, FETCH_TOPOGRAPHIC_REQUEST, FETCH_TOPOGRAPHIC_SUCCESS, FETCH_TOPOGRAPHIC_ERROR, FETCH_IPS_REQUEST, FETCH_IPS_SUCCESS, FETCH_IPS_ERROR, FETCH_VALIDATION_REQUEST, FETCH_VALIDATION_SUCCESS, FETCH_VALIDATION_ERROR,
    FETCH_OTHER_REQUEST, FETCH_OTHER_SUCCESS, FETCH_OTHER_ERROR,
    FETCH_TYPE_REQUEST, FETCH_TYPE_SUCCESS, FETCH_TYPE_ERROR,
    FETCH_TYPEEPS_REQUEST, FETCH_TYPEEPS_SUCCESS, FETCH_TYPEEPS_ERROR,
    FETCH_TYPETEL_REQUEST, FETCH_TYPETEL_SUCCESS, FETCH_TYPETEL_ERROR,
    FETCH_TYPEUSER_REQUEST, FETCH_TYPEUSER_SUCCESS, FETCH_TYPEUSER_ERROR,
    FETCH_ZONE_REQUEST, FETCH_ZONE_SUCCESS, FETCH_ZONE_ERROR,
    FETCH_SEX_REQUEST, FETCH_SEX_SUCCESS, FETCH_SEX_ERROR,
    FETCH_CHANGES_TYPES_REQUEST, FETCH_CHANGES_TYPES_SUCCESS, FETCH_CHANGES_TYPES_ERROR
} from "../types";

const initialState = {
    categories: [],
    city: [],
    errorhisto    : [],
    countries     : [],
    ciuu          : [],
    ciuo          : [],
    classification: [],
    decease       : [],
    diference     : [],
    base          : [],
    laterality    : [],
    notreatment   : [],
    maintop       : [],
    period        : [],
    histological  : [],
    topographic   : [],
    validation    : [],
    ips           : [],
    cie10         : [],
    epsars        : [],
    other         : [],
    sex           : [],
    type          : [],
    typeeps       : [],
    typetel       : [],
    typeuser      : [],
    zone          : [],

    company: [],
    evaluationType: [],
    speciality: [],
    inventoryOfficers: [],
    assetsStatus: [],
    changesTypes: [],
    missingReasons: [],
    typeTransact: [],
    typeDcoument: [],
    replacementStatus: [],
    transactTypesDocuments: [],
    reprintReasons: [],
    isFetching: false,
    error: null
}

export default function SUSILists(state = initialState, action) {
    switch (action.type) {


        case FETCH_COUNTRIES_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case FETCH_COUNTRIES_SUCCESS:
            return {
                ...state,
                isFetching: false,
                countries: action.payload.countries
            };
        case FETCH_COUNTRIES_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
            };
 
        case FETCH_BASE_REQUEST:
             return {
                ...state,
                isFetching: true
            };
        case FETCH_BASE_SUCCESS:
             return {
                ...state,
                isFetching: false,
                base: action.payload.base
             };
        case FETCH_BASE_ERROR:
             return {
                ...state,
                isFetching: false,
                error: action.error
             };
    
        case FETCH_CITY_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case FETCH_CITY_SUCCESS:
            return {
                ...state,
                isFetching: false,
                city: action.payload.city
            };
        case FETCH_CITY_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
            };

        case FETCH_CIUO_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case FETCH_CIUO_SUCCESS:
            return {
                ...state,
                isFetching: false,
                ciuo: action.payload.ciuo
            };
        case FETCH_CIUO_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
            };
     
        case FETCH_CIUU_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case FETCH_CIUU_SUCCESS:
            return {
                ...state,
                isFetching: false,
                ciuu: action.payload.ciuu
            };
        case FETCH_CIUU_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
            };
        
        case FETCH_CLASSIFICATION_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case FETCH_CLASSIFICATION_SUCCESS:
            return {
                ...state,
                isFetching: false,
                classification: action.payload.classification
            };
        case FETCH_CLASSIFICATION_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
            };

        case FETCH_DECEASE_REQUEST:
                return {
                    ...state,
                    isFetching: true
                };
        case FETCH_DECEASE_SUCCESS:
                return {
                    ...state,
                    isFetching: false,
                    decease: action.payload.decease
                };
        case FETCH_DECEASE_ERROR:
                return {
                    ...state,
                    isFetching: false,
                    error: action.error
                };            

        case FETCH_DIFERENCE_REQUEST:
                    return {
                        ...state,
                        isFetching: true
                    };
        case FETCH_DIFERENCE_SUCCESS:
                    return {
                        ...state,
                        isFetching: false,
                        diference: action.payload.diference
                    };
        case FETCH_DIFERENCE_ERROR:
                    return {
                        ...state,
                        isFetching: false,
                        error: action.error
                    };     

        case FETCH_EPSARS_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case FETCH_EPSARS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                epsars: action.payload.epsars
            };
        case FETCH_EPSARS_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
            };

        case FETCH_ERRORHISTO_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case FETCH_ERRORHISTO_SUCCESS:
            return {
                ...state,
                isFetching: false,
                errorhisto: action.payload.errorhisto
            };
        case FETCH_ERRORHISTO_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
            };
        case FETCH_LATERALITY_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case FETCH_LATERALITY_SUCCESS:
            return {
                ...state,
                isFetching: false,
                laterality: action.payload.laterality
            };
        case FETCH_LATERALITY_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
            };
        case FETCH_NOTREATMENT_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case FETCH_NOTREATMENT_SUCCESS:
            return {
                ...state,
                isFetching: false,
                notreatment: action.payload.notreatment
            };
        case FETCH_NOTREATMENT_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
            };
        case FETCH_MAINTOP_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case FETCH_MAINTOP_SUCCESS:
            return {
                ...state,
                isFetching: false,
                maintop: action.payload.maintop
            };
        case FETCH_MAINTOP_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
            };
        case FETCH_PERIOD_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case FETCH_PERIOD_SUCCESS:
            return {
                ...state,
                isFetching: false,
                period: action.payload.period
            };
        case FETCH_PERIOD_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
            };

            case FETCH_SEX_REQUEST:
                return {
                    ...state,
                    isFetching: true
                };
            case FETCH_SEX_SUCCESS:
                return {
                    ...state,
                    isFetching: false,
                    sex: action.payload.sex
                };
            case FETCH_SEX_ERROR:
                return {
                    ...state,
                    isFetching: false,
                    error: action.error
                };
    
        case FETCH_HISTOLOGICAL_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case FETCH_HISTOLOGICAL_SUCCESS:
            return {
                ...state,
                isFetching: false,
                histological: action.payload.histological
            };
        case FETCH_HISTOLOGICAL_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
            };
        case FETCH_TOPOGRAPHIC_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case FETCH_TOPOGRAPHIC_SUCCESS:
            return {
                ...state,
                isFetching: false,
                topographic: action.payload.topographic
            };
        case FETCH_TOPOGRAPHIC_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
            };
        case FETCH_IPS_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case FETCH_IPS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                ips: action.payload.ips
            };
        case FETCH_IPS_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
            };

        case FETCH_VALIDATION_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case FETCH_VALIDATION_SUCCESS:
            return {
                ...state,
                isFetching: false,
                validation: action.payload.validation
            };
        case FETCH_VALIDATION_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
            };

        case FETCH_OTHER_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case FETCH_OTHER_SUCCESS:
            return {
                ...state,
                isFetching: false,
                other: action.payload.other
            };
        case FETCH_OTHER_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
        };

        case FETCH_TYPE_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case FETCH_TYPE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                type: action.payload.type
            };
        case FETCH_TYPE_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
        };
        
        case FETCH_TYPEEPS_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case FETCH_TYPEEPS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                typeeps: action.payload.typeeps
            };
        case FETCH_TYPEEPS_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
        };

        case FETCH_TYPETEL_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case FETCH_TYPETEL_SUCCESS:
            return {
                ...state,
                isFetching: false,
                typetel: action.payload.typetel
            };
        case FETCH_TYPETEL_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
        };

        case FETCH_TYPEUSER_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case FETCH_TYPEUSER_SUCCESS:
            return {
                ...state,
                isFetching: false,
                typeuser: action.payload.typeuser
            };
        case FETCH_TYPEUSER_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
        };

        case FETCH_ZONE_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case FETCH_ZONE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                zone: action.payload.zone
            };
        case FETCH_ZONE_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
        };
            
        default:
            return state;
    }
}