import { GET_USER } from "../types";
//alert("INICIANDO ESTADO");
const initialState = {
    name: window.sessionStorage.getItem('_displayName'),
    fullName: window.sessionStorage.getItem('_fullName'),
    numero_documento: window.sessionStorage.getItem('_numeroDocumento'), 
    user_susi_id: window.sessionStorage.getItem('_numeroDocumento'), // cambiar cuando se modifique el numero de documento
    perfil_id: 1, //1 servidor, 2 Inventarios  TLA no tuve en cuenta esto quemado, lei el directorio activo en listitems
    email: window.sessionStorage.getItem('_displayMail'),
    celular: '3022376890',
    CORREO_REPORTAR_REIMPRESION_PLACA: window.sessionStorage.getItem('CORREO_REPORTAR_REIMPRESION_PLACA'),
    CORREO_REPORTAR_SOBRANTES: window.sessionStorage.getItem('CORREO_REPORTAR_SOBRANTES'),
    userRole: window.sessionStorage.getItem('userrole'),
    culmination_date: window.sessionStorage.getItem('NOTI_CULMINATION_DATE'),
    limit_file_size: window.sessionStorage.getItem('LIMIT_FILE_SIZE'),
    correo_gestion_faltantes: window.sessionStorage.getItem('CORREO_GESTION_FALTANTES')
}

export default function user (state = initialState, action){    
    switch (action.type) {
        case GET_USER:
            window.sessionStorage.setItem('NOTI_CULMINATION_DATE', action.payload.culmination_date);
            return {
                ...state,
                culmination_date: action.payload.culmination_date
            };
        default:
            return state;
    }
}