import { FETCH_DATA_USER_REQUEST, FETCH_DATA_USER_SUCCESS, FETCH_DATA_USER_ERROR } from "../types";

const initialState = {
    dataDirectory: [],
    isFetching: false,
    error: null
}

export default function dataUser(state = initialState, action) {
    switch (action.type) {
        case FETCH_DATA_USER_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case FETCH_DATA_USER_SUCCESS:
            return {
                ...state,
                isFetching: false,
                dataDirectory: action.payload.directory_user
            };
        case FETCH_DATA_USER_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
            };
        default:
            return state;
    }
}