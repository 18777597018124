import { DELETE_FILES_ERROR, DELETE_FILES_REQUEST, DELETE_FILES_SUCCESS, DOWNLOAD_FILES_ERROR, DOWNLOAD_FILES_REQUEST, DOWNLOAD_FILES_SUCCESS, UPLOAD_FILES_ERROR, UPLOAD_FILES_REQUEST, UPLOAD_FILES_SUCCESS } from "../types";

const initialState = {
    uploading: true,
    downloading: true,
    error: '',
    deleting: false,
    message: ''
}

export default function filesReducer(state = initialState, action) {
    switch (action.type) {
        case UPLOAD_FILES_REQUEST:
            return {
                ...state,
                uploading: true
            };
        case UPLOAD_FILES_SUCCESS:
            return {
                ...state,
                uploading: false,
                message: action.payload.message,
                files: action.payload.files
            };
        case UPLOAD_FILES_ERROR:
            return {
                ...state,
                uploading: false,
                error: action.error
            };
        case DOWNLOAD_FILES_REQUEST:
            return {
                ...state,
                downloading: true
            };
        case DOWNLOAD_FILES_SUCCESS:
            return {
                ...state,
                downloading: false,
                message: action.payload.message
            };
        case DOWNLOAD_FILES_ERROR:
            return {
                ...state,
                downloading: false,
                error: action.error
            };
            case DELETE_FILES_REQUEST:
                return {
                    ...state,
                    deleting: true
                };
            case DELETE_FILES_SUCCESS:
                return {
                    ...state,
                    deleting: false
                };
            case DELETE_FILES_ERROR:
                return {
                    ...state,
                    deleting: false,
                    error: action.error
                };
        default:
            return state;
    }
}