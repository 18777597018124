import React, { useState, useRef } from 'react';
import { NavLink, useLocation } from "react-router-dom";
import { connect } from 'react-redux';

// Material UI Components
import { ButtonBase, Chip, Grid, IconButton, Paper, Radio, TextField, Typography, withStyles } from '@mui/material';

// Icons
import HomeIcon from '@mui/icons-material/Home';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import AddAlert from '@mui/icons-material/AddAlert';
import NotificationsActiveRounded from '@mui/icons-material/NotificationsActiveRounded';
import { faBarcode, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { faPuzzlePiece } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
  styled, List, ListItem, ListItemIcon, ListItemText, Button, FormControlLabel
} from '@mui/material';

import { Alert, Autocomplete } from '@mui/material';

import { allAssetForPDF, fetchForImpresion } from '../../redux/actions/assignedAssetActions';
import { pdf } from "@react-pdf/renderer";
import { fetchRazonReimpresionPlaca, getIdOptionSUSIList, getNameOptionSUSIList } from '../../redux/actions/SUSIListsActions';
import { fetchInventoryOfficers } from '../../redux/actions/SUSIListsActions'; //TLA
import { fetchDataUser }          from '../../redux/actions/dataUserActions'; //TLA 
import { DocumentService } from '../../services/generateDocumentService';

import '../../assets/css/material-grid.css';
import { Editor } from '@tinymce/tinymce-react';
import { sendMail } from '../../redux/actions/sendMailActions';
import Checkbox from '@mui/material/Checkbox';

import fiscaliapng from "../../assets/img/logo_inc.svg";
import fiscaliapng2 from "../../assets/img/logo_inc.svg";

import { REACT_APP_VERSION } from "../../config";
import { RESOLUCION_INVENTARIO } from "../../config";
import { useDispatch } from 'react-redux';
import { fetchTotalMissings } from '../../redux/actions/missingActions';
import { useEffect } from 'react';
import { fetchInventoryHistory } from '../../redux/actions/inventoryHistoryActions';
import { fetchEvaluations } from '../../redux/actions/evaluationsActions';
import { fetchScore } from '../../redux/actions/scoreActions';
import useStyles from '../../assets/jss/online-inventory/online-inventory';
import { SlideTransition } from '@mui/x-date-pickers'; 
import EditIcon from '@mui/icons-material/Edit';
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";

import Popover from "@mui/material/Popover";

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 4,
  width: 20,
  height: 20,
  backgroundColor: theme.palette.mode == 'dark' ? '#394b59' : 'white',
  border: '3px solid #9d9ea5',
  backgroundImage:
    theme.palette.mode == 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode == 'dark'
        ? 'rgba(57,75,89,.5)'
        : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#fff',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: '100%',
    height: '100%',
    backgroundImage: "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3C!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc.--%3E%3Cpath d='M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7l233.4-233.3c12.5-12.5 32.8-12.5 45.3 0z' fill='%236ce1f5'/%3E%3C/svg%3E\")",
    content: '""',
  },
  border: '3px solid #6ce1f5',
});

function BpCheckbox(props) {
  return (
    <Checkbox
      sx={{
        '&:hover': { bgcolor: 'transparent' },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ 'aria-label': 'Checkbox demo' }}
      {...props}
    />
  );
}

const MainListItems2 = (props) => {

  const perfil = 0;
  var userrole =  window.sessionStorage.getItem('userrole');
  console.log(userrole);
  if (userrole==null){userrole=0};
  console.log(userrole);
  const tipos  = Object.entries(props.rePrintReasons).length
  const [changeType, setChangeType] = useState(null)
  const [changeTypeError, setChangeTypeError] = useState(null);
  const [open, setOpen] = useState(false)
  const [email, setEmail] = React.useState(props.user.CORREO_REPORTAR_REIMPRESION_PLACA)
  const [ccmail, setCcEmail] = React.useState([])
  const [placa, setPlaca] = React.useState('')
  const [serie, setSerie] = React.useState('')
  const [asuntoMail, setAsuntoMail] = React.useState(`Solicitud Renovación de plaqueta adhesiva`)
  const [sended, setSended] = React.useState(false)
  const [emailAlert, setEmailAlert] = React.useState('')
  const [finded, setFinded] = React.useState(false)
  const [openPrint, setOpenPrint] = useState(false);
  const [enableFeaturesPrint, setEnableFeaturesPrint] = React.useState(false)
  const [assetFind, setAssetFind] = React.useState({
    placa: '',
    serie: '',
    error: false
  })

  const dispatch = useDispatch();

  //TLA const pathname = useLocation().pathname; 
  const pathname = "/"
  const editorRef = useRef(null);


//*************************************************/
const [anchorEl, setAnchorEl] = React.useState(null);

const handleClick = (event) => {
  setAnchorEl(event.currentTarget);
};

const handleClose = () => {
  setAnchorEl(null);
};
//**************************************** */


  let headChs = '';
  if (assetFind && assetFind.location != null) {
    headChs = getnameLocation(assetFind.location_id);
  }
  const classes = useStyles();
  const [headquarterChs, setHeadQuarterChs] = useState(headChs);
  const [sendMailState, setSendMailState] = useState(false);
  
  
  const getURLAsset = (assetFile) => {
    if (assetFile)
      return (
        "http://" +
        window.location.host +
        "/assets/img/iconsAssets/" +
        assetFile +
        ".svg"
      );
    else
      return (
        "http://" +
        window.location.host +
        "/assets/img/iconsAssets/no-image.svg"
      );
  };

  const toggleDialog = () => {
    const currentState = open;
    if (!open) {
      setAssetFind({
        placa: '',
        serie: '',
        error: false
      });
      setFinded(false);
      setSended(false);
      setEmailAlert('');
      setCcEmail([]);
      setChangeType(null)
      setChangeTypeError(null)
      setSendMailState(false)
    }
    setOpen(!currentState);

  };

  const toggleDialogPrint = () => {
    const currentState = openPrint;
    setOpenPrint(!currentState);
  };

  const getnameLocation = (idLocation) => {
    let dataname = props.locations.filter(t => t.id == idLocation);
    if (dataname.length > 0) {
      return dataname[0].fullname;
    }
  }

  const downloadPdf = async () => {
    let data = await allAssetForPDF(props.user.numero_documento, '', enableFeaturesPrint);
    if (data.length > 0) {
      var docInventario = await pdf(DocumentService.generateDocument(data.map(function (asset) {
        let novedad = asset.change_type_id == getIdOptionSUSIList('Faltante', props.changesTypes) ? ('FALTANTE (' + getNameOptionSUSIList(asset.missing_reason_id, props.missingReasons) + ')').toUpperCase() : asset.change_type_id == getIdOptionSUSIList('Verificado', props.changesTypes) ? 'VERIFICADO' : 'SIN NOVEDAD';
        let map = {
          serialBien: asset.serialBien,
          serieBien: asset.serieBien,
          placaBien: asset.placaBien,
          descripcionBien: asset.descripcionBien,
          estadoBien: props.stages.stages.find(stage => stage.code == asset.estadoBien).name,
          assetStatus: asset.estadoBienSUSI == getIdOptionSUSIList('Regular', props.assetsStatus),
          novedad: novedad,
          ubicacion: getnameLocation(asset.location_id),
          dependencia: asset.dependencia,
          lastCheck: asset.date_checklist,
          seccional: asset.nombreSeccional,
          funcionarioSolicitante: asset.funcionarioSolicitante,
          features: asset.features,
          responsable: props.user.fullName
        }
        return map;
      }), false, enableFeaturesPrint)).toBlob();
      var csvURL = window.URL.createObjectURL(docInventario);
      let tempLink = window.document.createElement('a');
      tempLink.href = csvURL;
      tempLink.setAttribute('download', 'FGN REPORTE DE INVENTARIO.pdf');
      // console.log('intenta descargar')
      tempLink.click();
    }
  }

  const downloadRes = () => {

      var pdfPath = process.env.PUBLIC_URL + RESOLUCION_INVENTARIO;
      let tempLink = window.document.createElement('a');
      tempLink.href = pdfPath;
      tempLink.download = '';
      //tempLink.setAttribute('download', 'RESOLUCION0-1166_2021.pdf');
      // console.log('intenta descargar')
      console.log(tempLink)
      tempLink.click();
  }




  const handleChangeType = (event) => {
    let optionData = props.rePrintReasons.find(reimpresion => reimpresion.id == event.target.value)
    setChangeType(optionData);
    setChangeTypeError(null);
  };


  useEffect(() => { 
// TLA useEffect que carga listas generales, aqui debe ir mis inscripciones 
// y mis evaluaciones, asi como roles y demas
//    dispatch(fetchTotalMissings(props.user.numero_documento))
    // dispatch(fetchInventoryHistory({ idUser: props.user.user_susi_id }))
    // dispatch(fetchEvaluations({ idUser: props.user.user_susi_id }))
    // dispatch(fetchScore({ idUser: props.user.user_susi_id }))
//    dispatch(fetchRazonReimpresionPlaca()) 
//    dispatch(fetchInventoryOfficers(props.user.numero_documento)) //TLA CAMBIO
    dispatch(fetchDataUser(props.user.numero_documento)) //TLA CAMBIO
  }, [])

  return (
    <nav>
      <div className="card-body-nav">
       <img src={fiscaliapng} className='fiscaliaImagen' alt="" />
 {/*         <img src={fiscaliapng2} className='fiscaliaImagen2' alt="" />
        {userrole==0 ? (
      <div>userrole cero {userrole}</div>
    ) : (
      <div>userrole diferente de cero {userrole}</div>
    )}
        {openPrint || open ? (
      <div>Entró por openPrint o open</div>
    ) : (
      <div>Entró por NavLink</div>
    )}         */}
      {userrole==0 && (
          openPrint || open ?
          <ListItem className='NavElement' button disableGutters>
            <ListItemIcon className='listItem'>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText className='listItem' primary="Inicio" />
          </ListItem> : pathname == '/tonotifys' ?
            <NavLink className='NavElement' to="/" 
            exact
            >
              <ListItem disableGutters className='NavElement' style={{ justifyContent: 'center' }}>
                <ListItemIcon className='modalActiveIcon'>
                  <HomeIcon style={{ color: '#3f66c4' }} />
                </ListItemIcon>
                <ListItemText className='modalAcitve' primary="Inicio" />
              </ListItem>
            </NavLink>
            :
            <NavLink className='NavElement' to="/" 
            exact
            >
              <ListItem disableGutters>
                <ListItemIcon className='listItem'>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText className='listItem' primary="Inicio" />
              </ListItem>
            </NavLink>
        )}

{userrole==0 && (
          openPrint || open ?
          <NavLink className='NavElement' to="/registrar">
          <ListItem button disableGutters>
            <ListItemIcon className='listItem'>
              <PlaylistAddCheckIcon />
            </ListItemIcon>
            <ListItemText className='listItem' primary="Registrar" />
          </ListItem>
        </NavLink>
          
           : <NavLink className='NavElement' to="/registrar">
              <ListItem button disableGutters>
                <ListItemIcon className='listItem'>
                  <PlaylistAddCheckIcon />
                </ListItemIcon>
                <ListItemText className='listItem' primary="Registrar" />
              </ListItem>
            </NavLink>
        )}


       {userrole==0 && (
          openPrint || open ?
          <NavLink className='NavElement' to="/followups">
          <ListItem button disableGutters>
            <ListItemIcon className='listItem'>
              <PlaylistAddCheckIcon />
            </ListItemIcon>
            <ListItemText className='listItem' primary="Pendientes" />
          </ListItem>
        </NavLink>
          
           : <NavLink className='NavElement' to="/followups">
              <ListItem button disableGutters>
                <ListItemIcon className='listItem'>
                  <PlaylistAddCheckIcon />
                </ListItemIcon>
                <ListItemText className='listItem' primary="Pendientes" />
              </ListItem>
            </NavLink>
        )}

{userrole==0 && (
          openPrint || open ?
          <NavLink className='NavElement' to="/firstonly">
          <ListItem button disableGutters>
            <ListItemIcon className='listItem'>
              <PlaylistAddCheckIcon />
            </ListItemIcon>
            <ListItemText className='listItem' primary="Unicamente cara A" />
          </ListItem>
        </NavLink>
          
           : <NavLink className='NavElement' to="/firstonly">
              <ListItem button disableGutters>
                <ListItemIcon className='listItem'>
                  <PlaylistAddCheckIcon />
                </ListItemIcon>
                <ListItemText className='listItem' primary="Unicamente cara A" />
              </ListItem>
            </NavLink>
        )}

{userrole==0 && (
          openPrint || open ?
          <NavLink className='NavElement' to="/uploadyear">
          <ListItem button disableGutters>
            <ListItemIcon className='listItem'>
              <PlaylistAddCheckIcon />
            </ListItemIcon>
            <ListItemText className='listItem' primary="Subir casos del año" />
          </ListItem>
        </NavLink>
          
           : <NavLink className='NavElement' to="/uploadyear">
              <ListItem button disableGutters>
                <ListItemIcon className='listItem'>
                  <PlaylistAddCheckIcon />
                </ListItemIcon>
                <ListItemText className='listItem' primary="Subir casos del año" />
              </ListItem>
            </NavLink>
        )}



        {/* {perfil === 0 && (
          openPrint || open ?
            <ListItem className='NavElement displayNavXs' button disableGutters>
              <ListItemIcon className='listItem'>
                <NotificationsActiveRounded />
              </ListItemIcon>
              <ListItemText className='listItem' primary="Sobrantes Reportados" />
            </ListItem>
            :
            <NavLink className='NavElement displayNavXs' to="/Surpluses" >
              <ListItem button disableGutters>
                <ListItemIcon className='listItem NavElement'>
                  <NotificationsActiveRounded />
                </ListItemIcon>
                <ListItemText className='listItem' primary="Sobrantes Reportados" />
              </ListItem>
            </NavLink>)} */}




          {/* <ListItem className='NavElement' style={{ position: 'absolute', top: '15%', width: '13rem', justifyContent: 'center' }} onClick={downloadRes} button disableGutters>
            <ListItemText className='listItem' primary="Registro Pacientes" />
          </ListItem>
          <ListItem className='NavElement' style={{ position: 'absolute', top: '20%', width: '13rem', justifyContent: 'center' }} onClick={downloadRes} button disableGutters>
            <ListItemText className='listItem' primary="Seguimientos" />
          </ListItem> */}
          
          <ListItem className='NavElement' style={{ position: 'absolute', top: '85%', width: '13rem', justifyContent: 'center' }} onClick={downloadRes} button disableGutters>
            <ListItemText className='listItem' primary="Descargue aquí el Manual del usuario" />
          </ListItem>

        <div className='listItem' style={{ position: 'absolute', top: '95%', width: '13rem', justifyContent: 'center' }}>
          <p>
            Versión {REACT_APP_VERSION}
          </p>
        </div>
      </div>


      {/* {renderDialogMail()}
      {renderDialogPrint()} */}
    </nav>
  )
};

const mapStateToProps = state => {
  // console.log("estado en lisitems");
  // console.log(state)
  return {
    historyList: state.inventoryHistory,
    evaluations: state.evaluations,
    score      : state.score,
    params: state.getActualParams,
    withoutFillingOuts: state.withoutFillingOut,
    user: state.user,
    assignedAssets: state.assignedAssets,
    locations: state.locations.locations,
    assetsStatus: state.SUSILists.assetsStatus,
    changesTypes: state.SUSILists.changesTypes,
    missingReasons: state.SUSILists.missingReasons,
    rePrintReasons: state.SUSILists.reprintReasons,
    inventoryOfficers: state.SUSILists.inventoryOfficers,
    dataUser: state.dataUserReducer.dataDirectory,
    sectionalsUser: state.sectionals,
    stages: state.stages,
    SyncSIAF: state.syncSIAF.isFetching,
    Whitout: state.missingReducer
  }
}

export default connect(mapStateToProps)(MainListItems2)