import { FETCH_WITHOUT_FILLINGOUT_REQUEST, FETCH_WITHOUT_FILLINGOUT_SUCCESS, FETCH_WITHOUT_FILLINGOUT_ERROR } from "../types";

import axios from 'axios';

export const fetchWithoutFillingOuts = (params) => (dispatch) => {    
    dispatch({type: FETCH_WITHOUT_FILLINGOUT_REQUEST});
    //console.log("Esto es lo que invoca: ", process.env.REACT_APP_BACKEND_URL+'/assignedAssets?'+params);   
    axios.get(process.env.REACT_APP_BACKEND_URL+'/inventoryChecklists/withoutFillingOut?identification=' + params)
        .then( res => {
             //console.log("ESTA ES LA RESPUESTA ", res.data);
            dispatch({
                type: FETCH_WITHOUT_FILLINGOUT_SUCCESS,
                payload: {
                    fullWithout: res.data.full,
                    withoutFillingOuts: res.data.data
                }
            })
        })
        .catch(error => {
            dispatch({
                type: FETCH_WITHOUT_FILLINGOUT_ERROR,
                error: error.toString()
            })
        })        
}