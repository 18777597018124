import { FETCH_SYNC_SIAF_ERROR, FETCH_SYNC_SIAF_SUCCESS, FETCH_SYNC_SIAF_REQUEST, FETCH_BLOCK_ERROR, FETCH_BLOCK_SUCCESS, FETCH_BLOCK_REQUEST} from '../types/index';

const initialState = {
    messageSync: '',
    isFetching: false,
    error: null
}

export default function syncSIAF (state = initialState, action) {
    switch (action.type) {
        case FETCH_SYNC_SIAF_REQUEST:                        
            return {
                ...state,
                isFetching: false
            };
        case FETCH_SYNC_SIAF_SUCCESS:
            return {
                ...state,
                isFetching: true,
                messageSync: action.payload.message,
            };
        case FETCH_SYNC_SIAF_ERROR:                        
            return {
                ...state,
                isFetching: false,
                error: action.error
            };

        case FETCH_BLOCK_REQUEST:
            return {
                ...state,
                isFetching: false
            };
        case FETCH_BLOCK_SUCCESS:
            return {
                ...state,
                sync: action.payload.sync,
                syncRepeated: action.payload.syncRepeated,
                isFetching: true,
                syncFail: action.payload.syncFail
            };
        case FETCH_BLOCK_ERROR:
            return {
                sync: action.payload.sync,
                message: action.payload.message,
                syncRepeated: action.payload.syncRepeated,
                isFetching: false,
                error: action.error
            };
        default:
            return state;
    }
}