import { FETCH_ASSETS_SURPLUS_COMPLETE_REQUEST, FETCH_ASSETS_SURPLUS_COMPLETE_SUCCESS, FETCH_ASSETS_SURPLUS_COMPLETE_ERROR } from '../types'
const initialState = {
    count: 0,
    page: 1,
    hasMore: true,
    isFetching: false,
    data: [],
    error: null,
    mount: true
}

export default function listSurplus(state = initialState, action) {
    switch (action.type) {
        case FETCH_ASSETS_SURPLUS_COMPLETE_REQUEST:
            return {
                ...state,
                page: 1,
                hasMore: true,
                isFetching: true
            };
        case FETCH_ASSETS_SURPLUS_COMPLETE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                hasMore: action.payload.data.length === 20,
                page: action.payload.page,
                data: action.payload.page > 1 ? state.data.concat(action.payload.data) : action.payload.data,
                mount: action.payload.mount
            };
        case FETCH_ASSETS_SURPLUS_COMPLETE_ERROR:
            return {
                ...state,
                isFetching: false,
                asset: null,
                error: action.payload.error,
                mount: action.payload.mount
            };
        default:
            return state;
    }
}