import { FETCH_SCORE_ERROR, FETCH_SCORE_SUCCESS, FETCH_SCORE_REQUEST } from '../types/index';


const initialState = {
    autoInfo: [],
    score:[],
    isFetching: false,
    error: null
}

export default function score(state = initialState, action) {
    switch (action.type) {
        case FETCH_SCORE_REQUEST:                        
            return {
                ...state,
                isFetching: true
            };
        case FETCH_SCORE_SUCCESS:                        
            return {
                ...state,
                isFetching: false,
                score: action.payload.score,
                autoInfo: action.payload.autoInfo
            };
        case FETCH_SCORE_ERROR:                        
            return {
                ...state,
                isFetching: false,
                error: action.error
            };
        default:
            return state;
    }
}