import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {IconButton, Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
const UploadFile = () => {
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
  const [history, setHistory] = useState([]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!file) {
      setMessage('Por favor, selecciona un archivo');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post('http://localhost:3001/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setMessage(response.data);
      fetchHistory(); // Actualizar el historial después de subir el archivo
    } catch (error) {
      setMessage('Error al subir el archivo');
    }
  };

  const fetchHistory = async () => {
    try {
      const response = await axios.get('http://localhost:3001/upload/history');
      setHistory(response.data);
    } catch (error) {
      console.error('Error al obtener el historial de archivos:', error);
    }
  };

  useEffect(() => {
    fetchHistory();
  }, []);

  return (
    <div style={{ padding: '20px', maxWidth: '600px', margin: 'auto' }}>
      <Typography variant="h4" align="center" color="black" gutterBottom>
        Importar archivo de casos procesados 
      </Typography>
      <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <input
        accept="*"
        style={{ display: 'none' }}
        id="file-upload"
        type="file"
        onChange={handleFileChange}
      />
      <label htmlFor="file-upload">
        <IconButton color="primary" aria-label="upload file" component="span">
          <UploadIcon fontSize="large" />
        </IconButton>
      </label>
        

        <Button 
        type="submit" 
        variant="contained" 
        sx={{ bgcolor: 'var(--backBlue)', '&:hover': { bgcolor: '#345b76' } }} 
        fullWidth
        >
          Validar e Importar
        </Button>
      </form>
      {message && (
        <Typography variant="body1" align="center" color="primary" style={{ marginTop: '20px' }}>
          {message}
        </Typography>
      )}

      <Typography variant="h5" align="center" color="black" gutterBottom style={{ marginTop: '60px' }}>
        Historial de archivos cargados
      </Typography>
      <TableContainer component={Paper} style={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center"><strong>Año</strong></TableCell>
              <TableCell align="center"><strong>Total de registros</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {history.map((record) => (
              <TableRow key={record.año}>
                <TableCell align="center">{record.año}</TableCell>
                <TableCell align="center">{record.total}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default UploadFile;
