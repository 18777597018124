import { FETCH_EVALUATIONS_ERROR, FETCH_EVALUATIONS_SUCCESS, FETCH_EVALUATIONS_REQUEST } from '../types/index';


const initialState = {
    autoInfo: [],
    evaluations:[],
    isFetching: false,
    error: null
}

export default function evaluations(state = initialState, action) {
    switch (action.type) {
        case FETCH_EVALUATIONS_REQUEST:                        
            return {
                ...state,
                isFetching: true
            };
        case FETCH_EVALUATIONS_SUCCESS:                        
            return {
                ...state,
                isFetching: false,
                evaluations: action.payload.evaluations,
                autoInfo: action.payload.autoInfo
            };
        case FETCH_EVALUATIONS_ERROR:                        
            return {
                ...state,
                isFetching: false,
                error: action.error
            };
        default:
            return state;
    }
}