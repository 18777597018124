import {REACT_APP_BACKEND_URL} from "../../config";

import axios from 'axios';

export const createRegister = async (params) => {
    console.log("crear registro");
    console.log(params);
    return await axios.post(REACT_APP_BACKEND_URL + 'createRegister.php', params).then((resp) => {
        console.log(resp.data)
        if (!resp.data.ok) {
            console.error('ha surgido el siguiente error al crear la cuenta: ');
        }
        return resp.data
    }).catch(err => {
        console.error('Error al crear la cuenta: ', err);
        return err
    })
}
