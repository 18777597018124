import {FETCH_ASSETS_STAGES_USER_ERROR,FETCH_ASSETS_STAGES_USER_REQUEST, FETCH_ASSETS_STAGES_USER_SUCCESS} from '../types'
const initialState = {
    stages:[],
    isFetching: false,
    error: null
}

export default function assetStages(state = initialState, action) {
    switch (action.type) {
        case FETCH_ASSETS_STAGES_USER_REQUEST:                        
            return {
                ...state,
                isFetching: true
            };
        case FETCH_ASSETS_STAGES_USER_SUCCESS:                        
            return {
                ...state,
                isFetching: false,
                stages: action.payload.stages
            };
        case FETCH_ASSETS_STAGES_USER_ERROR:                        
            return {
                ...state,
                isFetching: false,
                error: action.error
            };
        default:
            return state;
    }
}