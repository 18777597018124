import { FETCH_ASSETS_CATEGORIES_REQUEST, FETCH_ASSETS_CATEGORIES_SUCCESS, FETCH_ASSETS_CATEGORIES_ERROR } from "../types";

const initialState = {
    categories:[],
    isFetching: false,
    error: null
}

export default function assetsCategories (state = initialState, action){    
    switch (action.type) {
        case FETCH_ASSETS_CATEGORIES_REQUEST:                        
            return {
                ...state,
                isFetching: true
            };
        case FETCH_ASSETS_CATEGORIES_SUCCESS:                        
            return {
                ...state,
                isFetching: false,
                categories: action.payload.categories
            };
        case FETCH_ASSETS_CATEGORIES_ERROR:                        
            return {
                ...state,
                isFetching: false,
                error: action.error
            };
        default:
            return state;
    }
}