import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { DataGrid } from '@mui/x-data-grid';
import { IconButton, Box, Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

const Followup = () => {
    const [inconsistencies, setInconsistencies] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]); // Almacena los IDs seleccionados
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchInconsistencies = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/patients/followup`);
                const dataWithId = response.data.info.map((item, index) => ({
                    ...item,
                    id: item.hc || index,  // Asigna 'hc' como id o usa el índice si 'hc' es nulo
                }));
                setInconsistencies(dataWithId);
            } catch (error) {
                console.error("Error al obtener inconsistencias:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchInconsistencies();
    }, []);

    // Función para descargar los datos seleccionados en Excel
    const downloadExcel = () => {
        if (selectedIds.length === 0) {
            alert("No hay filas seleccionadas para descargar.");
            return;
        }
        const selectedData = inconsistencies.filter((row) => selectedIds.includes(row.id));
        const worksheet = XLSX.utils.json_to_sheet(selectedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Inconsistencias");
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, 'inconsistencias_seleccionadas.xlsx');
    };

    const columns = [
        { field: 'hc', headerName: 'Historia', width: 150 },
        { field: 'description', headerName: 'Descripción', width: 300 },
    ];

    const localeTextSpanish = {
        noRowsLabel: 'Sin filas',
        noResultsOverlayLabel: 'Sin resultados',
        toolbarDensity: 'Densidad',
        toolbarDensityLabel: 'Densidad',
        toolbarDensityCompact: 'Compacta',
        toolbarDensityStandard: 'Estándar',
        toolbarDensityComfortable: 'Cómoda',
        toolbarColumns: 'Columnas',
        toolbarColumnsLabel: 'Seleccionar columnas',
        toolbarExport: 'Exportar',
        toolbarExportLabel: 'Exportar',
        checkboxSelectionHeaderName: 'Seleccionar',
        columnMenuSortAsc: 'Ordenar ascendente',
        columnMenuSortDesc: 'Ordenar descendente',
        columnMenuFilter: 'Filtrar',
        columnMenuHideColumn: 'Ocultar',
        columnMenuShowColumns: 'Mostrar columnas',
        footerRowSelected: (count) => `${count} fila(s) seleccionada(s)`,
    };

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h4" component="h1" color="black" gutterBottom>
                Listado de Pendientes
            </Typography>
            <IconButton color="primary" onClick={downloadExcel} sx={{ mb: 2 }} title="Descargar Excel">
                <DownloadIcon />
            </IconButton>
            {loading ? (
                <Typography>Cargando...</Typography>
            ) : (
                <Box sx={{ height: 600, width: '100%' }}>
                    <DataGrid
                        rows={inconsistencies}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5, 10, 20]}
                        checkboxSelection
                        disableSelectionOnClick
                        onRowSelectionModelChange={(ids) => setSelectedIds(ids)} // Actualiza los IDs seleccionados
                        localeText={localeTextSpanish}
                        sx={{
                            '& .MuiDataGrid-columnHeaders': {
                                backgroundColor: '#f5f5f5',
                                color: 'black',
                                fontWeight: 'bold',
                            },
                        }}
                    />
                </Box>
            )}
        </Box>
    );
};

export default Followup;
