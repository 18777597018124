import { FETCH_REPORT_MISSINGS_ERROR, FETCH_REPORT_MISSINGS_REQUEST, FETCH_REPORT_MISSINGS_SUCCESS, UPDATE_REPORT_MISSINGS_ERROR, UPDATE_REPORT_MISSINGS_REQUEST, UPDATE_REPORT_MISSINGS_SUCCESS } from "../types";

const initialState = {
    novedades: 0,
    page: 1,
    hasMore: false,
    reportMissings: [],
    documents: [],
    isFetching: false,
    updating: false,
    mount: false,
    error: null
}

export default function reportMissingReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_REPORT_MISSINGS_REQUEST:
            return {
                ...state,
                page: 1,
                hasMore: true,
                isFetching: true,
                mount: action.payload.mount
            };
        case FETCH_REPORT_MISSINGS_SUCCESS:
            return {
                ...state,
                page: action.payload.page,
                novedades: action.payload.novedades,
                documents: action.payload.documents,
                isFetching: false,
                hasMore: action.payload.missings.length === 20,
                reportMissings: action.payload.page > 1 ? state.reportMissings.concat(action.payload.missings) : action.payload.missings,
                mount: false
            };
        case FETCH_REPORT_MISSINGS_ERROR:
            return {
                ...state,
                page: 1,
                hasMore: false,
                isFetching: false,
                error: action.error
            };

        case UPDATE_REPORT_MISSINGS_REQUEST:
            return { ...state, updating: true };
        case UPDATE_REPORT_MISSINGS_SUCCESS:
            return { ...state, updating: false };
        case UPDATE_REPORT_MISSINGS_ERROR:
            return {
                ...state,
                error: action.payload.errorstate, updating: false
            };
        default:
            return state;
    }
}