import React from 'react';
import { Provider } from 'react-redux';
import axios from 'axios';
import './App.css';
import store from './redux/store';

import { BrowserRouter as Router, Routes, Route, BrowserRouter } from 'react-router-dom'; // Asegúrate de importar Router y Route 111
//import Routers from './components/Router/Routers'; 
import DashboardPage from './pages/Main/DashboardPage';
import Main from  './pages/Main/Page';
import Login from './pages/Main/Login';



// intercept all request and add authorization token header
axios.interceptors.request.use(
  (req) => {
     // Add configurations here
     let token =  window.sessionStorage.getItem('_userToken');
     //  add authorization header only request to backend
     if(token !== null && req.url.includes(process.env.REACT_APP_BACKEND_URL)) {
      req.headers = {
        ...req.headers,
        Authorization: 'Bearer ' + token
      };
     }
     return req;
  },
  (err) => {
     return Promise.reject(err);
  }
);

function App() {
  return (
    <Provider store={ store }>     

      {/* <DashboardPage/> */}
      <Main/>

    
    </Provider>
  );
}

export default App;
