import { FETCH_SECTIONALS_USER_REQUEST, FETCH_SECTIONALS_USER_SUCCESS, FETCH_SECTIONALS_USER_ERROR } from "../types";

const initialState = {
    sectionals: [],
    isFetching: false,
    error: null
}

export default function sectionalsUser(state = initialState, action) {
    switch (action.type) {
        case FETCH_SECTIONALS_USER_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case FETCH_SECTIONALS_USER_SUCCESS:
            return {
                ...state,
                isFetching: false,
                sectionals: action.payload.sectionals
            };
        case FETCH_SECTIONALS_USER_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
            };
        default:
            return state;
    }
}