import { FETCH_NOTIFICATIONS_ERROR, FETCH_NOTIFICATIONS_SUCCESS, FETCH_NOTIFICATIONS_REQUEST, CREATE_NOTIFICATIONS_APROVED_REQUEST, CREATE_NOTIFICATIONS_APROVED_SUCCESS, CREATE_NOTIFICATIONS_APROVED_ERROR } from '../types/index';

const initialState = {
    count: 0,
    page: 1,
    hasMore: true,
    isFetching: false,
    notifys: [],
    error: null
}

export default function callNotifys(state = initialState, action) {
    switch (action.type) {
        case FETCH_NOTIFICATIONS_REQUEST:
            return {
                ...state,
                page: 1,
                hasMore: true,
                isFetching: true,
                mount: action.payload.mount
            };
        case FETCH_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                hasMore: action.payload.data.length === 20,
                page: action.payload.page,
                notifys: action.payload.page > 1 ? state.notifys.concat(action.payload.data) : action.payload.data,
                mount: false
            };
        case FETCH_NOTIFICATIONS_ERROR:
            return {
                ...state,
                isFetching: false,
                hasMore: false,
                error: action.error
            };
        case CREATE_NOTIFICATIONS_APROVED_REQUEST:
            return { ...state };
        case CREATE_NOTIFICATIONS_APROVED_SUCCESS:
            return { ...state };
        case CREATE_NOTIFICATIONS_APROVED_ERROR:
            return {
                ...state,
                error: action.error
            };
        default:
            return state;
    }
}