import { FETCH_INVENTORY_HISTORY_ERROR, FETCH_INVENTORY_HISTORY_SUCCESS, FETCH_INVENTORY_HISTORY_REQUEST } from '../types/index';


const initialState = {
    autoInfo: [],
    history:[],
    isFetching: false,
    error: null
}

export default function inventoryHistory(state = initialState, action) {
    switch (action.type) {
        case FETCH_INVENTORY_HISTORY_REQUEST:                        
            return {
                ...state,
                isFetching: true
            };
        case FETCH_INVENTORY_HISTORY_SUCCESS:                        
            return {
                ...state,
                isFetching: false,
                history: action.payload.history,
                autoInfo: action.payload.autoInfo
            };
        case FETCH_INVENTORY_HISTORY_ERROR:                        
            return {
                ...state,
                isFetching: false,
                error: action.error
            };
        default:
            return state;
    }
}