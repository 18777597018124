import { Card, CardActions, CardContent, Typography, Button, CircularProgress } from "@mui/material";
import { Navigate } from 'react-router-dom';
import React from 'react'
// React Router
import { BrowserRouter as Router } from "react-router-dom";

import { connect } from "react-redux"
import { fetchLogin } from "../../redux/actions/loginSessionAction";
import { Alert } from "@mui/material";
import logoA from '../../assets/img/logo_inc.svg';
import tituloA from '../../assets/img/titulo_mi_inventario_linea.png';
import {REACT_APP_VERSION} from "../../config";
import Register from './Register';
import Main from './Page';
import { useEffect } from "react";


const Login = (props) => {
    let message = '';
    if (props.token.error) {
        message = 'El usuario no es valido';
    }
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [loading,  setLoading]  = React.useState(false);
    const [register, setRegister] = React.useState(false);
    const [authenticate, setAuthenticate] = React.useState(false);

// Función que devuelve una promesa que se resuelve después de un tiempo
const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const handleAuth = async () => {
    setLoading(true);

    // Esperamos 500 ms antes de continuar
    await delay(500);

    try {
        const response = await props.dispatch(fetchLogin({ username, password }));

        console.log("*******************************");
        console.log(response);

        if (response) {
            console.log("AUTORIZADO");
            setAuthenticate(true);
        } else {
            console.log("NO AUTORIZADO");
            setAuthenticate(false);
        }

        alert(response);
    } catch (error) {
        console.error("Error during authentication:", error);
        setAuthenticate(false);
    } finally {
        setLoading(false);
    }
};
        
    const handleRegister = () => {
        setRegister(!register);
    };


    useEffect(() => {
        setLoading(props.token.isFetching);
    }, [props.token])
    

    return (
        <div className="containerLogin">

       {/* <Router> */}
       {authenticate && <Main/>}
       {register && <Register />}
       {/* Otros componentes o rutas aquí */}
       {/* </Router> */}


            
            <Card className='rootLogin'>
                <CardContent className="cardTtuloLogin">
                    {/* <Typography className="tituloLogin">MI <br /></Typography>
                    <Typography variant="h5" >INVENTARIO</Typography>
                    <Typography variant="h5" >EN LÍNEA</Typography> */}
                </CardContent>
                {/* <img src={tituloA} className='ImgTitulo' alt="" /> */}
                <CardContent style={{ padding: '0.3rem', margin: '0.1rem' }}>
                <div className="centered-container">
                <Typography variant="h4" className="label-principal !important">REGISTRO NACIONAL DE CÁNCER - INC</Typography>
                <Typography variant="h2" className="label-secundario !important">REGINST</Typography>
                </div>
                </CardContent>

                <CardContent style={{ type:'email', padding: '0.3rem', margin: '0.1rem' }}>
                    <input className="field" value={username} onChange={(e) => setUsername(e.target.value.replace(' ', ''))} placeholder={'Correo_electronico@user.com'} required>
                    </input>
                </CardContent>
                <CardContent style={{ padding: '0.3rem', margin: '0.1rem' }}>
                    <input className="field" value={password} type="password" onChange={(e) => setPassword(e.target.value.replace(' ', ''))} placeholder={'Contraseña'} security="true" required>
                    </input>
                </CardContent>
                {message.length > 0 ? <Alert severity="error">{message}</Alert> : <></>}
                <CardActions style={{ justifyContent: 'center' , marginTop: '1.5rem', marginBottom: '-0.9rem' }}>
                    {loading === false ? <Button  className='btnLogin' onClick={handleAuth}>Ingresar</Button> : <CircularProgress style={{ margin: 'auto' }} />}
                </CardActions>
                <CardActions style={{ justifyContent: 'center'  }}>
                    {loading === false ? <Button className="btnLogin" onClick={handleRegister}>Registrate</Button> : <CircularProgress style={{ margin: 'auto' }} />}
                </CardActions>

                <img src={logoA} className='ImgLogoLogin' alt="" />
                <CardContent style={{ padding: '0.2rem', margin: '0.1rem' }}>
                    <Typography paragraph style={{color: "black", fontWeight: "bold", fontSize:18}}>
                        Versión {REACT_APP_VERSION}
                    </Typography>
                </CardContent>
            </Card>
        </div>
    )
}
function mapStateToProps(state) {
    console.log(state)
    return {
        token: state.getUserToken,
        register: state.register
    };
}
export default connect(mapStateToProps, null, null, { forwardRef: true })(Login);
