import {FETCH_COUNTRIES_REQUEST, FETCH_COUNTRIES_SUCCESS, FETCH_COUNTRIES_ERROR,
    FETCH_BASE_REQUEST, FETCH_BASE_SUCCESS, FETCH_BASE_ERROR,
    FETCH_CITY_REQUEST, FETCH_CITY_SUCCESS, FETCH_CITY_ERROR,
    FETCH_CIUO_REQUEST, FETCH_CIUO_SUCCESS, FETCH_CIUO_ERROR,
    FETCH_CIUU_REQUEST, FETCH_CIUU_SUCCESS, FETCH_CIUU_ERROR,
    FETCH_CLASSIFICATION_REQUEST, FETCH_CLASSIFICATION_SUCCESS, FETCH_CLASSIFICATION_ERROR,
    FETCH_DECEASE_REQUEST, FETCH_DECEASE_SUCCESS, FETCH_DECEASE_ERROR,
    FETCH_DIFERENCE_REQUEST, FETCH_DIFERENCE_SUCCESS, FETCH_DIFERENCE_ERROR,
FETCH_EPSARS_REQUEST, FETCH_EPSARS_SUCCESS, FETCH_EPSARS_ERROR,
FETCH_ERRORHISTO_REQUEST, FETCH_ERRORHISTO_SUCCESS, FETCH_ERRORHISTO_ERROR, 
FETCH_LATERALITY_REQUEST, FETCH_LATERALITY_SUCCESS,
FETCH_LATERALITY_ERROR, FETCH_NOTREATMENT_REQUEST, FETCH_NOTREATMENT_SUCCESS, FETCH_NOTREATMENT_ERROR,
FETCH_MAINTOP_REQUEST, FETCH_MAINTOP_SUCCESS, FETCH_MAINTOP_ERROR, 
FETCH_PERIOD_REQUEST, FETCH_PERIOD_SUCCESS, FETCH_PERIOD_ERROR, 
FETCH_SEX_REQUEST, FETCH_SEX_SUCCESS, FETCH_SEX_ERROR, 
FETCH_HISTOLOGICAL_REQUEST, FETCH_HISTOLOGICAL_SUCCESS,
FETCH_HISTOLOGICAL_ERROR,   FETCH_TOPOGRAPHIC_REQUEST, FETCH_TOPOGRAPHIC_SUCCESS, 
FETCH_TOPOGRAPHIC_ERROR,    FETCH_IPS_REQUEST, 
FETCH_IPS_SUCCESS, FETCH_IPS_ERROR, 
FETCH_VALIDATION_REQUEST, FETCH_VALIDATION_SUCCESS, 
FETCH_VALIDATION_ERROR, FETCH_CIE10_REQUEST, FETCH_CIE10_SUCCESS, FETCH_CIE10_ERROR,
FETCH_TYPE_REQUEST, FETCH_TYPE_SUCCESS, FETCH_TYPE_ERROR,
FETCH_TYPE_IDENTIFICATION_REQUEST, FETCH_TYPE_IDENTIFICATION_SUCCESS, FETCH_TYPE_IDENTIFICATION_ERROR,
FETCH_TYPEEPS_REQUEST, FETCH_TYPEEPS_SUCCESS, FETCH_TYPEEPS_ERROR,
FETCH_TYPETEL_REQUEST, FETCH_TYPETEL_SUCCESS, FETCH_TYPETEL_ERROR,
FETCH_TYPEUSER_REQUEST, FETCH_TYPEUSER_SUCCESS, FETCH_TYPEUSER_ERROR,
FETCH_ZONE_REQUEST, FETCH_ZONE_SUCCESS, FETCH_ZONE_ERROR,
FETCH_OTHER_REQUEST, FETCH_OTHER_SUCCESS, FETCH_OTHER_ERROR,
FETCH_ASSETS_STATUS_REQUEST, FETCH_ASSETS_STATUS_SUCCESS, FETCH_ASSETS_STATUS_ERROR,
FETCH_CHANGES_TYPES_REQUEST, FETCH_CHANGES_TYPES_SUCCESS, FETCH_CHANGES_TYPES_ERROR,
FETCH_MISSINGS_REASONS_REQUEST, FETCH_MISSINGS_REASONS_SUCCESS, FETCH_MISSINGS_REASONS_ERROR
} from "../types";
import {REACT_APP_BACKEND_URL} from "../../config";


import axios from 'axios';

export const getptionSUSIList = (value, SUSIlist) => {
if (SUSIlist.length > 0) {
    let options = SUSIlist.filter(optionSUSI => optionSUSI.id == value);
    if (options[0]) {
        return options[0];
    }
    return "";
} else {
    return "";
}
}

export const getIdOptionSUSIList = (value, SUSIlist) => {
if (SUSIlist.length > 0) {
    let options = SUSIlist.filter(optionSUSI => optionSUSI.name == value);
    if (options[0]) {
        return options[0].id;
    }
    return "";
} else {
    return "";
}
}

export const getNameOptionSUSIList = (id, SUSIlist) => {

if (SUSIlist.length > 0) {
    let options = SUSIlist.filter(optionSUSI => optionSUSI.id == id);
    if (options[0]) {
        return options[0].name;
    }
    return "";
} else {
    return "";
}
}


export const fetchErrorHisto = (params) => (dispatch) => {
axios.get(process.env.REACT_APP_BACKEND_URL + '/lists?nombreLista=errorhisto')
    .then(res => {
        //console.warn("Estos son los ESTADOS DEL BIEN: ", res.data);
        dispatch({
            type: FETCH_ERRORHISTO_SUCCESS,
            payload: {
                errorhisto: res.data
            }
        })
    })
    .catch(error => {
        console.log("Hubo un error al recuperar la lista de Estados de Bienes: ", error);
        dispatch({
            type: FETCH_ERRORHISTO_ERROR,
            error: error.toString()
        })
    })
}


export const fetchCountries = () => (dispatch) => {
const headers = {
    'Content-Type': 'application/json; charset=utf-8'
};
axios.get(process.env.REACT_APP_BACKEND_URL + '/lists?nombreLista=paises', { headers })
    .then(res => {
        dispatch({
            type: FETCH_COUNTRIES_SUCCESS,
            payload: {
                countries: res.data
            }
        })
    })
    .catch(error => {
        console.log("Hubo un error al recuperar la lista de categorias: ", error);
        dispatch({
            type: FETCH_COUNTRIES_ERROR,
            error: error.toString()
        })
    })
}

export const fetchTypeIdentification = () => (dispatch) => {
    const headers = {
        'Content-Type': 'application/json; charset=utf-8'
    };
    axios.get(process.env.REACT_APP_BACKEND_URL + '/lists?nombreLista=tidentif', { headers })
        .then(res => {
            dispatch({
                type: FETCH_TYPE_IDENTIFICATION_SUCCESS,
                payload: {
                    typeidentif: res.data
                }
            })
        })
        .catch(error => {
            console.log("Hubo un error al recuperar la lista de tipos identificacion: ", error);
            dispatch({
                type: FETCH_TYPE_IDENTIFICATION_ERROR,
                error: error.toString()
            })
        })
    }
    
export const fetchCiuu = () => (dispatch) => {
const headers = {
    'Content-Type': 'application/json; charset=utf-8'
};
axios.get(process.env.REACT_APP_BACKEND_URL + '/lists?nombreLista=ciuu', { headers })
    .then(res => {
        dispatch({
            type: FETCH_CIUU_SUCCESS,
            payload: {
                ciuu: res.data
            }
        })
    })
    .catch(error => {
        console.log("Hubo un error al recuperar la lista de tipos de evaluación: ", error);
        dispatch({
            type: FETCH_CIUU_ERROR,
            error: error.toString()
        })
    })
}

export const fetchCiuo = () => (dispatch) => {
const headers = {
    'Content-Type': 'application/json; charset=utf-8'
};
axios.get(process.env.REACT_APP_BACKEND_URL + '/lists?nombreLista=ciuo', { headers })
    .then(res => {
        dispatch({
            type: FETCH_CIUO_SUCCESS,
            payload: {
                ciuo: res.data
            }
        })
    })
    .catch(error => {
        console.log("Hubo un error al recuperar la lista de entidades: ", error);
        dispatch({
            type: FETCH_CIUO_ERROR,
            error: error.toString()
        })
    })
}

export const fetchClassification = () => (dispatch) => {
const headers = {
    'Content-Type': 'application/json; charset=utf-8'
};
axios.get(process.env.REACT_APP_BACKEND_URL + '/lists?nombreLista=clasificacion', { headers })
    .then(res => {
        dispatch({
            type: FETCH_CLASSIFICATION_SUCCESS,
            payload: {
                classification: res.data
            }
        })
    })
    .catch(error => {
        console.log("Hubo un error al recuperar la lista de especialidades: ", error);
        dispatch({
            type: FETCH_CLASSIFICATION_ERROR,
            error: error.toString()
        })
    })
}

export const fetchDecease = () => (dispatch) => {
const headers = {
    'Content-Type': 'application/json; charset=utf-8'
};
axios.get(process.env.REACT_APP_BACKEND_URL + '/lists?nombreLista=defuncion', { headers })
    .then(res => {
        dispatch({
            type: FETCH_DECEASE_SUCCESS,
            payload: {
                decease: res.data
            }
        })
    })
    .catch(error => {
        console.log("Hubo un error al recuperar la lista de áreas temáticas: ", error);
        dispatch({
            type: FETCH_DECEASE_ERROR,
            error: error.toString()
        })
    })
}

export const fetchDiference = () => (dispatch) => {
const headers = {
    'Content-Type': 'application/json; charset=utf-8'
};
axios.get(process.env.REACT_APP_BACKEND_URL + '/getlist.php?nombreLista=diferenciacion', { headers })
    .then(res => {
        dispatch({
            type: FETCH_DIFERENCE_SUCCESS,
            payload: {
                diference: res.data
            }
        })
    })
    .catch(error => {
        console.log("Hubo un error al recuperar la lista de estados ", error);
        dispatch({
            type: FETCH_DIFERENCE_ERROR,
            error: error.toString()
        })
    })
}

export const fetchBase = () => (dispatch) => {
const headers = {
    'Content-Type': 'application/json; charset=utf-8'
};
axios.get(process.env.REACT_APP_BACKEND_URL + '/lists?nombreLista=base', { headers })
    .then(res => {
        dispatch({
            type: FETCH_BASE_SUCCESS,
            payload: {
                base: res.data
            }
        })
    })
    .catch(error => {
        console.log("Hubo un error al recuperar la lista de tipo de presentación: ", error);
        dispatch({
            type: FETCH_BASE_ERROR,
            error: error.toString()
        })
    })
}

export const fetchCity = () => (dispatch) => {
const headers = {
    'Content-Type': 'application/json; charset=utf-8'
};
axios.get(process.env.REACT_APP_BACKEND_URL + '/lists?nombreLista=divipola', { headers })
    .then(res => {
        console.log("TLA CITY ",res.data);
        dispatch({
            type: FETCH_CITY_SUCCESS,
            payload: {
                city: res.data
            }
        })
    })
    .catch(error => {
        console.log("Hubo un error al recuperar la lista de ciudades: ", error);
        dispatch({
            type: FETCH_CITY_ERROR,
            error: error.toString()
        })
    })
}

export const fetchLaterality = (params) => (dispatch) => {
axios.get(process.env.REACT_APP_BACKEND_URL + '/lists?nombreLista=lateralidad')
    .then(res => {
        dispatch({
            type: FETCH_LATERALITY_SUCCESS,
            payload: {
                laterality: res.data
            }
        })
    })
    .catch(error => {
        console.log("Hubo un error al recuperar la lista SUSI de tipo de novedad: ", error);
        dispatch({
            type: FETCH_LATERALITY_ERROR,
            error: error.toString()
        })
    })
}

export const fetchNoTreatment = (params) => (dispatch) => {
//console.log("ESTA ES LA LISTA DE MISSING: ", process.env.REACT_APP_SUSI_LISTS_URL+'/list?nombreLista=CORE_TIP_NOV_INVENTARIO');       

axios.get(process.env.REACT_APP_BACKEND_URL + '/lists?nombreLista=notrat')
    .then(res => {
  //      console.log(res.data)
        let data = (res.data) ? res.data : [];
        dispatch({
            type: FETCH_NOTREATMENT_SUCCESS,
            payload: {
                notreatment: data
            }
        })
    })
    .catch(error => {
        console.log("Hubo un error al recuperar la lista de SUSI de Motivos de faltante", error);
        dispatch({
            type: FETCH_NOTREATMENT_ERROR,
            error: error.toString()
        })
    })
}

export const fetchMaintop = (params) => (dispatch) => {
//console.log("ESTA ES LA LISTA DE MISSING: ", process.env.REACT_APP_SUSI_LISTS_URL+'/list/INVE_425');
//dispatch(FETCH_MAINTOP_REQUEST)
axios.get(process.env.REACT_APP_BACKEND_URL + '/lists?nombreLista=maintop')
    .then(res => {
        let data = (res.data) ? res.data : [];
        dispatch({
            type: FETCH_MAINTOP_SUCCESS,
            payload: {
                maintop: data
            }
        })
    })
    .catch(error => {
        console.log("Hubo un error al recuperar la lista de SUSI de Motivos de faltante", error);
        dispatch({
            type: FETCH_MAINTOP_ERROR,
            error: error.toString()
        })
    })
}

export const fetchPeriod = (params) => (dispatch) => {
//console.log("ESTA ES LA LISTA DE MISSING: ", process.env.REACT_APP_SUSI_LISTS_URL+'/list/INVE_425');
dispatch({ type:FETCH_PERIOD_REQUEST})
axios.get(process.env.REACT_APP_BACKEND_URL + '/lists?nombreLista=periodo').then(res => {
        let data = (res.data) ? res.data : [];
        dispatch({
            type: FETCH_PERIOD_SUCCESS,
            payload: {
                period: data
            }
        })
    })
    .catch(error => {
        console.log("Hubo un error al recuperar la lista de SUSI de Motivos de faltante", error);
        dispatch({
            type: FETCH_PERIOD_ERROR,
            error: error.toString()
        })
    })
}

export const fetchSex = (params) => (dispatch) => {
    //console.log("ESTA ES LA LISTA DE MISSING: ", process.env.REACT_APP_SUSI_LISTS_URL+'/list/INVE_425');
    dispatch({ type:FETCH_SEX_REQUEST})
    axios.get(process.env.REACT_APP_BACKEND_URL + '/lists?nombreLista=sexo').then(res => {
            let data = (res.data) ? res.data : [];
            dispatch({
                type: FETCH_SEX_SUCCESS,
                payload: {
                    sex: data
                }
            })
        })
        .catch(error => {
            console.log("Hubo un error al recuperar la lista de SUSI de Motivos de faltante", error);
            dispatch({
                type: FETCH_SEX_ERROR,
                error: error.toString()
            })
        })
    }
    

export const fetchHistological = (params) => (dispatch) => {
//console.log("ESTA ES LA LISTA DE MISSING: ", process.env.REACT_APP_SUSI_LISTS_URL+'/list/INVE_425');
dispatch({ type: FETCH_HISTOLOGICAL_REQUEST})
axios.get(process.env.REACT_APP_BACKEND_URL + '/lists?nombreLista=nhisto').then(res => {
        let data = (res.data) ? res.data : [];
        dispatch({
            type: FETCH_HISTOLOGICAL_SUCCESS,
            payload: {
                histological: data
            }
        })
    })
    .catch(error => {
        console.log("Hubo un error al recuperar la lista de SUSI de Motivos de faltante", error);
        dispatch({
            type: FETCH_HISTOLOGICAL_ERROR,
            error: error.toString()
        })
    })
}

export const fetchTopographic = (params) => (dispatch) => {
//console.log("ESTA ES LA LISTA DE MISSING: ", process.env.REACT_APP_SUSI_LISTS_URL+'/list/INVE_425');
dispatch({ type: FETCH_TOPOGRAPHIC_REQUEST})
axios.get(process.env.REACT_APP_BACKEND_URL + '/lists?nombreLista=ntop')
    .then(res => {
        let data = (res.data) ? res.data : [];
        dispatch({
            type: FETCH_TOPOGRAPHIC_SUCCESS,
            payload: {
                topographic: data
            }
        })
    })
    .catch(error => {
        console.log("Hubo un error al recuperar la lista de SUSI de Motivos de faltante", error);
        dispatch({
            type: FETCH_TOPOGRAPHIC_ERROR,
            error: error.toString()
        })
    })
}

export const fetchValidation = (params) => (dispatch) => {
//console.log("ESTA ES LA LISTA DE MISSING: ", process.env.REACT_APP_SUSI_LISTS_URL+'/list/INVE_425');
dispatch({ type: FETCH_VALIDATION_REQUEST})
axios.get(process.env.REACT_APP_BACKEND_URL + '/lists?nombreLista=nvalidacion')
    .then(res => {
        let data = (res.data) ? res.data : [];
        dispatch({
            type: FETCH_VALIDATION_SUCCESS,
            payload: {
                validation: data
            }
        })
    })
    .catch(error => {
        console.log("Hubo un error al recuperar la lista de SUSI de razon de reimpresion de placa: ", error);
        dispatch({
            type: FETCH_VALIDATION_ERROR,
            error: error.toString()
        })
    })
}

export const fetchIps = (params) => (dispatch) => {
//console.log("ESTA ES LA LISTA DE MISSING: ", process.env.REACT_APP_SUSI_LISTS_URL+'/list/INVE_425');
dispatch({ type: FETCH_IPS_REQUEST})
axios.get(process.env.REACT_APP_BACKEND_URL + '/lists?nombreLista=ips')
    .then(res => {
        let data = (res.data) ? res.data : [];
        dispatch({
            type: FETCH_IPS_SUCCESS,
            payload: {
                ips: data
            }
        })
    })
    .catch(error => {
        console.log("Hubo un error al recuperar la lista de SUSI de razon de reimpresion de placa: ", error);
        dispatch({
            type: FETCH_IPS_ERROR,
            error: error.toString()
        })
    })
}

export const fetchCie10 = (params) => (dispatch) => {
//console.log("ESTA ES LA LISTA DE MISSING: ", process.env.REACT_APP_SUSI_LISTS_URL+'/list/INVE_425');
dispatch({ type: FETCH_CIE10_REQUEST})
axios.get(process.env.REACT_APP_BACKEND_URL + '/lists?nombreLista=ncie10')
    .then(res => {
        let data = (res.data) ? res.data : [];
        dispatch({
            type: FETCH_CIE10_SUCCESS,
            payload: {
                cie10: data
            }
        })
    })
    .catch(error => {
        console.log("Hubo un error al recuperar la lista de SUSI de razon de reimpresion de placa: ", error);
        dispatch({
            type: FETCH_CIE10_ERROR,
            error: error.toString()
        })
    })
}

export const fetchEpsars = (params) => (dispatch) => {
//console.log("ESTA ES LA LISTA DE MISSING: ", process.env.REACT_APP_SUSI_LISTS_URL+'/list/INVE_425');
dispatch({ type: FETCH_EPSARS_REQUEST})
axios.get(process.env.REACT_APP_BACKEND_URL + '/lists?nombreLista=epsars')
    .then(res => {
        let data = (res.data) ? res.data : [];
        dispatch({
            type: FETCH_EPSARS_SUCCESS,
            payload: {
                eps: data
            }
        })
    })
    .catch(error => {
        console.log("Hubo un error al recuperar la lista de SUSI de razon de reimpresion de placa: ", error);
        dispatch({
            type: FETCH_EPSARS_ERROR,
            error: error.toString()
        })
    })
}

export const fetchOther = (params) => (dispatch) => {
//console.log("ESTA ES LA LISTA DE MISSING: ", process.env.REACT_APP_SUSI_LISTS_URL+'/list/INVE_425');
dispatch({ type: FETCH_OTHER_REQUEST})
axios.get(process.env.REACT_APP_BACKEND_URL + '/lists?nombreLista=otro')
    .then(res => {
        let data = (res.data) ? res.data : [];
        dispatch({
            type: FETCH_OTHER_SUCCESS,
            payload: {
                other: data
            }
        })
    })
    .catch(error => {
        console.log("Hubo un error al recuperar la lista de SUSI de razon de reimpresion de placa: ", error);
        dispatch({
            type: FETCH_OTHER_ERROR,
            error: error.toString()
        })
    })
}

export const fetchType = (params) => (dispatch) => {
//console.log("ESTA ES LA LISTA DE MISSING: ", process.env.REACT_APP_SUSI_LISTS_URL+'/list/INVE_425');
dispatch({ type: FETCH_TYPE_REQUEST})
axios.get(process.env.REACT_APP_BACKEND_URL + '/lists?nombreLista=tipo')
    .then(res => {
        let data = (res.data) ? res.data : [];
        dispatch({
            type: FETCH_TYPE_SUCCESS,
            payload: {
                type: data
            }
        })
    })
    .catch(error => {
        console.log("Hubo un error al recuperar la lista de tipo ", error);
        dispatch({
            type: FETCH_TYPE_ERROR,
            error: error.toString()
        })
    })
}

export const fetchTypeeps = (params) => (dispatch) => {
//console.log("ESTA ES LA LISTA DE MISSING: ", process.env.REACT_APP_SUSI_LISTS_URL+'/list/INVE_425');
dispatch({ type: FETCH_TYPEEPS_REQUEST})
axios.get(process.env.REACT_APP_BACKEND_URL + '/lists?nombreLista=tipoeps')
    .then(res => {
        let data = (res.data) ? res.data : [];
        dispatch({
            type: FETCH_TYPEEPS_SUCCESS,
            payload: {
                typeeps: data
            }
        })
    })
    .catch(error => {
        console.log("Hubo un error al recuperar la lista de tipo de eps: ", error);
        dispatch({
            type: FETCH_TYPEEPS_ERROR,
            error: error.toString()
        })
    })
}

export const fetchTypetel = (params) => (dispatch) => {
//console.log("ESTA ES LA LISTA DE MISSING: ", process.env.REACT_APP_SUSI_LISTS_URL+'/list/INVE_425');
dispatch({ type: FETCH_TYPETEL_REQUEST})
axios.get(process.env.REACT_APP_BACKEND_URL + '/lists?nombreLista=ttel')
    .then(res => {
        let data = (res.data) ? res.data : [];
        dispatch({
            type: FETCH_TYPETEL_SUCCESS,
            payload: {
                typetel: data
            }
        })
    })
    .catch(error => {
        console.log("Hubo un error al recuperar la lista de tipo de telefono ", error);
        dispatch({
            type: FETCH_TYPETEL_ERROR,
            error: error.toString()
        })
    })
}

export const fetchTypeuser = (params) => (dispatch) => {
//console.log("ESTA ES LA LISTA DE MISSING: ", process.env.REACT_APP_SUSI_LISTS_URL+'/list/INVE_425');
dispatch({ type: FETCH_TYPEUSER_REQUEST})
axios.get(process.env.REACT_APP_BACKEND_URL + '/lists?nombreLista=tusua')
    .then(res => {
        let data = (res.data) ? res.data : [];
        dispatch({
            type: FETCH_TYPEUSER_SUCCESS,
            payload: {
                typeuser: data
            }
        })
    })
    .catch(error => {
        console.log("Hubo un error al recuperar la lista de tipo usuario: ", error);
        dispatch({
            type: FETCH_TYPEUSER_ERROR,
            error: error.toString()
        })
    })
}

export const fetchZone = (params) => (dispatch) => {
//console.log("ESTA ES LA LISTA DE MISSING: ", process.env.REACT_APP_SUSI_LISTS_URL+'/list/INVE_425');
dispatch({ type: FETCH_ZONE_REQUEST})
axios.get(process.env.REACT_APP_BACKEND_URL + '/lists?nombreLista=zona')
    .then(res => {
        let data = (res.data) ? res.data : [];
        dispatch({
            type: FETCH_ZONE_SUCCESS,
            payload: {
                zone: data
            }
        })
    })
    .catch(error => {
        console.log("Hubo un error al recuperar la lista de zona: ", error);
        dispatch({
            type: FETCH_ZONE_ERROR,
            error: error.toString()
        })
    })
}


export const fetchAssetsStatus = (params) => (dispatch) => {
//console.log("ESTA ES LA LISTA DE MISSING: ", process.env.REACT_APP_SUSI_LISTS_URL+'/list/INVE_425');
dispatch({ type: FETCH_ASSETS_STATUS_REQUEST})
axios.get(process.env.REACT_APP_BACKEND_URL + '/lists?nombreLista=tipoeps')
    .then(res => {
        let data = (res.data) ? res.data : [];
        dispatch({
            type: FETCH_ASSETS_STATUS_SUCCESS,
            payload: {
                assetsStatus: data
            }
        })
    })
    .catch(error => {
        console.log("Hubo un error al recuperar la lista de SUSI de razon de reimpresion de placa: ", error);
        dispatch({
            type: FETCH_ASSETS_STATUS_ERROR,
            error: error.toString()
        })
    })
}

export const fetchChangesTypes = (params) => (dispatch) => {
//console.log("ESTA ES LA LISTA DE MISSING: ", process.env.REACT_APP_SUSI_LISTS_URL+'/list/INVE_425');
dispatch({ type: FETCH_CHANGES_TYPES_REQUEST})
axios.get(process.env.REACT_APP_BACKEND_URL + '/lists?nombreLista=tipoeps')
    .then(res => {
        let data = (res.data) ? res.data : [];
        dispatch({
            type: FETCH_CHANGES_TYPES_SUCCESS,
            payload: {
                typeeps: data
            }
        })
    })
    .catch(error => {
        console.log("Hubo un error al recuperar la lista de SUSI de razon de reimpresion de placa: ", error);
        dispatch({
            type: FETCH_CHANGES_TYPES_ERROR,
            error: error.toString()
        })
    })
}

export const fetchMissingReasons = (params) => (dispatch) => {
//console.log("ESTA ES LA LISTA DE MISSING: ", process.env.REACT_APP_SUSI_LISTS_URL+'/list/INVE_425');
dispatch({ type: FETCH_MISSINGS_REASONS_REQUEST})
axios.get(process.env.REACT_APP_BACKEND_URL + '/lists?nombreLista=tipoeps')
    .then(res => {
        let data = (res.data) ? res.data : [];
        dispatch({
            type: FETCH_MISSINGS_REASONS_SUCCESS,
            payload: {
                typeeps: data
            }
        })
    })
    .catch(error => {
        console.log("Hubo un error al recuperar la lista de SUSI de razon de reimpresion de placa: ", error);
        dispatch({
            type: FETCH_MISSINGS_REASONS_ERROR,
            error: error.toString()
        })
    })
}