import { FETCH_MEMBER_REQUEST, FETCH_MEMBER_SUCCESS, FETCH_MEMBER_ERROR } from "../types";

const initialState = {
    members:[],
    isFetching: false,
    error: null 
}

export default function members (state = initialState, action){    
    switch (action.type) {
        case FETCH_MEMBER_REQUEST:                        
            return {
                ...state,
                isFetching: true
            };
        case FETCH_MEMBER_SUCCESS:                   
            return {
                ...state,
                isFetching: false,
                members: action.payload.members
            };
        case FETCH_MEMBER_ERROR:                        
            return {
                ...state,
                isFetching: false,
                error: action.payload.error
            };
        default:
            return state;
    }
}