import { configureStore } from '@reduxjs/toolkit';
import { thunk } from 'redux-thunk'; // Importa thunk como un export nombrado
import reducer from './reducers';

const store = configureStore({
    reducer, // Puedes pasar múltiples reducers si es necesario
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(thunk), // Agrega thunk al middleware
    devTools: process.env.NODE_ENV !== 'production', // Activa DevTools solo en desarrollo
});

export default store;
