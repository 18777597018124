import { FETCH_LOCATIONS_REQUEST, FETCH_LOCATIONS_SUCCESS, FETCH_LOCATIONS_ERROR } from "../types";

import axios from 'axios';

export const fetchLocations = (params={}) => (dispatch) => { 
    //params.text = "";        
    //if(params.texto){   
        dispatch({type: FETCH_LOCATIONS_REQUEST});
        //console.log("Esta es la URL del servidor de Locations: ", process.env.REACT_APP_BACKEND_URL+'/locations');   
        axios.get(process.env.REACT_APP_BACKEND_URL+'/locations')
            .then( res => {
                // console.log("ESTA ES LA RESPUESTA DE LOCATIONS", res.data);
                dispatch({
                    type: FETCH_LOCATIONS_SUCCESS,
                    payload: {
                        locations: res.data,
                        
                    }
                })
            })
            .catch(error => {
                console.log('Error en fetchLocations: ',error.toString());
                dispatch({
                    type: FETCH_LOCATIONS_ERROR,
                    payload: {
                        error: error.toString()
                    }
                })
            })
    //}        
}