import { FETCH_ASSETS_SURPLUS_ERROR, FETCH_ASSETS_SURPLUS_SUCCESS, FETCH_ASSETS_SURPLUS_REQUEST, FETCH_ASSETS_SURPLUS_COMPLETE_ERROR, FETCH_ASSETS_SURPLUS_COMPLETE_SUCCESS, FETCH_ASSETS_SURPLUS_COMPLETE_REQUEST, GET_SURPLUS_SEARCH_PARAMS } from '../types';

import axios from 'axios';
var bienesSobrantes = require('../../data/bienesSobrantes.json');

export const fetchAssetSurplus = (params) => (dispatch) => {
    dispatch({ type: FETCH_ASSETS_SURPLUS_REQUEST })
    let paramsStrings = "";
    for (const param in params) {
        if (paramsStrings.length > 0) {
            paramsStrings += "&";
        }
        paramsStrings += param + "=" + params[param];
    }
    axios.get(process.env.REACT_APP_BACKEND_URL + '/assignedAssets/onesurplus?' + paramsStrings).then(resp => {

        dispatch({
            type: FETCH_ASSETS_SURPLUS_SUCCESS,
            payload: {
                asset: resp.data
            }
        })
    }).catch(e => {
        dispatch({
            type: FETCH_ASSETS_SURPLUS_ERROR,
            payload: {
                error: e.toString()
            }
        })
    })
}

export const fetchCompleteAssetSurplus = (params, mount = false) => (dispatch) => {
    if (mount)
     {params = 1
      dispatch({ type: FETCH_ASSETS_SURPLUS_COMPLETE_REQUEST })
     }
    axios.get(process.env.REACT_APP_BACKEND_URL + '/assignedAssets/surplus/complete?page=' + params).then(resp => {
        dispatch({
            type: FETCH_ASSETS_SURPLUS_COMPLETE_SUCCESS,
            payload: {
                data: resp.data.data,
                count: resp.data.count,
                page: params,
                mount: mount
            }
        })
    }).catch(e => {
        console.error('Error al traer sobrantes: ', e);
        dispatch({
            type: FETCH_ASSETS_SURPLUS_COMPLETE_ERROR,
            payload: {
                error: e.toString(),
                mount: mount
            }
        })
    })
}

export const getSurpluses = async (params) => {
    return await axios.get(process.env.REACT_APP_BACKEND_URL + '/assignedAssets/all/surpluses').then((resp) => {
        return resp.data
    }).catch(err => {
        console.error('Error al obtener sobrantes: ', err);
        return err
    })
}

export const getSurplus = async (params) => {
    let paramsStrings = "";
    for (const param in params) {
        if (paramsStrings.length > 0) {
            paramsStrings += "&";
        }
        paramsStrings += param + "=" + params[param];
    }

    return await axios.get(process.env.REACT_APP_BACKEND_URL + '/assignedAssets/surplus?' + paramsStrings).then((resp) => {
        if (resp.data.codigo != undefined && +resp.data.codigo >= 400) {
            console.error('Error por parte de siaf al obtener el sobrante: ', resp.data);
        } else if (resp.data.error != undefined) {
            console.error('Error al obtener sobrante: ', resp.data.error);
            return { error: resp.data.error }
        } else {
            return resp.data
        }
    }).catch(err => {
        console.error('Error al obtener sobrante: ', err);
        return err
    })
}

export const getDummySurpluses = (params) => {
    let bien = bienesSobrantes.find(i => i.serialBien == params.serial || i.placaBien == params.code)

    if (bien) {
        return bien
    } else {
        return null
    }
}

export const saveSurpluses = async (documento, params) => {

    return await axios.post(process.env.REACT_APP_BACKEND_URL + '/assignedAssets/register/surpluses?identification=' + documento, params).then((resp) => {
        return resp.data
    }).catch(err => {
        console.error('Error al reportar sobrante: ', err);
        return err
    })
}

export const registerSurplus = async (documento, params) => {
    return await axios.post(process.env.REACT_APP_BACKEND_URL + '/assignedAssets/surplusRegister?identification=' + documento, params).then((resp) => {
        if (!resp.data.ok) {
            console.error('ha surgido el siguiente error al registrar el sobrante: ',resp.data.message);
        }
        return resp.data
    }).catch(err => {
        console.error('Error al registrar sobrante: ', err);
        return err
    })
}

export const updateSurplus = (documento, params) => {
    return axios.put(process.env.REACT_APP_BACKEND_URL + '/assignedAssets/surplus/updateSurplus?identification=' + documento, params).then((resp) => {
        return resp.data
    }).catch(err => {
        console.error('error al actualizar sobrante: ', err);
        return err
    })
}

export const paramsSearchSurplus = (params) => (dispatch) => {
    dispatch({ type: GET_SURPLUS_SEARCH_PARAMS, payload: params })
}