import { FETCH_WITHOUT_FILLINGOUT_REQUEST, FETCH_WITHOUT_FILLINGOUT_SUCCESS, FETCH_WITHOUT_FILLINGOUT_ERROR } from "../types";

const initialState = {
    fullWithout: [],
    withoutFillingOuts: [],
    isFetching: false,
    error: null
}

export default function withoutFillingOut(state = initialState, action) {
    switch (action.type) {
        case FETCH_WITHOUT_FILLINGOUT_REQUEST:
            return {
                ...state,
                withoutFillingOuts: [],
                isFetching: true
            };
        case FETCH_WITHOUT_FILLINGOUT_SUCCESS:
            return {
                ...state,
                isFetching: false,
                fullWithout: action.payload.fullWithout,
                withoutFillingOuts: action.payload.withoutFillingOuts
            };
        case FETCH_WITHOUT_FILLINGOUT_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
            };
        default:
            return state;
    }
}