import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useStyles from '../../assets/jss/online-inventory/online-inventory';
import { createInscription, getInscription, closeInscription } from "../../redux/actions/inscriptionAction";
import { fetchInventoryHistory } from '../../redux/actions/inventoryHistoryActions';
import {AlertTitle } from "@mui/lab";
import InfiniteScroll from "react-infinite-scroll-component";
import { file_size_MB, susi_lists } from '../../config';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import {
  Paper,Typography, ButtonBase, ButtonGroup, Tabs, Tab,
  TextField,  Switch, Box, Radio, FormControl, FormLabel, RadioGroup,
  FormControlLabel, CircularProgress, List, ListItem, ListItemAvatar, ListItemText, Avatar, Tooltip, Chip, TextareaAutosize, styled
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import { Autocomplete} from '@mui/material';

import {IconButton, Button, Table, TableHead, TableRow, TableCell, TableBody,
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions
} from '@mui/material';
import { Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';
import CommentIcon from '@mui/icons-material/Comment';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import AddCircleOutlineTwoToneIcon from "@mui/icons-material/AddCircleOutlineTwoTone";

import { updateAssignedAsset } from '../../redux/actions/assignedAssetActions';
import { getIdOptionSUSIList, getNameOptionSUSIList } from '../../redux/actions/SUSIListsActions';
import { updateUbication } from '../../redux/actions/ubicationsActions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarcode, faEllipsisV } from '@fortawesome/free-solid-svg-icons';

import { Alert } from '@mui/lab';

import { fetchLocations } from '../../redux/actions/locationsActions';
import { DocumentService } from '../../services/generateDocumentService';
import { pdf } from '@react-pdf/renderer';
import { Editor } from '@tinymce/tinymce-react';
import { sendMail, sendEmailSoporte } from '../../redux/actions/sendMailActions';
import Checkbox from '@mui/material/Checkbox';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {DOCUMENTS_FOLDER} from "../../config";
import {CompetitionParticipant} from './CompetitionParticipant';

import { jsPDF } from 'jspdf';
import { isEmpty, isNull } from 'lodash';
import { green } from '@mui/material/colors';
// import { LocalizationProvider } from "@mui/x-date-pickers";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { InputAdornment } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone'; 
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';




const options = ['option1','option2','option3', 'option4'];
const userrole =  window.sessionStorage.getItem('userrole');
const BlueRadio = withStyles({
  root: {
    color: '#9d9ea5',
    '&$checked': {
    color: '#6ce1f5'
    }
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const MaterialUISwitch = styled(Switch)(() => ({
  margin: '0 0.5rem',
  '& .MuiSwitch-switchBase': {
    color: '#3f66c4',
    '&.Mui-checked': {
      color: '#ef4682'
    },
  },
}));

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 4,
  width: 20,
  height: 20,
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : 'white',
  border: '2px solid #9d9ea5',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(57,75,89,.5)'
        : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#fff',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: '100%',
    height: '100%',
    backgroundImage: "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3C!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc.--%3E%3Cpath d='M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7l233.4-233.3c12.5-12.5 32.8-12.5 45.3 0z' fill='%236ce1f5'/%3E%3C/svg%3E\")",
    content: '""',
  },
  border: '3px solid #6ce1f5',
});


const AssignedAsset = (props) => {
const classes = useStyles();
const navigate = useNavigate();

const initialState = {
  patientSap         : '',
  PatientHistory     : '',
  PatientFirstname   : '',
  PatientSecondname  : '',
  PatientName        : '',
  PatientRegben      : '',
  PatientRegmal      : '',
  PatientDatebirth   : '',
  PatientAge         : '',
  PatientSex         : '',
  PatientTypedoc     : '',
  PatientDocument    : '',
  PatientDateexp     : '',
  PatientTypeuser    : '',
  PatientYearswork   : '',
  PatientActeconomic : '',
  PatientOcupation   : '',
  PatientCountrybirth: '',
  PatientDeptobirth  : '',
  PatientMunbirth    : '',
  PatientCountryees  : '',
  PatientDeptores    : '',
  PatientMunres      : '',
  PatientAddress     : '',
  PatientNeight      : '',
  PatientTel1        : '',
  PatientTel2        : '',
  PatientTypetel     : '',
  PatientZone        : '',
  PatientCodeIns     : '',


  competitionId: '',
  presentationId: '1',
  presentationName: 'Poster-ePoster',
  categoryId: '',
  categoryName: '',
  thematicAreaId: '',
  memberIdcard: '',
  memberFullName: '',
  articleTitle: '',
  articleShortTitle: '',
  articleKeyword1: '',
  articleKeyword2: '',
  articleKeyword3: '',
  articleKeyword4: '',
  articleKeyword5: '',
  articlePatients: [
    {
        patientSap         : '',
        PatientHistory     : '',
        PatientFirstname   : '',
        PatientSecondname  : '',
        PatientName        : '',
        PatientRegben      : '',
        PatientRegmal      : '',
        PatientDatebirth   : '',
        PatientAge         : '',
        PatientSex         : '',
        PatientTypedoc     : '',
        PatientDocument    : '',
        PatientDateexp     : '',
        PatientTypeuser    : '',
        PatientYearswork   : '',
        PatientActeconomic : '',
        PatientOcupation   : '',
        PatientCountrybirth: '',
        PatientDeptobirth  : '',
        PatientMunbirth    : '',
        PatientCountryees  : '',
        PatientDeptores    : '',
        PatientMunres      : '',
        PatientAddress     : '',
        PatientNeight      : '',
        PatientTel1        : '',
        PatientTel2        : '',
        PatientTypetel     : '',
        PatientZone        : '',
        PatientCodeIns     : '',
        filiations: [
            {
                companyId: '',
                companyName: '',
                companyService:'',
                companyDepartament: '',
                companyCity: '',
            }
        ]
    }
  ],
  articleObjetive: '',
  articleMethod:   '',
  articleResult:   '',
  articleConclusion: '',
  articleSubmitted: '',
  articleSubmittedWhere: '',
  inscriptionState: 0,
  tipos: '',
  otro: false,
  contenido: '',
  isMobiliario: false,
  tipoBienOtro: '',
  descripcionBien: '',
  descripcionDependencia: '',
  contacto: '',
  location: '',
  seccional: '',
  dependencia: '',
  tipoBienCode: '',
  messageAlert: '',
  openAlert: false,
  Send: false,
  validations: {
    descriptionLength: true,
    contactLength: true,
    serieLength: true,
    descripcionDependenciaLength: true,
    memberValid: 'Debe digitar el número del documento del Miembro ACHO para poder continuar con inscripción',
  },
  paramsFetchSectionals: {
    // user_susi_id: props.user.user_susi_id,
    user_susi_id: '1',
  },
};


  let li = [];
  let assetStatusBD = "";
  // if (props.data.competition_state === 1) {
  //   assetStatusBD = true;
  // } else {
  //   assetStatusBD = false;
  // }
  const initialFormPatient = {
    patientSap         : '',
    PatientHistoria    : '',
    PatientFirstname   : '',
    PatientSecondname  : '',
    PatientName        : '',
    PatientRegben      : '',
    PatientRegmal      : '',
    PatientDatebirth   : '',
    PatientSex         : '',
    PatientTypedoc     : '',
    PatientDocument    : '',
    PatientDateexp     : '',
    PatientTypeuser    : '',
    PatientYearswork   : '',
    PatientActeconomic : '',
    PatientOcupation   : '',
    PatientCountrybirth: '',
    PatientDeptobirth  : '',
    PatientMunbirth    : '',
    PatientCountryees  : '',
    PatientDeptores    : '',
    PatientMunres      : '',
    PatientAddress     : '',
    PatientNeight      : '',
    PatientTel1        : '',
    PatientTel2        : '',
    PatientTypetel     : '',
    PatientZone        : '',
    PatientCodeIns     : ''
};
  const [formPatient, setFormPatient] = useState(initialFormPatient);



  const [culminationDate, setculminationDate] = useState(new Date());
  const [assetStatus, setAssetStatus] = useState(assetStatusBD);
  // const [assignedAssetID, setAssignedAssetID] = useState(this.props.data.info.doc);//props.data.info.doc
  const [showDialogStatusBad, setDialogStatusBad] = useState(false);
  const [showDialogShare, setShowDialogShare] = useState(false);
  const [alertObservations, setAlertObservations] = useState(true);
  
  const [changeType, setChangeType] = useState('');
  const [missingReason, setMissingReason] = useState('');
  const [errorFormMissing, setErrorFormMissing] = useState('');
  const [observations, setObservations] = useState('');
  const [savingMissingReason, setSavingMissingReason] = useState(false);
  const [recordsLocation, setRecordsLocation] = useState([]);
  // const [listHeadQuarters, setListHeadQuarters] = useState(false);
  // const loading = listHeadQuarters && props.locations.length === 0;
  const [headquarterChs, setHeadQuarterChs] = useState('');
  const [locationChs, setLocationChs] = useState('');
  const [switchOption2, setSwitchOption2] = useState(false);
  const [switchOption3, setSwitchOption3] = useState(false);
  const [switchOption4, setSwitchOption4] = useState(false);  
  const [showDialogTypeMissing, setShowDialogTypeMissing] = useState(false)
  const [textOption, setTextOption] = useState('')
  const [showDialogtheft, setShowDialogtheft] = useState(false)
  const [inscriptionPendiente, setInscriptionPendiente] = useState([]);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [openPrint, setOpenPrint] = useState(false);
  const [terminar, setTerminar] = useState(false);
  //const [isOut, setIsOut] = useState(props.isOut);
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('')
  const [ccmail, setCcEmail] = useState([])
  const [razonReimpresion, setRazonReimpresion] = useState(null)
  const [asuntoMail, setAsuntoMail] = useState(`Solicitud Renovación de plaqueta adhesiva `)
  const [sended, setSended] = useState(false)
  const [enableFeaturesPrint, setEnableFeaturesPrint] = useState(false)
  const [emailAlert, setEmailAlert] = useState('')
  const [totalPalabras, setTotalPalabras] = useState(0)
  const [pdfFile, setPdfFile] = useState(null)
  const [pdfFile1, setPdfFile1] = useState(null)
  const editorRef = useRef(null);

  const [saveHeadquarterChs, setSaveHeadquarterChs] = useState('');
  const [saveLocationChs, setSaveLocationChs] = useState('');
  //const [alertRuleNotify, setAlertRuleNotify] = useState(li.length);
  const [displayAlert, setDisplayAlert] = useState('none');

  const configDate = { year: 'numeric', month: 'long', day: 'numeric' };

  const [state, setState] = useState(initialState);
  const [temporalFiliations,setTemporalFiliations]=useState([]);
  const [isFiliationValid, setIsFiliationValid] = useState(true);
  const [temporalPatients,setTemporalPatients]=useState([]);
  const [isPatientValid, setIsPatientValid] = useState(true);
  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFile3, setSelectedFile3] = useState(null);
  const [selectedFile4, setSelectedFile4] = useState(null);
  const [thematic, setThematic] = useState(null);
  const [checkedPatients, setCheckedPatients] = useState({});
  const [checkedReceiveMail, setCheckedReceiveMail] = useState({});
  const [checkedSpeaker, setCheckedSpeaker] = useState({});
  const [pdfUrl, setPdfUrl] = useState(null);
  const [pdfUrl1, setPdfUrl1] = useState(null);
  const [pdfUrl2, setPdfUrl2] = useState(null);
  const [openPendient, setOpenPendient] = useState(null);
  const [openConvoca, setOpenConvoca] = useState(false);
  const [titleAlert, setTitleAlert] = useState('');
  const [messageAlert, setMessageAlert] = useState('');
  const [tabIndex0, setTabIndex0] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedValue, setSelectedValue] = useState("opcion1"); 

  const handleChangePatient = (event) => {
    const { id, value } = event.target;
    setFormPatient(prevState => ({
      ...prevState,
      [id]: value
    }));
  };

  const handleTabChange0 = (event, newIndex) => {
    setTabIndex0(newIndex);
  };

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  const colorTexto = totalPalabras > 350 ? 'red' : 'success';

//TLA nuevo
const yesnot = [
  { id: '1', label: 'Sí' },
  { id: '2', label: 'No' },
];

const handleBuscar = () => {
  console.log("EN BUSCAR");
  console.log(state);
  
  const miembroExiste = props.propsDad.members.members.find(p => p.memberIdcard == state.memberIdcard && p.memberState == 1);
  
  console.log(miembroExiste);
  
  if (miembroExiste) {
    setState(prevState => ({
      ...prevState,
      memberFullName: miembroExiste.memberName + ' ' + miembroExiste.memberLastname,
      validations: {
        ...prevState.validations,
        memberValid: ''
      }
    }), () => {
      console.log("Estado actualizado:", state);
    });
    console.log("existe");
  } else {
    setState(prevState => ({
      ...prevState,
      validations: {
        ...prevState.validations,
        memberValid: 'No se encontró un miembro ACHO activo'
      },
      memberFullName: '',
      memberIdcard: null
    }), () => {
      console.log("Estado actualizado:", state);
      console.log("Nuevo valor de memberValid:", state.validations.memberValid);
    });
    setTitleAlert('VALIDACIÓN MIEMBRO ACHO');
    setMessageAlert('No se encontró un miembro ACHO activo');
    handleChangeStatus();

  }
  
  console.log("ESTADO ACTUALIZADO");
  console.log(state);


};
const handleChange = (e) => {
  const { name, value } = e.target;
  if (name === 'articleTitle' && value.replace(/\s/g, '').length > 200) {
    return; // Si la longitud excede el límite, no actualizamos el estado
  }
const wordCounts = {
  articleObjetive: state.articleObjetive ? state.articleObjetive.trim().split(/\s+/).filter(Boolean).length : 0,
  articleMethod: state.articleMethod ? state.articleMethod.trim().split(/\s+/).filter(Boolean).length : 0,
  articleResult: state.articleResult ? state.articleResult.trim().split(/\s+/).filter(Boolean).length : 0,
  articleConclusion: state.articleConclusion ? state.articleConclusion.trim().split(/\s+/).filter(Boolean).length : 0,
};
  const totalWords = Object.values(wordCounts).reduce((total, count) => total + count, 0);
  setTotalPalabras(totalWords);
  // Validar la suma de palabras
  if (totalWords > 400 &&
     (name === 'articleObjetive' || name === 'articleMethod' 
      || name === 'articleResult' || name === 'articleConclusion')
     ) {
    setTitleAlert('VALIDACIÓN LONGITUD DEL TRABAJO ');
    setMessageAlert('Se ha alcanzado el limite de palabras máximo 400');
    handleChangeStatus();
    //alert('Se ha alcanzado el límite de palabras (400).', 'Alerta de límite de palabras');
    return; // Si la suma de palabras excede el límite, no actualizamos el estado
  }


  setState(prevState => ({
    ...prevState,
    [name]: value
  }));
};

useEffect(() => {
  console.log("Estado actualizado:", state);
}, [state]);

  const handleBlur = () => {
    handleBuscar();
  };
  
useEffect(() => {
    if (openPrint) {
      downloadPdf();
    }
}, [openPrint]);

useEffect(() => {
  if (openConvoca) {
    downloadFile();
  }
}, [openConvoca]);

  const toggleDialog = () => {

  const currentState = open;
    if (!open) {
      setSended(false);
      setEmailAlert('');
      setRazonReimpresion(true);
      setCcEmail([]);
    }
    setOpen(!currentState);

  };

   


  const toggleDialogConvoca = () => {
    const currentState = openConvoca;
    //setOpenConvoca(!currentState);
    setOpen3(true);
  };

  const toggleDialogPrint = () => {
    const currentState = openPrint;
    setOpenPrint(!currentState);
  };

  // Mensajes icono de alarma
  if (props.data.estadoBien == 'En tramite de reposición') {
    li.push('Responsabilidad en proceso');
  }
  if (changeType == getIdOptionSUSIList('Faltante', props.changesTypes)) {
    li.push('Novedad: Faltante');
  }
  if (assetStatus == true) {
    li.push('Estado del bien: Regular');
  }
  if (new Date(props.data.date_checklist).getFullYear() < new Date().getFullYear()) {
    li.push('Verificación vigente: sin reportar');
  }

  /**
   * Maneja el evento de hacer clic en el check de Estado
  */
  const handleChangeStatus = async () => {
      setDialogStatusBad(true);
    // if (assignedAssetID) {
    //   updateAssignedAsset({
    //     id: assignedAssetID,
    //     asset_status: (!assetStatus) ? getIdOptionSUSIList('Regular', props.assetsStatus) : getIdOptionSUSIList('Bueno', props.assetsStatus),
    //     change_type: changeType
    //   });
    // } else {
    //   console.error('Se esta intentando actualizar un estado de un bien que no existe');
    // }
    // setAssetStatus(!assetStatus);
    // props.getCountWithoutUpdate()
  };


  const addTextWithDynamicPosition = (content, x, y, fontSize) => {
    const pdfDoc = new jsPDF();
  
    pdfDoc.setFontSize(fontSize);
    pdfDoc.setFont('Times New Roman', 'normal');
  
    const textWidth = pdfDoc.getTextWidth(content);
  
    pdfDoc.text(content, x, y);
  
    return { xEnd: x + textWidth, yEnd: y }; // Devuelve la posición X y Y donde termina el texto
  };

  const downloadPdf = async () => {
    console.log("antes de archivo");
    console.log(temporalPatients);
    console.log(state);
    var articlePdf = await pdf(DocumentService.generateDocument([
      {
        competitionId  :   state.competitionId,
        presentationId :   state.presentationId,
        presentationName:  state.presentationName,
        categoryId     :   state.categoryId,
        categoryName   :   state.categoryName,
        thematicAreaId :   state.thematicAreaId,
        idUser         :   props.user.user_susi_id,
        memberIdcard:      state.memberIdcard,
        articleSubmitted:  state.articleSubmitted,
        articleSubmittedWhere: state.articleSubmittedWhere,
        articleKeyword1  : state.articleKeyword1,
        articleKeyword2  : state.articleKeyword2,
        articleKeyword3  : state.articleKeyword3,
        articleKeyword4  : state.articleKeyword4,
        articleKeyword5  : state.articleKeyword5,
        articleTitle     : state.articleTitle,
        articleShortTitle: state.articleShortTitle,
        articleObjetive  : state.articleObjetive,
        articleMethod    : state.articleMethod,
        articleResult    : state.articleResult,
        articleConclusion: state.articleConclusion,
        articlePatients   : temporalPatients,
        estadoBien: props.data.estadoBien,
        assetStatus: assetStatus,
        ubicacion: headquarterChs,
        lastCheck: props.data.date_checklist,
        dependencia: props.data.dependencia,
        seccional: props.data.nombreSeccional,
        funcionarioSolicitante: props.data.funcionarioSolicitante,
        features: temporalFiliations,
        responsable: props.user.fullName,
        consecutivo: terminar,
        inscriptionId: state.inscriptionId
      }
    ], false, true,false)).toBlob();

    var csvURL = window.URL.createObjectURL(articlePdf);
    setPdfUrl(csvURL);

    var summaryPdf = await pdf(DocumentService.generateDocument([
      {
        competitionId  :   state.competitionId,
        presentationId :   state.presentationId,
        presentationName:  state.presentationName,
        categoryId     :   state.categoryId,
        categoryName   :   state.categoryName,
        thematicAreaId :   state.thematicAreaId,
        idUser         :   props.user.user_susi_id,
        memberIdcard:      state.memberIdcard,
        articleSubmitted:  state.articleSubmitted,
        articleSubmittedWhere: state.articleSubmittedWhere,
        articleKeyword1  : state.articleKeyword1,
        articleKeyword2  : state.articleKeyword2,
        articleKeyword3  : state.articleKeyword3,
        articleKeyword4  : state.articleKeyword4,
        articleKeyword5  : state.articleKeyword5,
        articleTitle     : state.articleTitle,
        articleShortTitle: state.articleShortTitle,
        articleObjetive  : state.articleObjetive,
        articleMethod    : state.articleMethod,
        articleResult    : state.articleResult,
        articleConclusion: state.articleConclusion,
        summary          : true,
        estadoBien: props.data.estadoBien,
        assetStatus: assetStatus,
        ubicacion: headquarterChs,
        lastCheck: props.data.date_checklist,
        dependencia: props.data.dependencia,
        seccional: props.data.nombreSeccional,
        funcionarioSolicitante: props.data.funcionarioSolicitante,
        features: props.data.features,
        responsable: props.user.fullName,
        consecutivo: terminar,
        inscriptionId: state.inscriptionId
      }
    ], false, enableFeaturesPrint, true)).toBlob();
    var csvURL1 = window.URL.createObjectURL(summaryPdf);
    setPdfUrl1(csvURL1);

    // var pdfBlob = await articlePdf.toBlob();

    var newPdfFile  = new File([articlePdf], 'article.pdf', { type: 'application/pdf' });
    var newPdfFile1 = new File([summaryPdf], 'summary.pdf', { type: 'application/pdf' });
    setPdfFile(newPdfFile);
    setPdfFile1(newPdfFile1);

    // let tempLink = window.document.createElement('a');
    // tempLink.href = csvURL;
    // tempLink.setAttribute('download', 'ACHO - ' + props.data.placaBien + '.pdf');
    // tempLink.click();
  }

  // const handleChangeType = async (event) => {
  //   if (event.target.value == getIdOptionSUSIList('Faltante', props.changesTypes)) {
  //     setShowDialogTypeMissing(true);
  //   }
  //   setChangeType(event.target.value);
  //   props.getCountWithoutUpdate()
  //   if (assignedAssetID && event.target.value == getIdOptionSUSIList('Verificado', props.changesTypes)) {
  //     updateAssignedAsset({
  //       id: assignedAssetID,
  //       assetStatus: (assetStatus) ? getIdOptionSUSIList('Regular', props.assetsStatus) : getIdOptionSUSIList('Bueno', props.assetsStatus),
  //       change_type: event.target.value
  //     });
  //   } else if (event.target.value != getIdOptionSUSIList('Faltante', props.changesTypes)) {
  //     console.error('Se esta intentando actualizar un estado de un bien que no existe');
  //   }
  // };

  const handlepresentation = (event) => {
    const selectedpresentation =  props.propsDad.presentation.find(presentation => presentation.presentationId == event.target.value);
    setState({
        ...state,
        presentationId: event.target.value,
        presentationName: selectedpresentation ? selectedpresentation.presentationName : ''
    });
};

    const handleCategory = (event) => {
      const selectedCategory = props.propsDad.categories.find(category => category.categoryId === event.target.value);
      setState({
          ...state,
          categoryId: event.target.value,
          categoryName: selectedCategory ? selectedCategory.categoryName : ''
      });
    };

    const handleArticleSubmitted = (event) => {
      const selectedArticleSubmitted = yesnot.find(submitted => submitted.id === event.target.value);
      setState({
          ...state,
          articleSubmitted: event.target.value,
          articleSubmittedName: selectedArticleSubmitted ? selectedArticleSubmitted.label : ''
      });
    };

    const handlePatientConflict = (event) => {
      const selectedPatientConflict = yesnot.find(conflict => conflict.id === event.target.value);
      setState({
          ...state,
          PatientConflict: event.target.value,
          PatientConflictName: selectedPatientConflict ? selectedPatientConflict.label : ''
      });
    };


  const handleMissingReason = (event) => {
    let nameSUSIList = getNameOptionSUSIList(event.target.value, props.missingReasons)
    props.getCountWithoutUpdate()
    setMissingReason(event.target.value);
    setErrorFormMissing("");
    if (nameSUSIList == 'Perdida') {
      setTextOption('Se considera la pérdida de un bien, cuando de forma voluntaria o involuntaria se ocasiona el extravió físico del mismo. Esta novedad debe ser comunicada al jefe inmediato, y de igual forma podrá iniciar la reclamación ante el seguro, reposición voluntaria o consignación ante el tesoro nacional.')
    } else {
      setTextOption('Se considera que un bien ha sido hurtado, cuando el mismo ha sido tomado de forma ilegítima sin su consentimiento por un tercero. Esta novedad debe ser comunicada al jefe inmediato, y de igual forma podrá iniciar la reclamación ante el seguro, reposición voluntaria o consignación ante el tesoro nacional.')
    }
    setShowDialogtheft(false) //TLA deshabilito 
    //updateAssignedAsset({id: assignedAssetID, missing_reason: event.target.value});
  };

  const handleChangeSwitch2 = async () => {
    //const id = event.currentTarget.id;

    setOpen4(false)
    //setThematic(id);
    setDisplayAlert('none')
    // this.props.propsDad.myInscriptions.dispatch(fetchInventoryHistory({ idUser: props.user.user_susi_id }));
    // if (props.propsDad.myInscriptions && props.propsDad.myInscriptions.length > 0) {
    //   let pendiente = await props.propsDad.myInscriptions.find(pendiente => pendiente.inscriptionState == 0);
    //   console.log("encontro");
    //   console.log(pendiente);
    //   if(pendiente){setOpenPendient(true);}else{
    //     setSwitchOption2(!switchOption2);
    //     setOpen3(true);

    //   }
    // }else{
    //  setSwitchOption2(!switchOption2);
      setOpen3(true);
    //}
  };

  const handleChangeSwitch3 = () => {
    setTemporalFiliations([]);
    
    setSwitchOption3(!switchOption3);
    setOpen4(true);
    setDisplayAlert('none')
  };

  const handleChangeSwitch4 = () => {
    setSwitchOption4(!switchOption4);
    setOpen5(true);
    setDisplayAlert('none')
  };




  const handleClose = () => {
  //  props.getCountWithoutUpdate()
    setDialogStatusBad(false);
  };

  const closeDialogTypeMissing = () => {
    setAlertObservations(true)
    setShowDialogTypeMissing(false);
    setChangeType(props.data.change_type_id)
  };

  const notifyAlert = () => {
    if (li.length > 0) {
      return (
        <ul style={{ margin: 0, padding: 0 }}>
          {li.map((item, count) => {
            return (
              <li key={count}>{item}</li>
            )
          })}
        </ul>
      );
    } else {
      return ''
    }
  }

  const handleClose3 = async () => {
    //console.log('recordsLocation: ',recordsLocation,' headquarterChs: ',headquarterChs);
    if (recordsLocation != null && headquarterChs.length > 0) {
      // await updateUbication({ idAsset: assignedAssetID, complement: locationChs, idLocation: recordsLocation })
      setOpen3(false);
      setSaveHeadquarterChs(headquarterChs)
      setSaveLocationChs(locationChs)
      props.getCountWithoutUpdate()
      setDisplayAlert('none')
    } else {
      setDisplayAlert('flex')
    }
  };

  const handleClose4 = async () => {
    //console.log('recordsLocation: ',recordsLocation,' headquarterChs: ',headquarterChs);
    if (recordsLocation != null && headquarterChs.length > 0) {
      // await updateUbication({ idAsset: assignedAssetID, complement: locationChs, idLocation: recordsLocation })
      setOpen4(false);
      setSaveHeadquarterChs(headquarterChs)
      setSaveLocationChs(locationChs)
      props.getCountWithoutUpdate()
      setDisplayAlert('none')
    } else {
      setDisplayAlert('flex')
    }
  };

  const handleClose5 = async () => {
    //console.log('recordsLocation: ',recordsLocation,' headquarterChs: ',headquarterChs);
    if (recordsLocation != null && headquarterChs.length > 0) {
      // await updateUbication({ idAsset: assignedAssetID, complement: locationChs, idLocation: recordsLocation })
      setOpen5(false);
      setSaveHeadquarterChs(headquarterChs)
      setSaveLocationChs(locationChs)
      props.getCountWithoutUpdate()
      setDisplayAlert('none')
    } else {
      setDisplayAlert('flex')
    }
  };


  const handleObservations = (event) => {
    setObservations(event.target.value)
  }

  const handleSaveReasonMissing = async () => {
    let threeWords = []
    if (observations != null) {
      threeWords = observations.trim().split(/(\s)/).filter(i => i.trim().length > 0);
    }
    if (!savingMissingReason) {
      if (!missingReason) {
        setErrorFormMissing("Debe seleccionar una razón de Faltante");
        return;
      } else if (!observations) {
        setErrorFormMissing("Debe ingresar observaciones");
        return;
      } else if (threeWords.length < 3) {
        setErrorFormMissing("Minimo debe tener 3 palabras");
        return;
      } else {
        setErrorFormMissing("");
      }

      const savedRecord = await updateAssignedAsset({
        // id: assignedAssetID,
        change_type: susi_lists.newness.missing,
        missing_reason: missingReason,
        observations: observations
      });
      if (savedRecord) {
        setSavingMissingReason(false);
        setShowDialogTypeMissing(false);
      }
      props.getCountWithoutUpdate()
      setAlertObservations(true)
    }
  }
 const handleDialogShare = () => {
    setShowDialogShare(true);
 } 

  const validateEmailString = (input = '') => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(input)
  }

  const handleCloseDialogShare = () => {
    setShowDialogShare(false);
  }

  const searchLocation = (event, inputValue) => {
    let params = {
      texto: inputValue
    };
    props.propsDad.dispatch(fetchLocations(params));
  }

  const selectHeadQuarter = (event, inputValue) => {
    if (inputValue == null) inputValue = headquarterChs
    let structHQ = props.locations.filter(hqBD => hqBD.fullname == inputValue);
    if (structHQ.length > 0) {
      setRecordsLocation(structHQ[0].id);
      setHeadQuarterChs(inputValue);
    }
  }


const downloadFile = async () => {
  const pdfPath = process.env.PUBLIC_URL + DOCUMENTS_FOLDER +  'CONVOCATORIA_A.pdf';
  console.log("convocatoria");
  console.log(pdfPath);
  // Descargar el archivo PDF como un Blob
  const response = await fetch(pdfPath);
  const blob = await response.blob();

  // Crear una URL para el Blob
  const blobUrl2 = URL.createObjectURL(blob);

  // Actualizar el estado con la URL del Blob
  if(blobUrl2) {setPdfUrl2(blobUrl2)};
}


const renderDialogConvoca = () => {

  return (
    <div>
    <Dialog  
      PaperProps={{
        style: {
          paddingBottom: '0 !important',
          // overflowY: 'auto',
          maxHeight: '90vh',
          width: '80vw',          // Ancho al 100% de la ventana del navegador
          maxWidth: 'none !important',  // Sin límite de ancho máximo
          opacity: '1 !important',              // Desactivar la opacidad
          // scrollbarWidth: 'auto',  // Ancho del scrollbar
          // scrollbarColor: '#742239 #f7f6fa',  // Color del scrollbar y de su thumb
        }
      }} 
      fullWidth={false}
      maxWidth={false}
      className='dialogReounded dialogUbication' 
      open={false} //{openConvoca} 
      aria-labelledby="form-dialog-title"
    >
        <DialogTitle className='dialogTttle' id="alert-dialog-title" >
                CONVOCATORÍA 
        </DialogTitle>
        
        <DialogContent style={{ paddingLeft: '2rem' }}>
          <DialogContentText id="alert-dialog-description">
            {pdfUrl2 && <iframe src={pdfUrl2} width="100%" height="600px"></iframe>}
          </DialogContentText>         
        </DialogContent>
        <DialogActions style={{ padding: '0.5rem 1rem 1rem' }}>
          <Button className='btnPrimary' onClick={toggleDialogConvoca} color="primary">
            Salir
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}




  /* renderDialog */

  const renderDialogPrint = () => {


    return (
      <div>
      <Dialog  
        PaperProps={{
          style: {
            paddingBottom: '0 !important',
            // overflowY: 'auto',
            maxHeight: '90vh',
            width: '80vw',          // Ancho al 100% de la ventana del navegador
            maxWidth: 'none !important',  // Sin límite de ancho máximo
            opacity: '1 !important',              // Desactivar la opacidad
            // scrollbarWidth: 'auto',  // Ancho del scrollbar
            // scrollbarColor: '#742239 #f7f6fa',  // Color del scrollbar y de su thumb
          }
        }} 
        fullWidth={false}
        maxWidth={false}
        className='dialogReounded dialogUbication' 
        open={false}//{openPrint} 
        aria-labelledby="form-dialog-title"
      >

          {terminar == true ? 
              <DialogTitle className='dialogTttle' id="alert-dialog-title" >
                  El Soporte de Inscripción será generado una vez de clic en el botón INSCRIBIR
              </DialogTitle>
              :
              <DialogTitle className='dialogTttle' id="alert-dialog-title" >
                  Su Inscripción no puede ser aceptada porque contiene errores
              </DialogTitle>
          }
          <DialogContent style={{ paddingLeft: '2rem' }}>
            <DialogContentText id="alert-dialog-description">
              <label>Favor verificar que toda la información se encuentre correcta</label>
              {pdfUrl && <iframe src={pdfUrl} width="100%" height="600px"></iframe>}
            </DialogContentText>
            
          </DialogContent>
          <DialogActions style={{ padding: '0.5rem 1rem 1rem' }}>
            <Button className='btnPrimary' onClick={toggleDialogPrint} color="primary">
              Cancelar
            </Button>
            <Button className='btnPrimary' onClick={addInscription} disabled={!terminar} color={terminar ? "success" : "secondary"} autoFocus>
              Inscribir
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }

  const renderDialogShare = () => {
    return (
      <Dialog className='dialogReounded' 
      open={false}> 
      {/* {showDialogShare}> */}
        <DialogTitle id="form-dialog-title">Compartir Bien</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Seleccione como quiere compartir el bien
          </DialogContentText>
          <List>
            <ListItem button onClick={() => alert("Vamos a compartir como imagen")} key="image">
              <ListItemAvatar>
                <Avatar>
                  <ImageIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Como Imagen" />
            </ListItem>
            <ListItem button onClick={() => alert("Vamos a compartir como HTML")} key="html">
              <ListItemAvatar>
                <Avatar>
                  <CommentIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Como HTML" />
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogShare} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleCloseDialogShare} color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  const renderDialogStatusBad = () => {
    return (<Dialog className='dialogReounded estadoBien'
      open={false}//{showDialogStatusBad}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      style={{ padding: '1rem', borderRadius: '20px!important', boxShadow: '0!important' }}
    >
      <DialogTitle id="form-dialog-title" color='red'>{titleAlert}</DialogTitle>
      <DialogContent style={{ padding: '40px 39px 15px' }}>
        <DialogContentText className='dialogText'  id="alert-dialog-slide-description">
        {messageAlert}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ alignSelf: 'center', marginBottom: '0.938rem' }}>
        <Button className='btnError' variant='outlined' onClick={handleClose}>
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>);
  }

  const renderDialogChangeTypeLost = () => {
    return (
      <Dialog className='dialogReounded dialogChangeLost' 
      open={false}>
        {/* {showDialogTypeMissing} aria-labelledby="form-dialog-title"> */}
        <DialogContent style={{ borderRadius: '1rem' }}>
          <p className='tituloCards'>Apreciad@ servidor@</p>
          <DialogContentText className='dialogText'  id="alert-dialog-slide-description">
          Si el bien no está en su poder y no tiene control sobre él, seleccione aquí la razón de la ausencia.
          </DialogContentText>
          <DialogContentText className='dialogText'  id="alert-dialog-slide-description">
          Cuando finalice el reporte de su inventario, podrá dirigirse al módulo Bienes <span style={{ fontWeight: 'bold' }}>No Verificados Físicamente</span> para reportar la ausencia del bien.
          </DialogContentText>

          <FormControl component="fieldset" style={{ width: '100%' }}>
            <Grid style={{ paddingLeft: '1rem' }} direction="column" container>
              <Grid item container>
                <RadioGroup aria-label="missing_reason" name="missing_reason" value={missingReason} onChange={handleMissingReason}>
                  {props.missingReasons.map((optMissingReason) => (
                    <FormControlLabel
                      key={optMissingReason.value}
                      value={optMissingReason.id}
                      control={<BlueRadio />}
                      checked={missingReason == optMissingReason.id}
                      label={optMissingReason.name} />
                  ))}
                </RadioGroup>
              </Grid>
              <Grid item container direction="column">
                <Grid item container>
                  <label style={{ fontSize: '0.875rem', color: '#5f6273' }}>Observaciones</label>
                  <div style={{ color: 'red', paddingLeft: '0.2rem' }}>{' *'}</div>
                </Grid>
                <Grid item container>
                  <TextareaAutosize
                    onClick={() => {
                      if (alertObservations) {
                        setAlertObservations(false)
                        setTextOption('Indique de forma concreta la razón de la pérdida o hurto del bien a relacionar dentro de estas novedades.')
                        setShowDialogtheft(true)
                      }
                    }}
                    minRows={6}
                    cols={5}
                    autoFocus
                    id="name"
                    type="text"
                    value={observations == null ? ' ' : observations}
                    onChange={handleObservations}
                    className='textArea' />
                </Grid>
              </Grid>
            </Grid>
          </FormControl>
          {errorFormMissing != '' ? (<Alert style={{ marginTop: '0.5rem' }} severity="error">{errorFormMissing}</Alert>) : null}

          <DialogContentText className='dialogText'  id="alert-dialog-slide-description">
          <span style={{ fontWeight: 'bold' }}>Nota:</span>  No aplica para los bienes que asignó a otro servidor temporalmente en calidad de préstamo. Artículo 32. Resolución 01166 de 2021.
          </DialogContentText>

        </DialogContent>
        <DialogActions style={{ alignSelf: 'center', marginBottom: '1.938rem' }}>
          <Button onClick={closeDialogTypeMissing} style={{ width: '100px' }} className='btnSecundary' color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSaveReasonMissing} style={{ width: '100px' }} className='btnPrimary' color="primary">
            {savingMissingReason ? "Guardando ..." : "Aceptar"}
          </Button>
        </DialogActions>
      </Dialog>);
  }

  const agregarFiliacion = () => {
    const newFiliation = {
      companyName: document.getElementById('companyName').value,
      companyService: document.getElementById('companyService').value,
      companyDepartament: document.getElementById('companyDepartament').value,
      companyCity: document.getElementById('companyCity').value
    };
    if (newFiliation.companyName && newFiliation.companyDepartament) {  
        setTemporalFiliations(prevFiliations => [...prevFiliations, newFiliation]);
        // document.getElementById('companyName').value = '';
        // document.getElementById('companyDepartament').value = '';
        // Reiniciar el estado de validación
        console.log("graba filiations");
        console.log(temporalFiliations);
        setIsFiliationValid(true);
        setOpen5(false);
    } else {
      // Mostrar mensaje de alerta
      setTitleAlert('VALIDACIÓN DE FILIACIÓN');
      setMessageAlert('Error: Por favor, completa todos los campos.');
      handleChangeStatus();
      
      
      // Establecer el estado de validación como falso
      setIsFiliationValid(false);
    }
};

const eliminarFiliacion = (indexToRemove) => {
  setTemporalFiliations(prevFiliations => 
    prevFiliations.filter((_, idx) => idx !== indexToRemove)
  );
};

// const [selectedDepartamento, setSelectedDepartamento] = useState(props.propsDad.city[0].nombre);
// const [selectedCiudad, setSelectedCiudad] = useState(props.propsDad.city[0].ciudades[0]);

const [selectedDepartamento, setSelectedDepartamento] = useState('');
const [selectedCiudad,       setSelectedCiudad]       = useState('');
const [selectedPais,         setSelectedPais]         = useState('');
const [selectedBase,         setSelectedBase]         = useState('');
const [selectedTidentif,     setSelectedTidentif]         = useState('');
const [selectedCie10,        setSelectedCie10]        = useState('');
const [selectedCiuo,         setSelectedCiuo]         = useState('');
const [selectedCiuu,         setSelectedCiuu]         = useState('');
const [selectedClasificacion, setSelectedClasificacion] = useState('');
const [selectedDefuncion,      setSelectedDefuncion]      = useState('');
const [selectedDiferenciacion,    setSelectedDiferenciacion]    = useState('');
const [selectedEps,          setSelectedEps]          = useState('');
const [selectedErrorhisto,   setSelectedErrorhisto]   = useState('');
const [selectedHistologico, setSelectedHistologico]   = useState('');
const [selectedIps,          setSelectedIps]          = useState('');
const [selectedLateralidad,   setSelectedLateralidad]   = useState('');
const [selectedMaintop,      setSelectedMaintop]      = useState('');
const [selectedNotrat,  setSelectedNotrat]  = useState('');
const [selectedTopografico,  setSelectedTopografico]  = useState('');
const [selectedValidacion,   setSelectedValidacion]   = useState('');
const [selectedPeriodo,       setSelectedPeriodo]       = useState('');
const [selectedSexo,          setSelectedSexo]          = useState('');
const [selectedOtro,        setSelectedOtro]        = useState('');
const [selectedTipo,         setSelectedTipo]         = useState('');
const [selectedTipoeps,      setSelectedTipoeps]      = useState('');
const [selectedTtel,      setSelectedTtel]      = useState('');
const [selectedTusua,     setSelectedTusua]     = useState('');
const [selectedZona,         setSelectedZona]         = useState('');


const handleChangeDepartamento = (event) => {
  const selected = event.target.value;
  setSelectedDepartamento(selected);
  setSelectedCiudad(props.propsDad.city.find(dep => dep.nombre === selected).ciudades[0]);
};

const handleChangeCiudad = (event) => {
  setSelectedCiudad(event.target.value);
};

const handleChangeSexo = (event) => {
  setSelectedSexo(event.target.value);
};

const handleChangePais = (event) => {
  setSelectedPais(event.target.value);
};

const handleChangeBase = (event) => {
  setSelectedBase(event.target.value);
};

const handleChangeTidentif = (event) => {
  setSelectedTidentif(event.target.value);
};

const handleChangeCie10 = (event) => {
  setSelectedCie10(event.target.value);
};

const handleChangeCiuo = (event) => {
  setSelectedCiuo(event.target.value);
};

const handleChangeCiuu = (event) => {
  setSelectedCiuu(event.target.value);
};

const handleChangeClasificacion = (event) => {
  setSelectedClasificacion(event.target.value);
};

const handleChangeDefuncion = (event) => {
  setSelectedDefuncion(event.target.value);
};

const handleChangeDiferenciacion = (event) => {
  setSelectedDiferenciacion(event.target.value);
};

const handleChangeEps = (event) => {
  setSelectedEps(event.target.value);
};

const handleChangeErrorhisto = (event) => {
  setSelectedErrorhisto(event.target.value);
};

const handleChangeHistologico = (event) => {
  setSelectedHistologico(event.target.value);
};

const handleChangeIps = (event) => {
  setSelectedIps(event.target.value);
};

const handleChangeLateralidad = (event) => {
  setSelectedLateralidad(event.target.value);
};

const handleChangeMaintop = (event) => {
  setSelectedMaintop(event.target.value);
};

const handleChangeNotrat = (event) => {
  setSelectedNotrat(event.target.value);
};

const handleChangeTopografico = (event) => {
  setSelectedTopografico(event.target.value);
};

const handleChangeValidacion = (event) => {
  setSelectedValidacion(event.target.value);
};

const handleChangePeriodo = (event) => {
  setSelectedPeriodo(event.target.value);
};

const handleChangeOtro = (event) => {
  setSelectedOtro(event.target.value);
};

const handleChangeTipo = (event) => {
  setSelectedTipo(event.target.value);
};

const handleChangeTipoeps = (event) => {
  setSelectedTipoeps(event.target.value);
};

const handleChangeTtel = (event) => {
  setSelectedTtel(event.target.value);
};

const handleChangeTusua = (event) => {
  setSelectedTusua(event.target.value);
};

const handleChangeZona = (event) => {
  setSelectedZona(event.target.value);
};



useEffect(() => {
  if (props.propsDad.city && props.propsDad.city.length > 0) {
    setSelectedDepartamento(props.propsDad.city[0].nombre);
    setSelectedCiudad(props.propsDad.city[0].ciudades[0]);
  }
}, [props.propsDad.city]);


// TLA nuevo
const renderDialogFiliations = () => {
  

  return (

<Dialog  
  PaperProps={{
    style: {
      paddingBottom: '0 !important',
      //overflowY: 'auto',
      maxHeight: '90vh',
      width: '80vw',          // Ancho al 100% de la ventana del navegador
      maxWidth: 'none !important',  // Sin límite de ancho máximo
      opacity: '1 !important',              // Desactivar la opacidad
      scrollbarWidth: 'auto',  // Ancho del scrollbar
      scrollbarColor: '#742239 #f7f6fa',  // Color del scrollbar y de su thumb
    }
  }} 
  fullWidth={false}
  maxWidth={false}
  className='dialogReounded dialogUbication' 
  open={open5} 
  aria-labelledby="form-dialog-title"
>

<DialogTitle style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.25rem', color: '#5f6273', margin: 0, marginTop: '0rem' }}>DATOS MARCADOR</DialogTitle>
<DialogContent style={{ paddingBottom: '0 !important', overflowY: 'hidden', maxHeight: '80vh'  }}>

<Grid container spacing={1}>
  <Grid item xs={12} sm={12} lg={12} xl={12} container alignItems='center'>
    <label className='labelBold'>Tipo cáncer:</label>
  </Grid>
  <Grid item xs={12} sm={12} lg={12} xl={12}>
    <select value={selectedDepartamento} 
    id="companyDepartament" onChange={handleChangeDepartamento} 
    style={{ width: '100%', fontSize: '1rem', height: '28px' }}>
      {props.propsDad.city && props.propsDad.city.map((departamento, index) => (
        <option key={index} value={departamento.nombre}>
          {departamento.nombre}
        </option>
      ))}
    </select>
  </Grid>
</Grid>
<Grid container spacing={1}>
  <Grid item xs={12} sm={12} lg={12} xl={12} container alignItems='center'>
    <label className='labelBold'>Marcador:</label>
  </Grid>
  <Grid item xs={12} sm={12} lg={12} xl={12}>
    <select
      value={selectedCiudad}
      id="companyCity"
      onChange={handleChangeCiudad}
      style={{ width: '100%', fontSize: '1rem', height: '28px' }}
    >
      {props.propsDad.city &&
        props.propsDad.city.find(dep => dep.nombre === selectedDepartamento)?.ciudades.map((ciudad, index) => (
          <option key={index} value={ciudad}>
            {ciudad}
          </option>
        ))}
    </select>
  </Grid>
</Grid> 
        <Grid item xs={12} sm lg xl container direction='column' justifyContent='center'>
            <label className='labelBold'>Resultado: </label>
            <RadioGroup aria-label="Speakerdo" name="articleSubmitted" value={state.articleSubmitted} onChange={handleArticleSubmitted}>
                  {yesnot &&  yesnot.map((articleSubmitted) => (
                    <FormControlLabel
                      key={articleSubmitted.id}
                      value={articleSubmitted.id}
                      control={<BlueRadio />}
                      checked={state.articleSubmitted == articleSubmitted.id}
                      label={articleSubmitted.label} />
                  ))}
           </RadioGroup>
        </Grid>


<Grid container spacing={1}>
    <Grid item xs={12} sm={12} lg={12} xl={12} container justifyContent='flex-start'>
    <label className='labelBold' >Porcentaje:</label>
    <TextField style={{ marginBottom: '0.5rem' }} className='field' fullWidth id="companyService"  variant="outlined" />
    </Grid>
</Grid>

 {/* TLA nuevo */}

</DialogContent>
<DialogActions style={{ justifyContent: 'center', margin: '1rem', marginTop: '1rem' }}>
<Button style={{ width: '100px' }} className='btnPrimary' onClick={() => {
//  setHeadQuarterChs(saveHeadquarterChs)
//  setLocationChs(saveLocationChs)
 setOpen5(false)
}} color="primary">
 Cancelar
</Button>
<Button style={{ width: '100px' }} className='btnPrimary' 
onClick={agregarFiliacion} 
color="primary">
 Guardar
</Button>
</DialogActions>
</Dialog>

);
}

useEffect(() => {
  console.log('temporalPatients:', temporalPatients);
}, [temporalPatients]);

// TLA nuevo
const agregarPatient = () => {
  const newPatient = {
    patientSap         : formPatient.patientSap,
    PatientHistory     : formPatient.PatientHistory,
    PatientFirstname   : formPatient.PatientFirstname,
    PatientSecondname  : formPatient.PatientSecondname,
    PatientName        : formPatient.PatientName,
    PatientRegben      : formPatient.PatientRegben,
    PatientRegmal      : formPatient.PatientRegmal,
    PatientDatebirth   : formPatient.PatientDatebirth,
    PatientAge         : formPatient.PatientAge,
    PatientSex         : formPatient.PatientSex,
    PatientTypedoc     : formPatient.PatientTypedoc,
    PatientDocument    : formPatient.PatientDocument,
    PatientDateexp     : formPatient.PatientDateexp,
    PatientTypeuser    : formPatient.PatientTypeuser,
    PatientYearswork   : formPatient.PatientYearswork,
    PatientActeconomic : formPatient.PatientActeconomic,
    PatientOcupation   : formPatient.PatientOcupation,
    PatientCountrybirth: formPatient.PatientCountrybirth,
    PatientDeptobirth  : formPatient.PatientDeptobirth,
    PatientMunbirth    : formPatient.PatientMunbirth,
    PatientCountryees  : formPatient.PatientCountryees,
    PatientDeptores    : formPatient.PatientDeptores,
    PatientMunres      : formPatient.PatientMunres,
    PatientAddress     : formPatient.PatientAddress,
    PatientNeight      : formPatient.PatientNeight,
    PatientTel1        : formPatient.PatientTel1,
    PatientTel2        : formPatient.PatientTel2,
    PatientTypetel     : formPatient.PatientTypetel,
    PatientZone        : formPatient.PatientZone,
    PatientCodeIns     : formPatient.PatientCodeIns
  };
  let errors = [];

  if (!newPatient.PatientHistory) {
    errors.push('Sin Historia Clínica.');
  }
  if (!newPatient.PatientSap) {
    errors.push('Sin identificación SAP.');
  }
  if (!newPatient.PatientName) {
    errors.push('Sin nombres del paciente.');
  }
  if (!newPatient.PatientFirstname) {
    errors.push('Sin primer apellido.');
  }
  if (!newPatient.Datebirth) {
    errors.push('Sin fecha de nacimiento');
  }
  if (!newPatient.Sex) {
    errors.push('Sin sexo');
  }
  if (!newPatient.Typedoc) {
    errors.push('Sin fecha de nacimiento');
  }
  if (!newPatient.Document) {
    errors.push('Sin número de documento');
  }
  if (!newPatient.Dateexp) {
    errors.push('No tiene fecha de expedición del documento');
  }
  if (!newPatient.Typeuser) {
    errors.push('No tiene definido tipo de usuario');
  }
  if (!newPatient.Yearswork) {
    errors.push('No ha ingresado el número de años trabajados');
  }

  if (!newPatient.PatientActeconomic) {
    errors.push('No ha definido la actividad económica');
  }
  if (!newPatient.ocupation) {
    errors.push('No se ha definido la ocupación');
  }
  if (!newPatient.Countrybirth ||  !newPatient.Deptobirth || !newPatient.Munbirth) {
    errors.push('No se ha definido el lugar de nacimiento');
  }
  if (!newPatient.Countryres ||  !newPatient.Deptores || !newPatient.Munres) {
    errors.push('No se ha definido el lugar de residencia');
  }
  if (!newPatient.Address) {
    errors.push('No se ha definido dirección de residencia');
  }
  if (!newPatient.Neight) {
    errors.push('No se ha definido barrio de residencia');
  }
  if (!newPatient.Zone) {
    errors.push('No se ha definido la zona de residencia');
  }

  if (errors.length == 0) {  
      console.log("nuevo autor");
      console.log(newPatient);
      setTemporalPatients(prevPatients => [...prevPatients, newPatient]);
      console.log(temporalPatients);
      setFormPatient(initialFormPatient);
      setIsPatientValid(true);
      setOpen4(false);
  } else {
    // Concatenar todos los errores en una sola cadena de texto
    const errorMessage = errors.join('\n');
    
    // Mostrar mensaje de alerta con todos los errores
    setTitleAlert('VALIDACIÓN DE DATOS PACIENTE');
    setMessageAlert(errorMessage);
    handleChangeStatus();
    // alert(errorMessage);
    
    setIsPatientValid(false);
  }
};

const eliminarPatient = (indexToRemove) => {
  setTemporalPatients(prevPatients => 
    prevPatients.filter((_, idx) => idx !== indexToRemove)
  );
};



const renderDialogPatients = () => {

  const handleOptionChange = (event, value) => {
    setFormPatient((prevState) => ({
      ...prevState,           
      PatientActeconomic : value,
    }));
  };
  const handleOption1Change = (event, value) => {
    setFormPatient((prevState) => ({
      ...prevState,           
      PatientOcupation : value,
    }));
  };

  const today = dayjs();
  const yesterday = dayjs().subtract(1, 'day');
  const todayStartOfTheDay = today.startOf('day');

  const tabsStyle = {
    backgroundColor: 'white', // Verde claro para el fondo general
  };

  const tabStyle = {
    color: 'black', // Texto en negro para todas las pestañas
    backgroundColor: '#e7fce4', // Fondo verde claro para pestañas no seleccionadas
    width: '50%', // Cada pestaña ocupa el 50% del ancho
  };

  const selectedTabStyle = {
    color: 'black', // Texto en negro para la pestaña seleccionada
    backgroundColor: '#00A19A', // Fondo verde oscuro para la pestaña seleccionada
    width: '50%', // Cada pestaña ocupa el 50% del ancho
  };

  const indicatorStyle = {
    backgroundColor: '#3c6f96', // Color del indicador de la pestaña seleccionada
  };


  return (

// {/* <Dialog  style={{ paddingBottom: '0 !important', overflowY: 'auto',  */}
  <Dialog  
  PaperProps={{
    style: {
      paddingBottom: '0 !important',
      overflowY: 'auto',
      maxHeight: '90vh',
      width: '80vw',          // Ancho al 100% de la ventana del navegador
      maxWidth: 'none !important',  // Sin límite de ancho máximo
      opacity: '1 !important',              // Desactivar la opacidad
      scrollbarWidth: 'auto',  // Ancho del scrollbar
      scrollbarColor: '#742239 #f7f6fa',  // Color del scrollbar y de su thumb
    }
  }} 
  fullWidth={false}
  maxWidth={false}
  className='dialogReounded dialogUbication' 
  open={open4} 
  aria-labelledby="form-dialog-title"
>
<DialogTitle style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1rem !important', color: '#5f6273', margin: 0, marginTop: '0rem' }}>DATOS DEL PACIENTE</DialogTitle>
<DialogContent style={{ paddingBottom: '0 !important', overflowY: 'hidden', maxHeight: '98vh'  }}>


<Box sx={{ width: '100%', height: '95vh', overflow: 'hidden' }}>
      <Grid item xs={12} sm={12} lg={12} xl={12} container justifyContent='flex-start'>
        <Grid item xs={12} sm={6} lg={3} xl={3} container justifyContent='flex-start'>
        
        <label className='labelBold' style={{fontSize: '1rem !important'}} >Historia clínica:</label>
        <TextField style={{ marginBottom: '0.5rem', marginTop: '0.5rem' }} className='field' type='text' 
        fullWidth id="PatientHistory"  variant="outlined" inputProps={{ maxLength: 11 }} 
        value={formPatient.PatientHistory}
        onChange={handleChangePatient}/>
        </Grid>

        <Grid item xs={12} sm={6} lg={3} xl={3} container justifyContent='flex-start'>
        <label className='labelBold' >Número SAP:</label>
        <TextField style={{ marginBottom: '0.5rem', marginTop: '0.5rem' }} className='field' type='text' 
        fullWidth id="PatientSap"  variant="outlined" inputProps={{ maxLength: 11 }} 
        value={formPatient.PatientSap}
        onChange={handleChangePatient}/>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} lg={12} xl={12} container justifyContent='flex-start'>
        <Grid item xs={12} sm={6} lg={4} xl={4} container justifyContent='flex-start'>
        <label className='labelBold' >Primer apellido:</label>
        <TextField style={{ marginBottom: '0.5rem', marginTop: '0.5rem' }} className='field' type='text' 
        fullWidth id="PatientFirstname"  variant="outlined" inputProps={{ maxLength: 20 }} 
        value={formPatient.PatientFirstname}
        onChange={handleChangePatient}/>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={4} container justifyContent='flex-start'>
        <label className='labelBold' >Segundo apellido:</label>
        <TextField style={{ marginBottom: '0.5rem', marginTop: '0.5rem' }} className='field' type='text' 
        fullWidth id="PatientSecondname"  variant="outlined" inputProps={{ maxLength: 20 }} 
        value={formPatient.PatientSecondname}
        onChange={handleChangePatient}/>
        </Grid>

        <Grid item xs={12} sm={6} lg={4} xl={4} container justifyContent='flex-start'>
        <label className='labelBold' >Nombres:</label>
        <TextField style={{ marginBottom: '0.5rem', marginTop: '0.5rem' }} className='field' type='text' 
        fullWidth id="PatientName"  variant="outlined" inputProps={{ maxLength: 40 }} 
        value={formPatient.PatientName}
        onChange={handleChangePatient}/>
        </Grid>
      </Grid>
    <Tabs
      value={tabIndex}
      onChange={handleTabChange}
      centered
      // TabIndicatorProps={{ style: indicatorStyle }}
      style={tabsStyle}
      >
      <Tab label="IDENTIFICACIÓN" 
      style={tabIndex === 0 ? selectedTabStyle : tabStyle} />
      <Tab label="UBICACIÓN" 
      style={tabIndex === 1 ? selectedTabStyle : tabStyle} />
    </Tabs>

 {tabIndex===0 && (
  <>
<Grid container spacing={1}>
  <Grid item xs={12} sm={6} lg={2} xl={2} container justifyContent='flex-start'>
  <label className='labelBold' >Consecutivo Registro Benigno:</label>
  <TextField style={{ marginBottom: '0.5rem', marginTop: '0.5rem' }} className='field'
   fullWidth id="PatientRegben"  variant="outlined" inputProps={{ maxLength: 10 }}
    value={formPatient.PatientRegben}
    onChange={handleChangePatient}/>
  </Grid>

  <Grid item xs={12} sm={6} lg={2} xl={2} container justifyContent='flex-start'>
  <label className='labelBold' >Consecutivo Registro Maligno:</label>
  <TextField style={{ marginBottom: '0.5rem', marginTop: '0.5rem' }} className='field'
   fullWidth id="PatientRegben"  variant="outlined" inputProps={{ maxLength: 9 }}
    value={formPatient.PatientRegmal}
    onChange={handleChangePatient}/>
  </Grid>
</Grid>
<Grid item xs={12} sm={12} lg={12} xl={12} container justifyContent='flex-start'>
<Grid item xs={12} sm={6} lg={4} xl={4} container justifyContent='flex-start'>
<LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer
        components={[
          'DatePicker',
          'DateTimePicker',
          'TimePicker',
          'DateRangePicker',
          'DateTimeRangePicker',
        ]}
      >
        <DemoItem label="Fecha de Nacimiento">
          <DatePicker
            defaultValue={yesterday}
            disablePast
            views={['year', 'month', 'day']}
          />
        </DemoItem>
      </DemoContainer>
    </LocalizationProvider>
</Grid>
<Grid item xs={12} sm={6} lg={4} xl={4} container justifyContent='flex-start'>
<Box display="flex" alignItems="center">
     <label className="labelBold" style={{ marginRight: '0.5rem' }}>Edad:</label>
  <TextField style={{ marginBottom: '0.5rem', marginTop: '0.5rem' }} className='field'
   fullWidth id="PatientAge"  variant="outlined" inputProps={{ maxLength: 3 }}
    value={formPatient.PatientAge}
    onChange={handleChangePatient}/>
     <label className="labelBold" style={{ marginRight: '0.5rem' }}>años</label>
</Box>
</Grid>
<Grid item xs={12} sm={6} lg={4} xl={4} container justifyContent='flex-start'>
<label className="labelBold" style={{ marginRight: '0.5rem' }}>Sexo:</label>
<select value={selectedSexo} 
    id="Sex" onChange={handleChangeSexo} 
    style={{ width: '100%', fontSize: '1rem', height: '28px' }}>
      {props.propsDad.sex && props.propsDad.sex.map((sex, index) => (
        <option key={index} value={sex.sexo}>
          {sex.sexo}
        </option>
      ))}
    </select>
</Grid>
</Grid>
<Grid container spacing={1}>
  <Grid item xs={12} sm={12} lg={4} xl={4} container alignItems='center'>
    <label className='labelBold'>Tipo de identificación:</label>
    <select value={selectedDepartamento} 
    id="companyDepartament" onChange={handleChangeDepartamento} 
    style={{ width: '100%', fontSize: '1rem', height: '28px' }}>
      {props.propsDad.city && props.propsDad.city.map((departamento, index) => (
        <option key={index} value={departamento.nombre}>
          {departamento.nombre}
        </option>
      ))}
    </select>
    </Grid>
    <Grid item xs={12} sm={12} lg={4} xl={4} container justifyContent='flex-start'>
        <label className='labelBold' >Nro identificación:</label>
        <TextField style={{ marginBottom: '0.5rem', marginTop: '0.5rem' }} className='field' type='text' 
        fullWidth id="PatientDocument"  variant="outlined" inputProps={{ maxLength: 11 }} 
        value={formPatient.PatientDocument}
        onChange={handleChangePatient}/>
    </Grid>
    <Grid item xs={12} sm={6} lg={4} xl={4} container justifyContent='flex-start'>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer
              components={[
                'DatePicker',
                'DateTimePicker',
                'TimePicker',
                'DateRangePicker',
                'DateTimeRangePicker',
              ]}
            >
              <DemoItem label="Fecha de Expedición">
                <DatePicker
                  defaultValue={yesterday}
                  disablePast
                  views={['year', 'month', 'day']}
                />
              </DemoItem>
            </DemoContainer>
          </LocalizationProvider>
      </Grid>
</Grid>
<Grid container spacing={3}>
  <Grid item xs={12} sm={12} lg={4} xl={4} container alignItems='center'>
    <label className='labelBold'>Tipo de usuario:</label>
    <select value={selectedDepartamento} 
    id="companyDepartament" onChange={handleChangeDepartamento} 
    style={{ width: '100%', fontSize: '1rem', height: '28px' }}>
      {props.propsDad.city && props.propsDad.city.map((departamento, index) => (
        <option key={index} value={departamento.nombre}>
          {departamento.nombre}
        </option>
      ))}
    </select>
  </Grid>

    <Grid item xs={12} sm={12} lg={4} xl={4} container justifyContent='flex-start'>
        <label className='labelBold' >Años trabajados:</label>
        <TextField style={{ marginBottom: '0.5rem', marginTop: '0.5rem' }} className='field' type='number' 
        fullWidth id="PatientYearswork"  variant="outlined" inputProps={{ maxLength: 2 }} 
        value={formPatient.PatientYearswork}
        onChange={handleChangePatient}/>
    </Grid>
</Grid>

<Grid container direction="column" spacing={3}>
  <Grid item>
    {/* Primer Autocomplete */}
    <Autocomplete
      options={props.propsDad.ciuu?.data || []}
      getOptionLabel={(option) => option.act}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={formPatient.PatientActeconomic}
      onChange={handleOptionChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Seleccionar Actividad Económica Habitual"
          variant="outlined"
          className="field"
          InputLabelProps={{
            shrink: true,
            style: { fontSize: '1.2rem' },
          }}
          sx={{
            "& .MuiInputLabel-root": { fontSize: '1.4rem !important' },
            "& .MuiInputBase-input": { fontSize: '1rem !important' },
            mt: 3,
          }}
        />
      )}
    />
  </Grid>
  
  <Grid item>
    {/* Segundo Autocomplete */}
    <Autocomplete
      options={props.propsDad.ciuo?.data || []}
      getOptionLabel={(option) => option.ocup}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={formPatient.PatientOcupation}
      onChange={handleOption1Change}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Seleccionar Ocupación u Oficio Habitual"
          variant="outlined"
          className="field"
          InputLabelProps={{
            shrink: true,
            style: { fontSize: '1.2rem' },
          }}
          sx={{
            "& .MuiInputLabel-root": { fontSize: '1.4rem !important' },
            "& .MuiInputBase-input": { fontSize: '1rem !important' },
          }}
        />
      )}
    />
  </Grid>
</Grid>

</>
)}


{tabIndex===1 && (
  <>
<Grid item xs sm lg="auto">
    <Typography className='fontAa' variant="caption" display="block" gutterBottom>LUGAR DE NACIMIENTO</Typography>
</Grid> 
<Grid container spacing={3}>
  <Grid item xs={12} sm={12} lg={3} xl={3} container alignItems='center'>
    <label className='labelBold'>País:</label>
    <select value={selectedDepartamento} 
    id="companyDepartament" onChange={handleChangeDepartamento} 
    style={{ width: '100%', fontSize: '1rem', height: '28px' }}>
      {props.propsDad.countries && props.propsDad.countries.data.map((departamento, index) => (
        <option key={index} value={departamento.nombre}>
          {departamento.nombre}
        </option>
      ))}
    </select>
  </Grid>
  <Grid item xs={12} sm={12} lg={4} xl={4} container alignItems='center'>
    <label className='labelBold'>Departamento:</label>
    <select value={selectedDepartamento} 
    id="companyDepartament" onChange={handleChangeDepartamento} 
    style={{ width: '100%', fontSize: '1rem', height: '28px' }}>
      {props.propsDad.city && props.propsDad.city.map((departamento, index) => (
        <option key={index} value={departamento.nombre}>
          {departamento.nombre}
        </option>
      ))}
    </select>
  </Grid>

  <Grid item xs={12} sm={12} lg={5} xl={5} container alignItems='center'>
    <label className='labelBold'>Municipio:</label>
    <select value={selectedDepartamento} 
    id="companyDepartament" onChange={handleChangeDepartamento} 
    style={{ width: '100%', fontSize: '1rem', height: '28px' }}>
      {props.propsDad.city && props.propsDad.city.map((departamento, index) => (
        <option key={index} value={departamento.nombre}>
          {departamento.nombre}
        </option>
      ))}
    </select>
  </Grid>
</Grid>
<Grid item xs sm lg="auto">
    <Typography className='fontAa' variant="caption" display="block" gutterBottom>LUGAR DE RESIDENCIA</Typography>
</Grid> 

<Grid container spacing={3}>
  <Grid item xs={12} sm={12} lg={3} xl={3} container alignItems='center'>
    <label className='labelBold'>País:</label>
    <select value={selectedDepartamento} 
    id="companyDepartament" onChange={handleChangeDepartamento} 
    style={{ width: '100%', fontSize: '1rem', height: '28px' }}>
      {props.propsDad.city && props.propsDad.city.map((departamento, index) => (
        <option key={index} value={departamento.nombre}>
          {departamento.nombre}
        </option>
      ))}
    </select>
  </Grid>

  <Grid item xs={12} sm={12} lg={4} xl={4} container alignItems='center'>
    <label className='labelBold'>Departamento:</label>
    <select value={selectedDepartamento} 
    id="companyDepartament" onChange={handleChangeDepartamento} 
    style={{ width: '100%', fontSize: '1rem', height: '28px' }}>
      {props.propsDad.city && props.propsDad.city.map((departamento, index) => (
        <option key={index} value={departamento.nombre}>
          {departamento.nombre}
        </option>
      ))}
    </select>
  </Grid>

  <Grid item xs={12} sm={12} lg={5} xl={5} container alignItems='center'>
    <label className='labelBold'>Municipio:</label>
    <select value={selectedDepartamento} 
    id="companyDepartament" onChange={handleChangeDepartamento} 
    style={{ width: '100%', fontSize: '1rem', height: '28px' }}>
      {props.propsDad.city && props.propsDad.city.map((departamento, index) => (
        <option key={index} value={departamento.nombre}>
          {departamento.nombre}
        </option>
      ))}
    </select>
  </Grid>
</Grid>
<Grid container spacing={3}>
    <Grid item xs={12} sm={12} lg={5} xl={5} container justifyContent='flex-start'>
        <label className='labelBold' >Dirección:</label>
        <TextField style={{ marginBottom: '0.5rem', marginTop: '0.5rem' }} className='field' type='text' 
        fullWidth id="PatientAddress"  variant="outlined" inputProps={{ maxLength: 50 }} 
        value={formPatient.PatientAddress}
        onChange={handleChangePatient}/>
    </Grid>

    <Grid item xs={12} sm={12} lg={4} xl={4} container justifyContent='flex-start'>
        <label className='labelBold' >Barrio:</label>
        <TextField style={{ marginBottom: '0.5rem', marginTop: '0.5rem' }} className='field' type='text' 
        fullWidth id="PatientNeight"  variant="outlined" inputProps={{ maxLength: 50 }} 
        value={formPatient.PatientNeight}
        onChange={handleChangePatient}/>
    </Grid>

  <Grid item xs={12} sm={12} lg={3} xl={3} container alignItems='center'>
    <label className='labelBold'>Localidad:</label>
    <select value={selectedDepartamento} 
    id="companyDepartament" onChange={handleChangeDepartamento} 
    style={{ width: '100%', fontSize: '1rem', height: '28px' }}>
      {props.propsDad.city && props.propsDad.city.map((departamento, index) => (
        <option key={index} value={departamento.nombre}>
          {departamento.nombre}
        </option>
      ))}
    </select>
  </Grid>
</Grid>

<Grid container spacing={4}>
<Grid item xs={12} sm={12} lg={5} xl={5} container alignItems='center'>
<TextField
  label="Teléfono (1):" className='field'
  variant="outlined"
  value={formPatient.PatientTel1}
  name="PatientTel1" // Esto es importante para que `handleChange` funcione bien
  onChange={handleChange}
  InputProps={{
    startAdornment: (
      <InputAdornment position="start">
        <PhoneIcon />
      </InputAdornment>
    ),
  }}
  InputLabelProps={{
    shrink: true, // Asegura que el label permanezca arriba
    sx: { fontSize: '1.5rem', fontWeight: 'bold' }, // Tamaño y peso de la fuente
  }}
/>
</Grid>
<Grid item xs={12} sm={12} lg={4} xl={4} container alignItems='center'>
<TextField
  label="Teléfono (2):"
  variant="outlined"
  value={formPatient.PatientTel2}
  name="PatientTel2" // Esto es importante para que `handleChange` funcione bien
  onChange={handleChange}
  InputProps={{
    startAdornment: (
      <InputAdornment position="start">
        <PhoneIcon />
      </InputAdornment>
    ),
  }}
  InputLabelProps={{
    shrink: true, // Asegura que el label permanezca arriba
    sx: { fontSize: '1.5rem', fontWeight: 'bold' }, // Tamaño y peso de la fuente
  }}
/>
</Grid>
  <Grid item xs={12} sm={12} lg={3} xl={3} container alignItems='center'>
    <label className='labelBold'>Tipo:</label>
    <select value={selectedDepartamento} 
    id="companyDepartament" onChange={handleChangeDepartamento} 
    style={{ width: '100%', fontSize: '1rem', height: '28px' }}>
      {props.propsDad.city && props.propsDad.city.map((departamento, index) => (
        <option key={index} value={departamento.nombre}>
          {departamento.nombre}
        </option>
      ))}
    </select>
  </Grid>
</Grid>



  
</>
)}


{tabIndex===10 && (
  <>
  {/* TLA nuevo */}
  {/* <Grid container spacing={1}>
  <Grid item xs={12} sm={12} lg={12} xl={12} container justifyContent='flex-start'>
  <label className='labelBold' >Institución:</label>
  <TextField style={{ marginBottom: '0.5rem' }} className='field' fullWidth id="PatientCompany"  variant="outlined" 
    value={formPatient.PatientCompany}
    onChange={handleChangePatient}/>
  </Grid>
</Grid>   */}
<Grid container spacing={1}>
    <Grid item xs={12} sm={12} lg={12} xl={12} container  justifyContent='flex-start' alignItems='center'>
    {/* <IconButton aria-label="Borrar" size="large" onClick={handleChangeSwitch4}>
      <AddCircleOutlineTwoToneIcon color="rgb(0, 166, 206)" fontSize="large" />
    </IconButton> */}

    <Fab color="success" size="small" aria-label="add" onClick={handleChangeSwitch4}>
        <AddIcon color="rgb(0, 166, 206)" />
    </Fab>
    <label className='labelBold' >Filiaciones:</label>

  </Grid>
  </Grid>

 
  <Grid container justifyContent="center" alignItems="center" spacing={1}>
      <Grid item xs={12}>
        <Paper elevation={3}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Número</TableCell>
                <TableCell>Institución</TableCell>
                <TableCell>Servicio</TableCell>
                <TableCell>Departamento</TableCell>
                <TableCell>Ciudad</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {temporalFiliations.length > 0 ? (
                temporalFiliations.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell style={{ width: '10%' }}>{index}</TableCell>
                    <TableCell style={{ width: '40%' }}>{item.companyName}</TableCell>
                    <TableCell style={{ width: '40%' }}>{item.companyService}</TableCell>
                    <TableCell style={{ width: '40%' }}>{item.companyDepartament}</TableCell>
                    <TableCell style={{ width: '40%' }}>{item.companyCity}</TableCell>
                    <TableCell style={{ width: '10%' }}>
                    <IconButton aria-label="Borrar" size="small" onClick={() => eliminarFiliacion(index)}>
                      <DeleteIcon color="secondary" fontSize="large" />
                    </IconButton>

                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} align="center">No se han agredado Filiaciones</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
    </Grid>
  </>
)}



</Box>




  




</DialogContent>
<DialogActions style={{ justifyContent: 'center', margin: '1rem', marginTop: 0 }}>
<Button style={{ width: '100px' }} className='btnPrimary' onClick={() => {
  // setHeadQuarterChs(saveHeadquarterChs)
  // setLocationChs(saveLocationChs)
 setOpen4(false);
 navigate('/');
 window.location.href = '/';
}} color="primary">
 Cancelar
</Button>
<Button style={{ width: '100px' }} className='btnPrimary' 
onClick={agregarPatient} 
color="primary">
 Guardar
</Button>

<Button style={{ width: '100px' }} className='btnPrimary' 
onClick={handleChangeSwitch2} 
color="primary">
 Registro
</Button>


</DialogActions>
</Dialog>

);
}




// TLA nuevo
const handleFile1Change = (e) => {
  setSelectedFile1(e.target.files[0]);  // Almacena el archivo seleccionado en el estado
};
const handleFile2Change = (e) => {
  setSelectedFile2(e.target.files[0]);  // Almacena el archivo seleccionado en el estado
};
const handleFile3Change = (e) => {
  setSelectedFile3(e.target.files[0]);  // Almacena el archivo seleccionado en el estado
};
const handleFile4Change = (e) => {
  setSelectedFile4(e.target.files[0]);  // Almacena el archivo seleccionado en el estado
};

const addInscription = async () =>{
      downloadPdf();
      const formData = new FormData();
      formData.append('pendienteId',props.propsDad.pendienteId);
      formData.append('idUser',props.user.user_susi_id);
      formData.append('articlePdf', pdfFile);
      formData.append('summaryPdf', pdfFile1);
      console.log("cierra inscripción");
      await closeInscription(formData);   
      //ENVIAR CORREO
      let email = window.sessionStorage.getItem('_displayMail');
      let params = {
      subject : ''.toString('utf8'),
      emailEnd: email,
      userId  : props.user.user_susi_id, 
      contenido: '',
      // inscriptionId: dataInscription['inscriptionId'];
      }
      let result = await sendMail(params)
      setTitleAlert('SOPORTE DE INSCRIPCIÓN');
      setMessageAlert('Ha sido enviado a su correo registrado el soporte de inscripción. Favor verifica');
      handleChangeStatus();
      setTemporalFiliations([]);
      setTemporalPatients([]);
      setOpenPrint(false)
      setOpen3(false);
      if(props.propsDad.pendienteId){
        this.props.history.push({
          pathname: '/toreport'
        });  
        
      }
}

const updateState = (newState) => {
  return new Promise((resolve) => {
    setState((prevState) => {
      const updatedState = { ...prevState, ...newState };
      resolve(updatedState);
      return updatedState;
    });
  });
};

const addRegister = async () => {
  console.log("STATE EN GUARDAR");
  console.log(state);
  const formData = new FormData();
  const PatientsObject = temporalPatients.reduce((acc, Patient) => {
    acc[Patient.PatientId] = Patient;
    return acc;
  }, {});

  setState({
    ...state,
    articlePatients: PatientsObject, // Si articlePatients es un arreglo con un solo objeto, usa corchetes para crear un nuevo arreglo con temporalPatients
  })
  const updatedState = await updateState({ articlePatients: PatientsObject });

  console.log("AUTORES");
  console.log(temporalPatients);
  console.log(PatientsObject);
  console.log(updatedState);
  // }
    // Agrega archivos al FormData
  formData.append('articleImage1', selectedFile1 ? selectedFile1 : null);
  formData.append('articleImage2', selectedFile2 ? selectedFile2 : null);
  formData.append('articleCarta' , selectedFile3 ? selectedFile3 : null);
  formData.append('articleEthic' , selectedFile4 ? selectedFile4 : null);

// Descargar el archivo y convertirlo en un Blob
//const blob = await (await fetch(state.pdfUrl)).blob();
//const blob1 = await (await fetch(state.pdfUrl1)).blob();
// Crear el objeto File a partir del Blob
//const pdfFile  = new File([blob], 'articlePdf.pdf', { type: 'application/pdf' });
//const pdfFile1 = new File([blob1], 'summaryPdf.pdf', { type: 'application/pdf' });
//  const pdfFile  = new File([await (await fetch(state.pdfUrl)).blob()], 'articlePdf.pdf', { type: 'application/pdf' });
//  const pdfFile1 = new File([await (await fetch(state.pdfUrl1)).blob()], 'summaryPdf.pdf', { type: 'application/pdf' });
  formData.append('pendienteId',props.propsDad.pendienteId);
  formData.append('articlePdf', pdfFile);
  formData.append('summaryPdf', pdfFile1);
  //formData.append('competitionId', updatedState.competitionId);
  formData.append('presentationId', updatedState.presentationId);
  formData.append('categoryId', state.categoryId);
  formData.append('thematicAreaId', thematic);
  formData.append('idUser',props.user.user_susi_id);
  formData.append('memberIdcard',      updatedState.memberIdcard);
  formData.append('articleSubmitted',  updatedState.articleSubmitted);
  formData.append('articleSubmittedWhere',  updatedState.articleSubmittedWhere);
  formData.append('articleTitle',      updatedState.articleTitle);
  formData.append('articleShortTitle', updatedState.articleShortTitle);
  formData.append('articleKeyword1',   updatedState.articleKeyword1);
  formData.append('articleKeyword2',   updatedState.articleKeyword2);
  formData.append('articleKeyword3',   updatedState.articleKeyword3);
  formData.append('articleKeyword4',   updatedState.articleKeyword4);
  formData.append('articleKeyword5',   updatedState.articleKeyword5);
  formData.append('articlePatients',    JSON.stringify(updatedState.articlePatients));
  formData.append('articleObjetive',   updatedState.articleObjetive);
  formData.append('articleMethod',     updatedState.articleMethod);
  formData.append('articleResult',     updatedState.articleResult);
  formData.append('articleConclusion', updatedState.articleConclusion);
  formData.append('articleSubmitted',  updatedState.articleSubmitted);
  formData.append('articleSubmittedWhere', updatedState.articleSubmittedWhere);
  formData.append('pathfiles', process.env.PUBLIC_URL + DOCUMENTS_FOLDER);
  formData.append('inscriptionId', updatedState.inscriptionId);

  console.log("formdata");
  console.log(formData);
  const newRegister = {
    competitionId  :   updatedState.competitionId,
    presentationId :   updatedState.presentationId,
    categoryId     :   state.categoryId,
    thematicAreaId :   thematic,
    idUser         :   props.user.user_susi_id,
    memberIdcard:      updatedState.memberIdcard,
    articleSubmitted: updatedState.articleSubmitted,
    articleSubmittedWhere: updatedState.articleSubmittedWhere,
    articleTitle:      updatedState.articleTitle,
    articleShortTitle: updatedState.articleShortTitle,
    articleKeyword1:   updatedState.articleKeyword1,
    articleKeyword2:   updatedState.articleKeyword2,
    articleKeyword3:   updatedState.articleKeyword3,
    articleKeyword4:   updatedState.articleKeyword4,
    articleKeyword5:   updatedState.articleKeyword5,
    articlePatients:    temporalPatients,
    articleObjetive:   updatedState.articleObjetive,
    articleMethod:     updatedState.articleMethod,
    articleResult:     updatedState.articleResult,
    articleConclusion: updatedState.articleConclusion,
    articleImage1:     selectedFile1 && selectedFile1.name ? selectedFile1.name : null,
    articleImage2:     selectedFile2 && selectedFile2.name ? selectedFile2.name : null,
    articleCarta :     selectedFile3 && selectedFile3.name ? selectedFile3.name : null,
    articleEthic:      selectedFile4 && selectedFile4.name ? selectedFile4.name : null,
    
  };

  let errors = [];

  if (!newRegister.memberIdcard) {
    errors.push('Sin identificación del miembro ACHO .');
  }
  if (!newRegister.categoryId) {
    errors.push('No ha seleccionado categoría .');
  }  
  if (!newRegister.articleTitle) {
    errors.push('Sin título del trabajo a inscribir');
  }
  if (!newRegister.articleShortTitle) {
    errors.push('Sin título corto del trabajo a inscribir');
  }
  if (!newRegister.articleKeyword1 & !newRegister.articleKeyword2 & !newRegister.articleKeyword3
     & !newRegister.articleKeyword4 & !newRegister.articleKeyword5) {
    errors.push('No hay ninguna palabra clave');
  }

  if (!newRegister.articleObjetive) {
    errors.push('No ha incluido objetivos');
  }
  if (!newRegister.articleMethod) {
    errors.push('No ha incluido la sección de materiales y métodos');
  }
  if (!newRegister.articleResult) {
    errors.push('No se incluyo resultados');
  }
  if (!newRegister.articleConclusion) {
    errors.push('No se incluyo conclusiones');
  }

  if (temporalPatients.length < 1){
    errors.push('No se ha registrado ningun Patient');
  }

  if (errors.length == 0 ) {  
      console.log("INSCRIPCION");
      console.log(newRegister);
      setTerminar(true);

//Graba el registro      TLA nuevo graba
     formData.append('inscriptionState', 0); 
      let dataInscription= await createInscription(formData)   
      if (dataInscription) {
         console.log("registro actualizado");
         console.log(dataInscription);
         state.inscriptionId = dataInscription.inscriptionId;
      }  
      toggleDialogPrint();

  } else {
    state.inscriptionState=0;
    setTerminar(false);
    formData.append('inscriptionState', 0);
    await createInscription(formData);
    toggleDialogPrint();
    // Concatenar todos los errores en una sola cadena de texto
    const errorMessage = errors.join('\n');
    
    // Mostrar mensaje de alerta con todos los errores
    //alert(errorMessage);
    setTitleAlert('VALIDACIÓN INSCRIPCIÓN');
    setMessageAlert(errorMessage);
    handleChangeStatus();
    setIsPatientValid(false);
  }
  
};


const verificarRegister = async () => {

  let errors = [];

  if (!state.memberIdcard) {
    errors.push('Sin identificación del miembro ACHO .');
  }
  if (!state.articleTitle) {
    errors.push('Sin título del trabajo a inscribir');
  }
  if (!state.articleShortTitle) {
    errors.push('Sin título corto del trabajo a inscribir');
  }
  if (!state.articleKeyword1 & !state.articleKeyword2 & !state.articleKeyword3
     & !state.articleKeyword4 & !state.articleKeyword5) {
    errors.push('No hay ninguna palabra clave');
  }

  if (!state.articleObjetive) {
    errors.push('No ha incluido objetivos');
  }
  if (!state.articleMethod) {
    errors.push('No ha incluido la sección de materiales y métodos');
  }
  if (!state.articleResult) {
    errors.push('No se incluyo resultados');
  }
  if (!state.articleConclusion) {
    errors.push('No se incluyo conclusiones');
  }

  if (temporalPatients.length < 1){
    errors.push('No se ha registrado ningun Patient');
  }

  if (!state.presentationId){
    errors.push('No se ha registrado en que va a participar');
  }

  if (!state.categoryId){
    errors.push('No se ha registrado ninguna categoría');
  }

  if (errors.length > 0 ) {  
    // Concatenar todos los errores en una sola cadena de texto
    const errorMessage = errors.join('\n');
    //alert(errorMessage);
    setTitleAlert('VALIDACIÓN INSCRIPCIÓN');
    setMessageAlert(errorMessage);
    handleChangeStatus();
    setIsPatientValid(false);
  }
  toggleDialogPrint();
};


const handleCheckboxChange = (PatientId) => {
  const updatedCheckedPatients = { ...checkedPatients, [PatientId]: !checkedPatients[PatientId] };
  setCheckedPatients(updatedCheckedPatients);

  const updatedPatients = temporalPatients.map(Patient => {
    if (Patient.PatientId == PatientId) {
      return { ...Patient, PatientRole: !checkedPatients[PatientId] };
    }
    return Patient;
  });
  setTemporalPatients(updatedPatients);
};

const handleReceiveMailChange = (PatientId) => {
  const updatedCheckedReceiveMail = { ...checkedReceiveMail, [PatientId]: !checkedReceiveMail[PatientId] };
  setCheckedReceiveMail(updatedCheckedReceiveMail);

  const updatedPatients = temporalPatients.map(Patient => {
    if (Patient.PatientId == PatientId) {
      return { ...Patient, PatientReceiveMail: !checkedReceiveMail[PatientId] };
    }
    return Patient;
  });
  setTemporalPatients(updatedPatients);
};

const handleSpeakerChange = (PatientId) => {
  const updatedCheckedSpeaker = { ...checkedSpeaker, [PatientId]: !checkedSpeaker[PatientId] };
  setCheckedSpeaker(updatedCheckedSpeaker);

  const updatedPatients = temporalPatients.map(Patient => {
    if (Patient.PatientId == PatientId) {
      return { ...Patient, PatientSpeaker: !checkedSpeaker[PatientId] };
    }
    return Patient;
  });
  setTemporalPatients(updatedPatients);
};



// TLA nuevo registro
const renderDialogRegister = () => {


const today = dayjs();
const yesterday = dayjs().subtract(1, 'day');
const todayStartOfTheDay = today.startOf('day');
  const handleOptionChange = (event, value) => {
    setFormPatient((prevState) => ({
      ...prevState,           
      PatientActeconomic : value,
    }));
  };
  const handleOption1Change = (event, value) => {
    setFormPatient((prevState) => ({
      ...prevState,           
      PatientOcupation : value,
    }));
  };
 

return (

<div>
<Dialog  
  PaperProps={{
    style: {
      paddingBottom: '0 !important',
      //overflowY: 'auto',
      maxHeight: '97vh',
      width: '80vw',          // Ancho al 100% de la ventana del navegador
      maxWidth: 'none !important',  // Sin límite de ancho máximo
      opacity: '1 !important',              // Desactivar la opacidad
      scrollbarWidth: 'auto',  // Ancho del scrollbar
      scrollbarColor: '#742239 #f7f6fa',  // Color del scrollbar y de su thumb
    }
  }} 
  fullWidth={false}
  maxWidth={false}
  className='dialogReounded dialogUbication' 
  open={open3} 
  aria-labelledby="form-dialog-title"
>
{/* <DialogTitle className='dialogTttle !important' id="alert-dialog-title" style={{ fontSize:'40px', textAlign:'center' }}>FORMULARIO DE INSCRIPCIÓN {' '.repeat(10)}(PALABRAS={totalPalabras})</DialogTitle> */}
<DialogTitle className='dialogTitle !important' 
id="alert-dialog-title" style={{ display: 'flex', justifyContent: 'center', 
  color: green, alignItems:'center'}}>
      REGISTRO Historia Clínica:  
      <span style={{ marginLeft: '5px', color: green }}> 
        Número SAP:
        Apellidos:                   Nombres:
      </span>
</DialogTitle>
<DialogContent style={{ paddingBottom: '0 !important', overflowY: 'hidden', maxHeight: '80vh'  }}>

      <Tabs value={tabIndex0} onChange={handleTabChange0} centered
      TabIndicatorProps={{ style: { backgroundColor: '#4caf50' } }}
      classes={{ indicator: classes.indicator }}>
        <Tab label="Diagnóstico"     className={classes.tab}/>
        <Tab label="Tratamiento"     className={classes.tab}/>
        <Tab label="Marcadores"      className={classes.tab}/>
      </Tabs>

{tabIndex0 === 0 && (
<>
  <Grid container spacing={1}>
  <FormControl component="fieldset">
      <FormLabel component="legend">Tipo de tumor</FormLabel>
      <RadioGroup value={selectedValue} onChange={handleChange}>
        <FormControlLabel value="Benigno" control={<Radio />} label="Benigno" />
        <FormControlLabel value="Maligno" control={<Radio />} label="Maligno" />
      </RadioGroup>
    </FormControl>


    <Grid container spacing={1}>
    <Grid item xs={12} sm={12} lg={2} xl={2} container alignItems='center'>
        <label className='labelBold' htmlFor="memberIdcard">Secuencia:</label>
        <TextField className='field' type='number' fullWidth 
            id="memberIdcard"  name="memberIdcard" 
            value={state.memberIdcard} 
            onChange={handleChange} 
            style={{ width: '100%' }} 
            variant="outlined"  inputProps={{ maxLength: 14 }} />
    </Grid>
      <Grid item xs={12} sm={12} lg={6} xl={6} container alignItems='center'>
        <label className='labelBold'>Clasificación :</label>
        <select value={selectedDepartamento} 
        id="companyDepartament" onChange={handleChangeDepartamento} 
        style={{ width: '100%', fontSize: '1rem', height: '28px' }}>
          {props.propsDad.city && props.propsDad.city.map((departamento, index) => (
            <option key={index} value={departamento.nombre}>
              {departamento.nombre}
            </option>
          ))}
        </select>
      </Grid>
<Grid item xs={12} sm={6} lg={4} xl={4} container justifyContent='flex-start'>
<LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer
        components={[
          'DatePicker',
          'DateTimePicker',
          'TimePicker',
          'DateRangePicker',
          'DateTimeRangePicker',
        ]}
      >
        <DemoItem label="Fecha de Ingreso">
          <DatePicker
            defaultValue={yesterday}
            disablePast
            views={['year', 'month', 'day']}
          />
        </DemoItem>
      </DemoContainer>
    </LocalizationProvider>
  </Grid>
</Grid>
<Grid item xs={12} sm={12} lg={12} xl={12} container justifyContent='flex-start'>
<Grid item xs={12} sm={6} lg={4} xl={4} container justifyContent='flex-start'>
<LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer
        components={[
          'DatePicker',
          'DateTimePicker',
          'TimePicker',
          'DateRangePicker',
          'DateTimeRangePicker',
        ]}
      >
        <DemoItem label="Fecha de Diagnóstico">
          <DatePicker
            defaultValue={yesterday}
            disablePast
            views={['year', 'month', 'day']}
          />
        </DemoItem>
      </DemoContainer>
    </LocalizationProvider>
</Grid>
<Grid item xs={12} sm={6} lg={4} xl={4} container justifyContent='flex-start'>
<Box display="flex" alignItems="center">
     <label className="labelBold" style={{ marginRight: '0.5rem' }}>Edad al diagnóstico:</label>
  <TextField style={{ marginBottom: '0.5rem', marginTop: '0.5rem' }} className='field'
   fullWidth id="PatientAge"  variant="outlined" inputProps={{ maxLength: 3 }}
    value={formPatient.PatientAge}
    onChange={handleChangePatient}/>
     <label className="labelBold" style={{ marginRight: '0.5rem' }}>años</label>
</Box>
</Grid>
<Grid item xs={12} sm={6} lg={4} xl={4} container justifyContent='flex-start'>
<label className="labelBold" style={{ marginRight: '0.5rem' }}>Municipio:</label>
<select value={selectedDepartamento} 
    id="Sex" onChange={handleChangeDepartamento} 
    style={{ width: '100%', fontSize: '1rem', height: '28px' }}>
      {props.propsDad.sex && props.propsDad.sex.map((sex, index) => (
        <option key={index} value={sex.sexo}>
          {sex.sexo}
        </option>
      ))}
    </select>
</Grid>
</Grid>

<Grid container direction="column" spacing={3}>
  <Grid item>
    {/* Primer Autocomplete */}
    <Autocomplete
      options={props.propsDad.epsars?.data || []}
      getOptionLabel={(option) => option.nombre}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={formPatient.PatientEps}
      onChange={handleOptionChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Seleccionar Entidad Promotora de Salud (EPS)"
          variant="outlined"
          className="field"
          InputLabelProps={{
            shrink: true,
            style: { fontSize: '1.2rem' },
          }}
          sx={{
            "& .MuiInputLabel-root": { fontSize: '1.4rem !important' },
            "& .MuiInputBase-input": { fontSize: '1rem !important' },
            mt: 3,
          }}
        />
      )}
    />
  </Grid>
  
  <Grid item>
    {/* Segundo Autocomplete */}
    <Autocomplete
      options={props.propsDad.ips?.data || []}
      getOptionLabel={(option) => option.nombre}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={formPatient.PatientIps}
      onChange={handleOption1Change}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Seleccionar Institución Prestadora de Servicios de Salud (IPS)"
          variant="outlined"
          className="field"
          InputLabelProps={{
            shrink: true,
            style: { fontSize: '1.2rem' },
          }}
          sx={{
            "& .MuiInputLabel-root": { fontSize: '1.4rem !important' },
            "& .MuiInputBase-input": { fontSize: '1rem !important' },
          }}
        />
      )}
    />
  </Grid>
</Grid>
<Grid container spacing={2}>
  <Grid item xs={12} sm={6} lg={8} xl={8} container justifyContent="flex-start">
    <Autocomplete
      options={props.propsDad.ips?.data || []}
      getOptionLabel={(option) => option.nombre}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={formPatient.PatientIps}
      onChange={handleOptionChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Seleccionar Topográfico"
          variant="outlined"
          //fullWidth // Hace que ocupe el ancho completo del espacio asignado
          InputLabelProps={{
            shrink: true,
            style: { fontSize: '1.2rem' },
          }}
          sx={{
            "& .MuiInputLabel-root": { fontSize: '1.4rem !important' },
            "& .MuiInputBase-input": { fontSize: '1rem !important' },
            mt: 3,
          }}
        />
      )}
    />
  </Grid>
  <Grid item xs={12} sm={6} lg={4} xl={4} container justifyContent="flex-start">
    <label className="labelBold">Lateralidad :</label>
    <select
      value={selectedDepartamento}
      id="companyDepartament"
      onChange={handleChangeDepartamento}
      style={{ width: '100%', fontSize: '1rem', height: '28px', marginTop: '1rem' }}
    >
      {props.propsDad.city &&
        props.propsDad.city.map((departamento, index) => (
          <option key={index} value={departamento.nombre}>
            {departamento.nombre}
          </option>
        ))}
    </select>
  </Grid>
</Grid>


    <Grid item xs={12} sm={12} lg={12} xl={12}>
            <label className='labelBold' htmlFor="memberIdcard">Descripción Topográfico:</label>
              <TextField className='field' type='number' fullWidth 
                id="memberIdcard"  name="memberIdcard" 
                value={state.memberIdcard} 
                onChange={handleChange} 
                style={{ width: '100%' }} 
                variant="outlined"  inputProps={{ maxLength: 14 }} />
    </Grid>

<Grid container spacing={2}>
  <Grid item xs={12} sm={6} lg={8} xl={8} container justifyContent="flex-start">
    <Autocomplete
      options={props.propsDad.ips?.data || []}
      getOptionLabel={(option) => option.nombre}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={formPatient.PatientIps}
      onChange={handleOptionChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Seleccionar Histológico"
          variant="outlined"
          //fullWidth // Hace que ocupe el ancho completo del espacio asignado
          InputLabelProps={{
            shrink: true,
            style: { fontSize: '1.2rem' },
          }}
          sx={{
            "& .MuiInputLabel-root": { fontSize: '1.4rem !important' },
            "& .MuiInputBase-input": { fontSize: '1rem !important' },
            mt: 3,
          }}
        />
      )}
    />
  </Grid>
    <Grid item xs={12} sm={12} lg={4} xl={4} container alignItems='center'>
            <label className='labelBold'>CIE10:</label>
    </Grid>
</Grid>

<Grid container spacing={2}>
<Grid item xs={12} sm={12} lg={12} xl={12}>
        <label className='labelBold' htmlFor="memberIdcard">Descripción histológico:</label>
      </Grid>
      <Grid item xs={12} sm={3}>
          <TextField className='field' type='text' fullWidth 
            id="memberIdcard"  name="memberIdcard" 
            value={state.memberIdcard} 
            onChange={handleChange} 
            style={{ width: '100%' }} 
            variant="outlined"  inputProps={{ maxLength: 14 }} />
      </Grid>
</Grid>
<Grid item xs={12} sm={12} lg={12} xl={12} container alignItems='center'>
   <Grid item xs={12} sm={12} lg={3} xl={3} container alignItems='center'></Grid>
        <label className='labelBold'>Diferenciación:</label>
        <select value={selectedDepartamento} 
        id="companyDepartament" onChange={handleChangeDepartamento} 
        style={{ width: '100%', fontSize: '1rem', height: '28px' }}>
          {props.propsDad.city && props.propsDad.city.map((departamento, index) => (
            <option key={index} value={departamento.nombre}>
              {departamento.nombre}
            </option>
          ))}
        </select>
    </Grid>

      <Grid item xs={12} sm={12} lg={3} xl={3} container alignItems='center'>
        <label className='labelBold'>Base válida para Diagnóstico:</label>
        <select value={selectedDepartamento} 
        id="companyDepartament" onChange={handleChangeDepartamento} 
        style={{ width: '100%', fontSize: '1rem', height: '28px' }}>
          {props.propsDad.city && props.propsDad.city.map((departamento, index) => (
            <option key={index} value={departamento.nombre}>
              {departamento.nombre}
            </option>
          ))}
        </select>
      </Grid>

      <Grid item xs={12} sm={12} lg={3} xl={3} container alignItems='center'>
        <label className='labelBold'>Estadio clínico:</label>
        <select value={selectedDepartamento} 
        id="companyDepartament" onChange={handleChangeDepartamento} 
        style={{ width: '100%', fontSize: '1rem', height: '28px' }}>
          {props.propsDad.city && props.propsDad.city.map((departamento, index) => (
            <option key={index} value={departamento.nombre}>
              {departamento.nombre}
            </option>
          ))}
        </select>
      </Grid>

      <Grid item xs={12} sm={12} lg={3} xl={3}>
        <label className='labelBold' htmlFor="memberIdcard">Número de protocolo:</label>
          <TextField className='field' type='number' fullWidth 
            id="memberIdcard"  name="memberIdcard" 
            value={state.memberIdcard} 
            onChange={handleChange} 
            style={{ width: '100%' }} 
            variant="outlined"  inputProps={{ maxLength: 14 }} />
      </Grid>
</Grid>
<Grid item xs={12} sm={12} lg={12} xl={12} container alignItems='center'>
      <Grid item xs={12} sm={12} lg={3} xl={3} container alignItems='center'>
        <label className='labelBold'>T:</label>
        <select value={selectedDepartamento} 
        id="companyDepartament" onChange={handleChangeDepartamento} 
        style={{ width: '100%', fontSize: '1rem', height: '28px' }}>
          {props.propsDad.city && props.propsDad.city.map((departamento, index) => (
            <option key={index} value={departamento.nombre}>
              {departamento.nombre}
            </option>
          ))}
        </select>
      </Grid>
            <Grid item xs={12} sm={12} lg={3} xl={3} container alignItems='center'>
        <label className='labelBold'>Metástasis (1):</label>
        <select value={selectedDepartamento} 
        id="companyDepartament" onChange={handleChangeDepartamento} 
        style={{ width: '100%', fontSize: '1rem', height: '28px' }}>
          {props.propsDad.city && props.propsDad.city.map((departamento, index) => (
            <option key={index} value={departamento.nombre}>
              {departamento.nombre}
            </option>
          ))}
        </select>
      </Grid>
      <Grid item xs={12} sm={12} lg={3} xl={3} container alignItems='center'>
        <label className='labelBold'>Tipo:</label>
        <select value={selectedDepartamento} 
        id="companyDepartament" onChange={handleChangeDepartamento} 
        style={{ width: '100%', fontSize: '1rem', height: '28px' }}>
          {props.propsDad.city && props.propsDad.city.map((departamento, index) => (
            <option key={index} value={departamento.nombre}>
              {departamento.nombre}
            </option>
          ))}
        </select>
      </Grid>

</Grid>

<Grid item xs={12} sm={12} lg={12} xl={12} container alignItems='center'>
      <Grid item xs={12} sm={12} lg={3} xl={3} container alignItems='center'>
        <label className='labelBold'>N:</label>
        <select value={selectedDepartamento} 
        id="companyDepartament" onChange={handleChangeDepartamento} 
        style={{ width: '100%', fontSize: '1rem', height: '28px' }}>
          {props.propsDad.city && props.propsDad.city.map((departamento, index) => (
            <option key={index} value={departamento.nombre}>
              {departamento.nombre}
            </option>
          ))}
        </select>
      </Grid>
            <Grid item xs={12} sm={12} lg={3} xl={3} container alignItems='center'>
        <label className='labelBold'>Metástasis (2):</label>
        <select value={selectedDepartamento} 
        id="companyDepartament" onChange={handleChangeDepartamento} 
        style={{ width: '100%', fontSize: '1rem', height: '28px' }}>
          {props.propsDad.city && props.propsDad.city.map((departamento, index) => (
            <option key={index} value={departamento.nombre}>
              {departamento.nombre}
            </option>
          ))}
        </select>
      </Grid>

</Grid>

<Grid item xs={12} sm={12} lg={12} xl={12} container alignItems='center'>
      <Grid item xs={12} sm={12} lg={3} xl={3} container alignItems='center'>
        <label className='labelBold'>M:</label>
        <select value={selectedDepartamento} 
        id="companyDepartament" onChange={handleChangeDepartamento} 
        style={{ width: '100%', fontSize: '1rem', height: '28px' }}>
          {props.propsDad.city && props.propsDad.city.map((departamento, index) => (
            <option key={index} value={departamento.nombre}>
              {departamento.nombre}
            </option>
          ))}
        </select>
      </Grid>
            <Grid item xs={12} sm={12} lg={3} xl={3} container alignItems='center'>
        <label className='labelBold'>Metástasis (3):</label>
        <select value={selectedDepartamento} 
        id="companyDepartament" onChange={handleChangeDepartamento} 
        style={{ width: '100%', fontSize: '1rem', height: '28px' }}>
          {props.propsDad.city && props.propsDad.city.map((departamento, index) => (
            <option key={index} value={departamento.nombre}>
              {departamento.nombre}
            </option>
          ))}
        </select>
      </Grid>
</Grid>




</>
)}


{(
  <>

{tabIndex0 === 1 && (
<>

<Grid container spacing={1}>
        <Grid item xs={12} sm lg xl container direction='column' justifyContent='center'>
            <label className='labelBold'>Tratamiento: </label>
            <RadioGroup aria-label="Speakerdo" name="articleSubmitted" value={state.articleSubmitted} onChange={handleArticleSubmitted}>
                  {yesnot &&  yesnot.map((articleSubmitted) => (
                    <FormControlLabel
                      key={articleSubmitted.id}
                      value={articleSubmitted.id}
                      control={<BlueRadio />}
                      checked={state.articleSubmitted == articleSubmitted.id}
                      label={articleSubmitted.label} />
                  ))}
           </RadioGroup>
        </Grid>
</Grid>

{state.articleSubmitted == 1 ? (
 <> 

<Grid container spacing={1}>
    <Grid item xs={12} sm={12} lg={12} xl={12} container justifyContent='flex-start'>
    <label className='labelBold' >En donde se presentó anteriormente:</label>
    </Grid>
    <textarea 
        style={{ marginBottom: '0.5rem', width: '100%', padding: '0.5rem', boxSizing: 'border-box', resize: 'vertical' }}
        className='field' 
        id="articleSubmittedWhere" name="articleSubmittedWhere"
        onChange={handleChange}
        // placeholder="Escribe el titulo del trabajo..."
        rows={1}
        cols={50}
    ></textarea>
</Grid>

{/* <div className={classes.container}>
<Typography className={classes.label}>Genera viáticos</Typography>
<FormControlLabel
  control={
    <Switch
      {...register("generaViaticos")}
      checked={generaViaticosLbl.checked}
      onClick={handleGeneraViaticos}
    />
  }
  label={generaViaticosLbl.label}
/>
</div> */}

{/* <div className={classes.container}>
<Typography className={classes.label}>Genera viáticos</Typography>
<FormControlLabel
  control={
    <Switch
      {...register("generaViaticos")}
      checked={generaViaticosLbl.checked}
      onClick={handleGeneraViaticos}
    />
  }
  label={generaViaticosLbl.label}
/>
</div> */}

<Autocomplete
      options={options}
      value={formPatient.PatientOcupation}
      onChange={handleChangePatient}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Tipo:"
          variant="outlined"
        />
      )}
      filterSelectedOptions
/>





</>


):


<Autocomplete
      options={options}
      value={formPatient.PatientOcupation}
      onChange={handleChangePatient}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Tipo:"
          variant="outlined"
        />
      )}
      filterSelectedOptions
/>


}



</>
)}


{tabIndex0===2 && (
<>
{/* TLA nuevo */}
<Grid container spacing={3}>
    <Grid item xs={12} sm={12} lg={12} xl={12} container justifyContent='flex-start' alignItems='center'>
      <Fab color="success" size="small" aria-label="add" onClick={handleChangeSwitch4}>
        <AddIcon color="rgb(0, 166, 206)" />
      </Fab>
      <label className='labelBold' >Marcadores:</label>

  </Grid>
 </Grid>

 
  <Grid container justifyContent="center" alignItems="center" spacing={3}>
      <Grid item xs={12}>
        <Paper elevation={3}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Tipo de cáncer</TableCell>
                <TableCell>Marcador</TableCell>
                <TableCell>Resultado</TableCell>
                <TableCell>Valor</TableCell>
                <TableCell>Porcentaje</TableCell>
                {/* <TableCell>Actions</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
            {temporalPatients.length > 0 ? (
                temporalPatients.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.PatientId}</TableCell>
                    <TableCell>{item.PatientName}</TableCell>
                    <TableCell>{item.PatientLastname}</TableCell>
                    <TableCell>{item.PatientMobile}</TableCell>
                    <TableCell>{item.PatientEmail}</TableCell>
                    {/* <TableCell>{item.PatientCompany}</TableCell> */}
                    <TableCell>
                        <Checkbox color="success"
                          checked={checkedPatients[item.PatientId] || false}
                          onChange={() => handleCheckboxChange(item.PatientId)}
                        />
                    </TableCell>
                    <TableCell>
                        <Checkbox color="success"
                          checked={checkedReceiveMail[item.PatientId] || false}
                          onChange={() => handleReceiveMailChange(item.PatientId)}
                        />
                    </TableCell>
                    <TableCell>
                        <Checkbox color="success"
                          checked={checkedSpeaker[item.PatientId] || false}
                          onChange={() => handleSpeakerChange(item.PatientId)}
                        />
                    </TableCell>                    

                    <TableCell>
                    <IconButton aria-label="Borrar" size="small" onClick={() => eliminarPatient(index)}>
                      <DeleteIcon color="secondary" fontSize="large" />
                    </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={9} align="center">No se han agregado marcadores</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
    </Grid>

</>
)}

{tabIndex0===6 && (
  <>
<Grid container spacing={1}>
             <Grid item xs sm="auto" lg="auto" xl={2}>
                 <Grid item style={{ display: 'flex', alignSelf: 'center' }}>
                    <label className='labelBold'>1. Subir primer gráfico y/o tabla:</label>
                    <label htmlFor="fileInput1" style={{ cursor: 'pointer' }}>
                      <FontAwesomeIcon icon={faUpload} className='uploadIcon' />
                      <input 
                        id="fileInput1"
                        type="file"
                        accept='application/pdf,image/jpeg,image/bmp'
                        style={{ display: 'none' }}
                        onChange={handleFile1Change} 
                      />
                    </label>
                    {selectedFile1 && <p>{selectedFile1.name}</p>}
                  </Grid>
           </Grid>
</Grid>
<Grid container spacing={1}>
             <Grid item xs sm="auto" lg="auto" xl={2}>
                 <Grid item style={{ display: 'flex', alignSelf: 'center' }}>
                    <label className='labelBold'>2. Subir segundo gráfico y/o tabla:</label>
                    <label htmlFor="fileInput2" style={{ cursor: 'pointer' }}>
                      <FontAwesomeIcon icon={faUpload} className='uploadIcon' />
                      <input 
                        id="fileInput2"
                        type="file"
                        accept='application/pdf,image/jpeg,image/bmp'
                        style={{ display: 'none' }}
                        onChange={handleFile2Change} 
                      />
                    </label>
                    {selectedFile2 && <p>{selectedFile2.name}</p>}
                  </Grid>
           </Grid>
</Grid>

{state.categoryId == 1 ? (
 <Grid container spacing={1}>          
           <Grid item xs sm="auto" lg="auto" xl={2}>
                 <Grid item style={{ display: 'flex', alignSelf: 'center' }}>
                    <label className='labelBold'>3. Subir Carta firmada por el paciente o su representante legal:</label>
                    <label htmlFor="fileInput3" style={{ cursor: 'pointer' }}>
                      <FontAwesomeIcon icon={faUpload} className='uploadIcon' />
                      <input 
                        id="fileInput3"
                        type="file"
                        accept='application/pdf,image/jpeg,image/bmp'
                        style={{ display: 'none' }}
                        onChange={handleFile3Change} 
                      />
                    </label>
                    {selectedFile3 && <p>{selectedFile3.name}</p>}
                  </Grid>
           </Grid>
</Grid>):null}

{state.categoryId == 3 ? (
              <Grid container spacing={1}>
                  <Grid item style={{ display: 'flex', alignSelf: 'center' }}>
                  <label className='labelBold'>4. Subir certificado comité de ética:</label>
                  <label htmlFor="fileInput4" style={{ cursor: 'pointer' }}>
                    <FontAwesomeIcon icon={faUpload} className='uploadIcon' />
                    <input 
                      id="fileInput4"
                      type="file"
                      accept='application/pdf,image/jpeg,image/bmp'
                      style={{ display: 'none' }}
                      onChange={handleFile4Change} 
                    />
                  </label>
                  {selectedFile4 && <p>{selectedFile4.name}</p>}
                </Grid>
            </Grid>
            ) : null}
  </>
)}
  </>
)}

</DialogContent>
<DialogActions style={{ justifyContent: 'center', margin: '1rem', marginTop: 0 }}>
 <Button style={{ width: '100px' }} className='btnPrimary' onClick={() => {
   setHeadQuarterChs(saveHeadquarterChs)
   setLocationChs(saveLocationChs)
   setOpen3(false)
 }} color="primary">
   Cancelar
 </Button>
 <Button
  style={{ width: '100px' }}
  className='btnPrimary'
  disabled={state.memberFullName == "" || isEmpty(state.memberFullName)
  || isEmpty(temporalPatients)} // Corregido aquí
  onClick={addRegister}
  color="primary"
>
  Guardar
</Button>
</DialogActions>
</Dialog>
</div>
);
}


  /* Alert lost */

  const renderDialogtheftLost = () => {
    return (
      <Dialog className='dialogReounded dialogChangeLost' open={showDialogtheft} aria-labelledby="form-dialog-title">
        <DialogContent className='labelFlex' style={{ borderRadius: '1rem' }}>
          Señor usuario <br /><br />{textOption}
        </DialogContent>
        <DialogActions style={{ alignSelf: 'center', marginBottom: '1.5rem' }}>
          <Button onClick={() => setShowDialogtheft(false)} style={{ width: '100px' }} className='btnPrimary' color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>);
  }

  /* Email */

  const templateText = () => {
    return `
      <p style="color: #5f6273; font-weight: 400; font-size: 0.875rem;">
        Señores Inventarios
        <br><br>
        Se informa que una vez consultada la información del serie ${props.data.serieBien == null ? 'no identificado' : props.data.serieBien}, el sistema identificó la placa ${props.data.placaBien} a nombre de ${props.user.fullName}
        <br><br>
        Se solicita generar la correspondiente placa de codigo de barras por ${razonReimpresion.name}, con el fin de realizar la legalización.
        <br><br>
        ${props.user.fullName}
        <br><br>
        Correo electronico ${props.propsDad.user.email}
      </p>
    `;
  }

 
    const sendSoporte = async () => {
        let params = {
          subject: 'Solicitud de Información',
          emailEnd: email,
          contenido: this.state.contenido,
          // inscriptionId: dataInscription['inscriptionId'];
          }
          let result = await sendEmailSoporte(params)
          if (result) {
            // setEmailAlert(result)
            alert('Ha sido enviada una solicitud de soporte, pronto será atendida')
          } else {
            // setEmailAlert('')
            alert('No ha sido posible enviar su solicitud. Favor intenta de nuevo')
          }
          toggleDialog();
    };
  

  const send = async () => {
    if (editorRef.current) {
      setSended(true)
      let params = {
        subject: asuntoMail,
        emailEnd: email
      }
      //let result = await sendMail(params, editorRef.current.getContent(), ccmail)
      const nuevoContenido = "Este es el nuevo contenido que quiero establecer en el editor.";
     // editorRef.current.setContent(nuevoContenido);
      console.log("contenido");
      //console.log(editorRef.current.getContent());
      let result = await sendMail(params, {nuevoContenido}, ccmail)
      if (result) {
        setEmailAlert(result)
      } else {
        setEmailAlert('')
      }
    }
  };

  
const ExistRegister = () => {
  return (
    <div>AssignedAsset</div>
  )
}


  

  const handleChangeTypeReimpresion = (event) => {
    let optionData = props.propsDad.rePrintReasons.find(reimpresion => reimpresion.id == event.target.value)

    setRazonReimpresion(optionData)
  };

  useEffect(() => {
    if (razonReimpresion != null) {
      let seacrh = asuntoMail.split(' - ')
      let asuntoNuevo = seacrh[1] != undefined ?
        asuntoMail.replace(` - ${seacrh[1]}`, ` - por ${razonReimpresion.name}`) :
        asuntoMail + ` - por ${razonReimpresion.name}`

      setAsuntoMail(asuntoNuevo)
    }

  }, [razonReimpresion])

const handleDialogPendient = () => {
    setOpenPendient(true);
} 

  const handleCloseDialogPendient = (event) => {
    const id = event.currentTarget.id;
    if(id==1){
      setState([]);
    }else{
    
    let inscriptionPendiente = props.propsDad.myInscriptions.find(pendiente => pendiente.inscriptionState == 0);
    if(inscriptionPendiente){
      setState(inscriptionPendiente);
    }
      console.log("carga");
      console.log(inscriptionPendiente);
      console.log(state);
    }
    console.log("QUEDA PENDIENTE");
    console.log(state);
    setOpenPendient(false);
    setSwitchOption2(!switchOption2);
    setOpen3(true);
  }



  const renderDialogPendient = () => {
      return (
        <Dialog className='dialogReounded' open={openPendient}>
          <DialogTitle id="form-dialog-title">INSCRIPCIÓN PENDIENTE</DialogTitle>
          <DialogContent>
            <DialogContentText>
              En este momento usted tiene una inscripción que no ha sido terminada. Favor seleccionar la acción a seguir?
            </DialogContentText>
            {/* <List>
              <ListItem button onClick={() => alert("Vamos a compartir como imagen")} key="image">
                <ListItemAvatar>
                  <Avatar>
                    <ImageIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Como Imagen" />
              </ListItem>
              <ListItem button onClick={() => alert("Vamos a compartir como HTML")} key="html">
                <ListItemAvatar>
                  <Avatar>
                    <CommentIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Como HTML" />
              </ListItem>
            </List> */}
          </DialogContent>
          <DialogActions>
            <Button className='btnPrimary' id={1} onClick={handleCloseDialogPendient} color="secondary">
              Eliminar
            </Button>
            <Button className='btnPrimary' id={2} onClick={handleCloseDialogPendient} color="primary">
              Completar
            </Button>
          </DialogActions>
        </Dialog>
      );
  }  


  const renderDialogEmail = () => {
    return (
      <Dialog className='dialogReounded DialogEmail'
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {emailAlert.length == 0 && razonReimpresion != null ? <>
          <DialogTitle style={{ color: '#5f6273', fontWeight: '700', paddingBottom: 0, paddingLeft: '1rem' }}>{"Enviar correo electrónico"}</DialogTitle>
          <DialogContent id='DialogEmail'>
            <label className='labelStyle'>Para:</label>
            <TextField type="password" style={{ backgroundColor: 'transparent', color: '#5f6273' }} fullWidth id="placa" value={email} size={'small'} variant="outlined" onChange={e => setEmail(e.target.value)}  disabled />

            <label className='labelStyle'>CC</label>
            <Autocomplete
              multiple
              id="tags-filled"
              options={[]}
              freeSolo
              value={ccmail}
              onChange={(event, input) => {
                setCcEmail(input)
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip variant='outlined' style={{ backgroundColor: validateEmailString(option) ? '#dae2fc' : '#ef4682', color: validateEmailString(option) ? '#445dc9' : '#ffff', border: 0 }} color={validateEmailString(option) ? 'primary' : 'secondary'} label={option} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => (
                <TextField style={{ marginBottom: 7, color: '#5f6273' }}
                  className='select-filtros'
                  onBlur={(e) => {
                    if (e.target.value.length > 0) {
                      setCcEmail(ccmail.concat(e.target.value))
                    }
                  }}
                  onKeyDown={e => {
                    if (e.keyCode === 188 && e.target.value) {
                      setCcEmail(ccmail.concat(e.target.value))
                    }
                  }} {...params} type='email' variant="outlined" size={'small'} />
              )}
            />

            <label className='labelStyle'>Asunto</label>
            <TextField style={{ color: '#5f6273', backgroundColor: 'transparent' }} fullWidth id="asunto" value={'Solicitud de Información'} size={'small'} variant="outlined" disabled />

            <p style={{ color: '#5f6273', margin: '0.5rem 0' }}>Contenido</p>
            <textarea style={{ color: '#5f6273', backgroundColor: 'transparent' }} 
            fullWidth 
            value={this.state.contenido || ''} // contenido viene del estado
            onChange={(e) => this.setState({ contenido: e.target.value })} // actualiza el estado
            // placeholder="Escribe el titulo del trabajo..."
            rows={6}
            cols={50}
            >

            </textarea>
            {/* <Editor
              apiKey='3kene6jho40n97htmyaa9sspemiuioexws0ko6m36go2k62i'
              onInit={(evt, editor) => (editorRef.current = editor)}
              initialValue={''}
              init={{
                height: 350,
                menubar: false,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                  "undo redo | formatselect | " +
                  "bold italic backcolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat | help",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              }}
            /> */}
            {emailAlert.length > 0 && <Alert severity={'info'}>
              {emailAlert.message}
            </Alert>}
          </DialogContent>

          {sended === false ?
            <DialogActions className='assetDialogActions1'>
              <Button className='btnPrimary' onClick={toggleDialog} color="primary">
                Cancelar
              </Button>
              <Button className='btnPrimary' onClick={sendSoporte} color="primary" autoFocus>
                Enviar
              </Button>
            </DialogActions>
            :
            <DialogActions className='assetDialogActions2'>
              <Button className='btnSecundary' onClick={toggleDialog} color="primary">
                Cancelar
              </Button>
              <Button autoFocus className='btnPrimary'>
                {emailAlert.length === 0 ? 'Enviando...' : 'Enviado'}
              </Button>
            </DialogActions>}
        </> : emailAlert.length == 0 ? <DialogContent className='dialogMotivo'>
          <DialogTitle>{"Motivo por el cual está solicitando la renovación de la placa adhesiva"}</DialogTitle>
          <DialogActions>
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <ButtonBase className='ContainerButtonGroup'>
                  <Grid container direction='row'>
                    {props.propsDad.rePrintReasons.length > 0 && props.propsDad.rePrintReasons.map(transact => {
                      return (
                        <Grid key={transact.id} item xs={12} sm={4} container alignItems='center'>
                          <Grid item xs="auto" sm="auto" lg="auto">
                            <BlueRadio
                              checked={razonReimpresion != null && razonReimpresion.id == transact.id}
                              onChange={handleChangeTypeReimpresion}
                              value={transact.id}
                              name={`${transact.id}`}
                            />
                          </Grid>
                          <Grid item xs sm lg="auto">
                            <Typography className='fontAa' variant="caption" display="block" gutterBottom>{transact.name}</Typography>
                          </Grid>
                        </Grid>)
                    })}
                  </Grid>
                </ButtonBase>
              </Grid>
              <Grid item>
                <Button className="btnSecundary btnCancelar" onClick={toggleDialog} color="primary">
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </DialogContent> :
          <>
            <DialogTitle style={{ color: '#5f6273', fontWeight: '700', paddingBottom: 0, paddingLeft: '1rem' }}>
              {emailAlert.error ? 'Enviado correctamente' : 'Enviado correctamente'}
            </DialogTitle>
            <DialogContent>
              <Grid container>
                <Grid item xs={12}>
                  <Typography className='labelFlex' style={emailAlert.error ? {} : { marginBottom: '1rem' }}>{emailAlert.message}</Typography>
                </Grid>
                {emailAlert.error ?
                  <Grid item container>
                    <Grid item>
                      <b className='labelFlex'>Detalle del error:</b>
                    </Grid>

                    <Grid item>
                      <Typography className='labelFlex' style={{ paddingLeft: '1rem' }} paragraph>
                        {emailAlert.messageError}
                      </Typography>
                    </Grid>
                  </Grid>
                  : null}
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <Button className='btnPrimary' style={{ margin: 'auto' }} onClick={toggleDialog} autoFocus>
                    Aceptar
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>
          </>
        }
      </Dialog>
    );
  };

  
// Pantalla principal 
  const ruta = process.env.PUBLIC_URL +  '/imagenEvento.jpg';

  useEffect(() => {
    console.log("abre registrar ", window.sessionStorage.getItem('_abrirRegistrar'));
    window.sessionStorage.setItem('_abrirRegistrar', true); 
    setOpen4(window.sessionStorage.getItem('_abrirRegistrar'));
    
  }, []);
  
 
 return (
    <div className={classes.root + ' contenedorAsset'} >
      {/* <Paper variant="outlined" style={{ padding: '1rem 0', borderRadius: '2rem', border: 0, margin: 0, maxWidth: 'none', boxShadow: '3px 3px 20px 0px #d6e0f4', position: 'relative' }} className={changeType && headquarterChs && locationChs ? classes.paper : props.isOut ? classes.paperOut : classes.paper}></Paper> */}
      <Paper  style={{ padding: '0.5rem 1.5 rem', borderRadius: '2rem', border: 0, margin: 0, maxWidth: 'none', boxShadow: '3px 3px 20px 0px ', position: 'relative' }} className={changeType && headquarterChs && locationChs ? classes.paper : props.isOut ? classes.paperOut : classes.paper}>

        <Grid container className='contenedorAssetGridMayor'>

          <Grid className='contenedorAssetGrid' item xs={12} sm={12} container>
            {/* <Grid className='columnaLogo' item xs sm={3} lg={2} style={{ padding: '0 0.5rem' }} justifyContent='center' container direction="column">
              <IconButton className="asset_icon" >
                <img className={classes.img} alt="Imagen Bien" src={props.url_imagen} />
              </IconButton>
              <Typography align="center" variant="caption" display="block" gutterBottom className='descripcionBien'> {props.data.descripcionBien}</Typography>
            </Grid> */}
            {/* <Grid className='columnaLogo' item xs sm={3} lg={2} style={{ padding: '0 0.5rem' }} justifyContent='center' container direction="column">
              <div style={{ width: '350px', height: '450px' }}>
                <img className={classes.img} alt="Imagen Evento" src={ruta} style={{ width: '100%', height: '100%' , opacity: '0.3' }} />
              </div>
              <Typography align="center" variant="caption" display="block" gutterBottom className='descripcionBien'> {props.data.descripcionBien}</Typography>
            </Grid> */}
            {/* <Grid item className='iconsAsset iconsAssetTop' xs sm>
              <IconButton style={{ padding: '5px' }} onClick={toggleDialog}>
                <FontAwesomeIcon style={{ color: '#80849d' }} icon={faBarcode} size="1x" />
              </IconButton>

              <IconButton style={{ padding: '5px' }} onClick={toggleDialogPrint} aria-label="delete">
                <PictureAsPdfIcon style={{ color: '#80849d' }} />
              </IconButton>

              <Tooltip style={{ background: '#ef4682' }} title={notifyAlert()} disableHoverListener={li.length <= 0}>
                <IconButton style={{ padding: '0px' }} color={'inherit'} aria-label="add an alarm">
                  <NotificationsActiveIcon style={{ color: li.length > 0 ? '#ef4682' : '#8487a0' }} />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid className='columnaInfo' item xs={12} sm={9} lg container>
              <Grid item xs container className='contenedorMedio'>
                <Grid item xs={12} container direction='row'>







                
                  <Grid item container xs={12}>
                  <Grid item>
                      <Typography className="textCards" variant="caption" style={{ fontFamily: 'Times', fontWeight: 'bold', color: '#000' }}>
                        Historia Clínica: {props.data?.pacienteHc ?? 'No disponible'}
                      </Typography>
                      <Typography className="textCards" variant="caption" style={{ fontFamily: 'Times', fontWeight: 'bold', color: '#000', marginLeft: '1rem' }}>
                        Número SAP: {props.data?.info?.sap ?? 'No disponible'}
                      </Typography>
                    </Grid>
                    <Typography className="textCards" variant="caption" style={{ fontFamily: 'Times', fontWeight: 'bold', color: '#000', marginLeft: '1rem' }}>
                        Documento: {props.data?.info?.doc ?? 'No disponible'}
                    </Typography>
                    </Grid>
                    <Grid item align="center">
                        <Typography variant="caption" className="textCards" style={{fontFamily: 'Times', fontWeight: 'bold', color: '#000' }}>
                        Nombres:{(props.data?.info?.pape ?? '') + ' ' + (props.data?.info?.sape ?? '') + ' ' + (props.data?.info?.nom ?? '')}
                        </Typography>
                    </Grid>
                    <Grid item xs={8} sm lg xl container direction='column'>
                      <Grid item className='dependenciaXs'>
                        <Typography variant="caption" className="textCards">
                        Fecha de Nacimiento:{props.data?.info?.fecnac ?? 'Fecha no disponible'}
                        </Typography>
                      </Grid>
                    </Grid>

                  </Grid>
 */}
                  {/* <Grid item xs sm lg xl container >
                     <Grid item xs={4} sm={2} lg={2} xl={2}>
                        <Typography labelBold className="textCards" variant="caption" style={{fontFamily: 'Times', fontWeight: 'bold', color: '#000' }}>
                          Dirección de residencia:
                        </Typography>
                     </Grid> */}
                    {/* <Grid item xs={4} sm={2} lg={2} xl={2}>
                      <Typography noWrap variant="caption" labelBold>
                        Dirigido a:yyyy
                      </Typography>
                    </Grid> */}

                      {/* <Grid item xs sm lg xl>
                        <Typography variant="caption" className="textCards" style={{fontFamily: 'Times', fontWeight: 'bold', color: '#000' }}>
                        {props.data?.info?.dirres ?? 'Dirección no disponible'}
                        </Typography>
                      </Grid>
                  </Grid>
                  <FontAwesomeIcon style={{ height: '1.1rem', color: '#5f6273', fontSize: '1.813rem', position: 'absolute', right: '1.5rem', top: '1.5rem' }} icon={faEllipsisV} />
                </Grid> */}




                      {/* <Button className='btnEditar1'
                        style={{
                          marginLeft: '0.2rem', padding: 0, width: 'auto', height: 'rem',
                          border: `1px solid ${headquarterChs.length > 0 || (locationChs != null && locationChs.length) > 0 ? '#445dc9' : '#ef4682'}`
                        }}
                        id={2}
                        onClick={handleChangeSwitch2}
                        variant="outlined"
                        color={(headquarterChs.length > 0 || (locationChs != null && locationChs.length) > 0 ? 'primary' : 'secondary')} size="small"
                        >
                        <p style={{ margin: 0, fontSize: '0.688rem', fontWeight: '600', textTransform: 'none' }}>
                          {headquarterChs.length > 0 ? 'Oncología' : locationChs != null ? 'Oncología' : 'Oncología'}
                        </p>
                        <EditIcon style={{ fontSize: "0.688rem" }} />
                      </Button> */}
                      {/* <Button className='btnEditar1'
                        style={{
                          marginLeft: '0.2rem', padding: 0, width: 'auto', height: '1.375rem',
                          border: `1px solid ${headquarterChs.length > 0 || (locationChs != null && locationChs.length) > 0 ? '#445dc9' : '#ef4682'}`
                        }}
                        id={3}
                        onClick={handleChangeSwitch2}
                        variant="outlined"
                        color={(headquarterChs.length > 0 || (locationChs != null && locationChs.length) > 0 ? 'primary' : 'secondary')} size="small"
                      >
                        <p style={{ margin: 0, fontSize: '0.688rem', fontWeight: '600', textTransform: 'none' }}>
                          {headquarterChs.length > 0 ? 'Hemato-Oncología Pediatrica' : locationChs != null ? 'Hemato-Oncología Pediatrica' : 'Hemato-Oncología Pediatrica'}
                        </p>
                        <EditIcon style={{ fontSize: "0.688rem" }} />
                      </Button> */}
                      {/* <Button className='btnEditar1'
                        style={{
                          marginLeft: '0.2rem', padding: 0, width: 'auto', height: '1.375rem',
                          border: `1px solid ${headquarterChs.length > 0 || (locationChs != null && locationChs.length) > 0 ? '#445dc9' : '#ef4682'}`
                        }}
                        id={4}
                        onClick={handleChangeSwitch2}
                        variant="outlined"
                        color={(headquarterChs.length > 0 || (locationChs != null && locationChs.length) > 0 ? 'primary' : 'secondary')} size="small"
                      >
                        <p style={{ margin: 0, fontSize: '0.688rem', fontWeight: '600', textTransform: 'none' }}>
                          {headquarterChs.length > 0 ? 'Manejo Multidisciplinario' : locationChs != null ? 'Manejo Multidisciplinario' : 'Manejo Multidisciplinario'}
                        </p>
                        <EditIcon style={{ fontSize: "0.688rem" }} />
                      </Button>
                      <Button className='btnEditar2'
                        style={{ marginLeft: '0.2rem', padding: 0, width: 'auto', height: '1.375rem' }}
                        onClick={handleChangeSwitch2}
                        color={(headquarterChs.length > 0 || (locationChs != null && locationChs.length) > 0 ? 'primary' : 'secondary')} size="small"
                      >
                        <EditIcon style={{ fontSize: "0.988rem" }} />
                      </Button> */}
                  {/* </Grid> */}
                </Grid>
                {/* <Grid item xs={12} container direction='row' className='estadosContainer'>
                  <Grid item xs={4} sm={2} lg={2} xl={2} container direction='column' style={{ alignSelf: 'center' }}>
                    <Grid item>
                      <Typography variant="caption">
                        <b className='bAsset'>Estado del bien: </b>
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item xs={8} sm={9} lg={4} xl={4} container justifyContent='space-between' alignContent='center'>
                    <ButtonBase color="primary" className='estado' aria-label="add to shopping cart">
                      <Typography className='fontAa' variant="caption" display="block" gutterBottom>Bueno</Typography>
                      <FormControlLabel
                        control={<MaterialUISwitch sx={{ m: 0 }} />}
                        checked={assetStatus}
                        onChange={handleChangeStatus}
                      />
                      <Typography className='fontAa' variant="caption" display="block" gutterBottom>Regular</Typography>
                    </ButtonBase>
                  </Grid> */}

                  {/* <Grid item xs={4} sm={2} lg={2} xl={2} container direction='column' style={{ alignSelf: 'center' }}>
                    <Grid item>
                      <Typography variant="caption">
                        <b className='bAsset'>¿Verificado físicamente?:</b>
                      </Typography>
                    </Grid>
                  </Grid> */}

                  {/* <Grid item xs={8} sm lg={4} xl={4} container direction='column' justifyContent='center'>
                    <ButtonBase style={{ justifyContent: 'flex-start' }} className='estadoFaltante' color="inherit" display="flex">
                      <BlueRadio
                        checked={changeType == getIdOptionSUSIList('Verificado', props.changesTypes)}
                        onChange={handleChangeType}
                        value={getIdOptionSUSIList('Verificado', props.changesTypes)}
                        name="radio-button-demo"
                      />
                      <Typography className='fontAa' variant="caption" display="block" gutterBottom>Si</Typography>

                      <RedRadio
                        checked={changeType == getIdOptionSUSIList('Faltante', props.changesTypes)}
                        onChange={handleChangeType}
                        value={getIdOptionSUSIList('Faltante', props.changesTypes)}
                        name="radio-button-demo"
                      />
                      <Tooltip title={observations != null ? observations : ' '} disableHoverListener={observations == null || changeType != getIdOptionSUSIList('Faltante', props.changesTypes)}>
                        <Typography className='fontAa' variant="caption" display="block" gutterBottom>
                          No{changeType == getIdOptionSUSIList('Faltante', props.changesTypes) ? ': ' + getNameOptionSUSIList(missingReason, props.missingReasons) : ''}
                        </Typography>
                      </Tooltip>
                    </ButtonBase>
                  </Grid> */}
                {/* </Grid> */}

                 {/* <Grid item container alignItems='center' justifyContent='space-between'>
                 <Grid item xs={4} sm={2} lg={2}>
                    {props.data.date_checklist != null && new Date(props.data.date_checklist).getFullYear() == new Date().getFullYear() ?
                      <CheckCircleOutlineIcon style={{ color: '#46cb4c', fontSize: '1.5rem', marginRight: '0.5rem' }} /> // verde
                      : props.data.date_checklist !== null && new Date() >= new Date(`${1}/${1}/${culminationDate.getFullYear()}`) && new Date() < culminationDate ?
                        <CheckCircleOutlineIcon style={{ color: '#EAB130', fontSize: '1.5rem', marginRight: '0.5rem' }} /> // amarillo
                        :
                        <CheckCircleOutlineIcon style={{ color: '#ef4682', fontSize: '1.5rem', marginRight: '0.5rem' }} /> // rojo
                    }
                    <Typography variant="caption" className="textCards">
                      Última verificación:
                    </Typography>
                  </Grid> */}

                  {/* <Grid item xs sm>
                    <Typography variant="caption" className="textCards">
                      {props.data.date_checklist != null ? new Date(props.data.date_checklist).toLocaleDateString("es-ES", configDate) : 'No Registrado'}
                    </Typography>
                  </Grid> */}

{/* <Grid item xs={12} sm={12} lg={12} xl={12} container className='iconsAsset iconsAssetBottom' justifyContent='center' alignItems='center'>

<Grid container spacing={3}>
        <Grid item xs={12} sm={12} lg={12} xl={12} container justifyContent='flex-start' alignItems='center'>
          <Fab color="success" size="small" aria-label="add" onClick={handleChangeSwitch3}>
            <AddIcon color="rgb(0, 166, 206)" />
          </Fab>
          <label className='labelBold' >Nuevo Paciente:</label>

          </Grid>
        </Grid> */}

  {/* <Grid item xs sm="auto" lg="auto" xl={6}>
    <IconButton style={{ padding: '0px' }} onClick={toggleDialog}>
      <Typography variant="body1" style={{ marginRight: '8px', fontFamily: 'Times', fontWeight: 'bold', fontSize: '14px', color: '#000' }}>Mayor Información</Typography>
      <AlternateEmailIcon fontSize="large" style={{ color: '#00A6CE' }} icon={faBarcode} size="1x" />
    </IconButton>
  </Grid> */}

  {/* <Grid item xs sm="auto" lg="auto" xl={6}>
    <IconButton style={{ padding: '0px' }} onClick={handleChangeSwitch2} aria-label="delete">
      <Typography variant="body1" style={{ marginRight: '8px', fontFamily: 'Times', fontWeight: 'bold', fontSize: '14px', color: '#000' }}>Editar</Typography>
      <EditIcon fontSize="large" style={{ color: '#00A6CE' }} />
    </IconButton>
  </Grid> */}
{/* </Grid> */}
                  {/* <Grid item xs={12} sm={12} lg={12} xl={12} container className='iconsAsset iconsAssetBottom' justifyContent='flex-end'>
                    <Grid item xs sm="auto" lg="auto" xl={6}>
                      <IconButton style={{ padding: '0px' }} onClick={toggleDialog}>
                        <Typography variant="caption" style={{ marginRight: '8px' }}>Mayor Información</Typography>
                        <AlternateEmailIcon fontSize="large" style={{ color: '#80849d' }} icon={faBarcode} size="1x" />
                      </IconButton>
                    </Grid>

                    <Grid item xs sm="auto" lg="auto" xl={6}>
                      <IconButton style={{ padding: '0px' }} disabled onClick={toggleDialogPrint} aria-label="delete">
                        <Typography variant="caption" style={{ marginRight: '8px' }}>Convocatoría</Typography>
                        <PictureAsPdfIcon fontSize="large" style={{ color: '#80849d' }} />
                      </IconButton>
                    </Grid>
                  </Grid> */}
                </Grid>
              
        {/*    </Grid>
          </Grid>
         </Grid> */}
      </Paper>
      {renderDialogPatients()}
      {renderDialogRegister()}
      {renderDialogFiliations()}
      {/* {renderDialogStatusBad()}
      {renderDialogChangeTypeLost()}
      
      
      
      {renderDialogShare()}
      {renderDialogEmail()}
      {renderDialogPrint()}
      {renderDialogConvoca()}
      {renderDialogPendient()}
      {renderDialogtheftLost()} */}

    </div >
  );

  
};

export default AssignedAsset