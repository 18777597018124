import { Card, CardActions, CardContent, Typography, Button, CircularProgress } from "@mui/material";
import {Navigate} from 'react-router-dom';
import React from 'react'
import { connect } from "react-redux"
import { createRegister } from "../../redux/actions/loginRegisterAction";
import { Alert } from "@mui/lab";
import logoA from '../../assets/img/logo_inc.svg';
import tituloA from '../../assets/img/titulo_mi_inventario_linea.png';
import { useEffect } from "react";
import { Tooltip } from '@mui/material';

const Register = (props) => {
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [passwordconfirm, setPasswordconfirm] = React.useState('');
    const [idcard, setIdcard] = React.useState('');    
    const [name, setName] = React.useState('');
    const [lastname, setLastname] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [messageRegister, setMessageRegister] = React.useState('');
    const [register, setRegister] = React.useState(props.register);
    const [isValid, setIsValid] = React.useState(false);    

    
    const handleRegister = async () => {
        if (!isValid) {
            return;
        }
        let data = {
            email:   username,
            idcard:  idcard, 
            name:    name,
            lastname:lastname,
            password:password,
            role    :0,
            idUser  :0
        }

        // let validation = this.onValidate()
        console.log("envia data en Register.js");
        console.log(data);
        await createRegister(data) 
        window.sessionStorage.clear();
        // <Navigate to="/login" />
        window.location.href = '/login';
        // let message = validation ? await createRegister(data) 
        //     : 'Digitar la información de la descripción del bien, serie, ubicación fisica del bien, dependencia y número de contacto, Fijo (ext) o móvil.';
        // this.setState({ alertResult: message.message ? message.message : message })

        // setTimeout(async () => {
        //     await props.dispatch(createRegister(data));
        // }, 500);
        
    };

    const validateFields = () => {
        // Validación básica de campos no vacíos
        if (
            username.trim() !== '' &&
            password.trim() !== '' &&
            passwordconfirm.trim() !== '' &&
            name.trim() !== '' &&
            lastname.trim() !== ''
        ) {
            // Validación de contraseñas
            if (password === passwordconfirm) {
                setIsValid(true);
                setMessageRegister('');
            } else {
                setIsValid(false);
                setMessageRegister('Las contraseñas no coinciden');
            }
        } else {
            setIsValid(false);
            setMessageRegister('Todos los campos son obligatorios');
        }
    }; 



    useEffect(() => {
        setLoading(props.token.isFetching);
    }, [props.token])
    
    useEffect(() => {
        validateFields(); // Validar campos cada vez que cambien
    }, );


    return (
        <div className="containerLogin">
            <Card className='rootLogin'>
                {/* <CardContent className="cardTtuloLogin"> */}
                    {/* <Typography className="tituloLogin">MI <br /></Typography>
                    <Typography variant="h5" >INVENTARIO</Typography>
                    <Typography variant="h5" >EN LÍNEA</Typography> */}
                    
                {/* </CardContent> */}
                {/* <img src={tituloA} className='ImgTitulo' alt="" /> */}
                <CardContent style={{ padding: '0.3rem', margin: '0.1rem' }}>
                    <label style={{ color: "black", fontWeight: "bold" }}>CREAR NUEVA CUENTA</label>
                </CardContent>
                <CardContent style={{padding: '0.3rem', margin: '0.1rem' }}>
                <Tooltip title="Cédula del usuario " 
                placement="top-start" arrow PopperProps={{disablePortal: false,
                style: { top: '0px' }, margin: '0px', }}>   
                    <input className="field" type="number" value={idcard} onChange={(e) => setIdcard(e.target.value)} placeholder={'Cédula del usuario'} required>
                    </input>
                </Tooltip>                    
                </CardContent>
                <CardContent style={{padding: '0.3rem', margin: '0.1rem' }}>
                <Tooltip title="Nombres del usuario " 
                placement="top-start" arrow PopperProps={{disablePortal: false,
                style: { top: '0px' }, margin: '0px', }}>   
                    <input className="field" value={name} onChange={(e) => setName(e.target.value.toUpperCase())} placeholder={'Nombres del usuario'} required>
                    </input>
                </Tooltip>                    
                </CardContent>
                <CardContent style={{padding: '0.3rem', margin: '0.1rem' }}>
                    <input className="field" value={lastname} onChange={(e) => setLastname(e.target.value.toUpperCase())} placeholder={'Apellidos del usuario'} required>
                    </input>
                </CardContent>

                <CardContent style={{ type:'email', padding: '0.3rem', margin: '0.1rem' }}>
                    <input className="field" value={username} onChange={(e) => setUsername(e.target.value.replace(' ', ''))} placeholder={'Correo_electronico@user.com'} required>
                    </input>
                </CardContent>
                <CardContent style={{ padding: '0.3rem', margin: '0.1rem' }}>
                    <input className="field" value={password} type="password" onChange={(e) => {
                    const cleanedValue = e.target.value.replace(' ', '');
                    setPassword(cleanedValue);
                    // messageRegister='';
                    // if (cleanedValue !== passwordconfirm) {
                    //     messageRegister = 'Las contraseñas no coinciden';
                    // }
                    }} placeholder={'Contraseña'} security="true" required>
                    </input>
                </CardContent>
                <CardContent style={{ padding: '0.3rem', margin: '0.1rem' }}>
                    <input className="field" value={passwordconfirm} type="password" onChange={(e) => {
                    const cleanedValue = e.target.value.replace(' ', '');
                    setPasswordconfirm(cleanedValue);
                    // messageRegister='';
                    // if (cleanedValue !== password) {
                    //     messageRegister = 'Las contraseñas no coinciden';
                    // }
                    }}  placeholder={'Confirmar Contraseña'} security="true" required>
                    </input>
                </CardContent>
                {/* <CardContent style={{ padding: '0.3rem', margin: '0.1rem' }}>
                <Select className="field" value={role} onChange={handleRoleChange}
                        required style={{ width: '100%' }}>           
                        <MenuItem value="participante">Participante</MenuItem>
                        <MenuItem value="clinico">Clínico</MenuItem>
                        <MenuItem value="metodologo">Metodológo</MenuItem>
                        <MenuItem value="administrador">Administrador</MenuItem>
                </Select>
                </CardContent> */}
                {messageRegister.length > 0 ? <Alert severity="error" style={{ backgroundColor: '#FFFF00'}}>{messageRegister}</Alert> : <></>}
                <CardActions style={{ justifyContent: 'center' , marginTop: '1.5rem', marginBottom: '-0.9rem' }}>
                    {loading === false ? <Button  className='btnLogin' onClick={handleRegister} disabled={!isValid}>Registrate</Button> : <CircularProgress style={{ margin: 'auto' }} />}
                </CardActions>
                <CardActions style={{ justifyContent: 'center'  }}>
                    {loading === false ? <Button className="btnLogin" onClick={() => {
                                window.sessionStorage.clear();
                                window.location.href = '/login';
                              }}>Inicia Sesión</Button> : <CircularProgress style={{ margin: 'auto' }} />}
                </CardActions>
                <img src={logoA} className='ImgLogoLogin' alt="" />
                {/* <CardContent style={{ padding: '0.1rem', margin: '0.1rem' }}>
                    <Typography paragraph style={{fontSize:14}}>
                        Versión {REACT_APP_VERSION}
                    </Typography>
                </CardContent> */}
            </Card>
        </div>
    )
}
function mapStateToProps(state) {
    console.log(state)
    return {
        token: state.getUserToken
    };
}
export default connect(mapStateToProps, null, null, { forwardRef: true })(Register);
