import axios from 'axios';
import {REACT_APP_BACKEND_URL} from "../../config";


export const sendMail = async (params) => {
    console.log("TLA params de Mail");
    console.log(params);
    const headers = {
        'Content-Type': 'application/json; charset=utf-8'
      };
    axios.post(REACT_APP_BACKEND_URL + 'sendEmail.php', params,{
        headers: headers
      }).then((result) => {
            console.log("esta es la respuesta", result);
            return true;
        })
        .catch((err) => {
            console.log("Hubo un error al enviar el correo ", err);
            return false;
        })
}

export const sendEmailSoporte = async (params) => {
  console.log("TLA params de Mail");
  console.log(params);
  const headers = {
      'Content-Type': 'application/json; charset=utf-8'
    };
  axios.post(REACT_APP_BACKEND_URL + 'sendSoporte.php', params,{
      headers: headers
    }).then((result) => {
          console.log("esta es la respuesta", result);
          return true;
      })
      .catch((err) => {
          console.log("Hubo un error al enviar el correo ", err);
          return false;
      })
}
